import React, { useState } from 'react'
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from "react-icons/bs"

export const Carousel = ({ data }) => {
    const [slide, setSlide] = useState(0);

    const nextSlide = () => {
        setSlide(slide === data.slides.length - 1 ? 0 : slide + 1);
    }

    const prevSlide = () => {
        setSlide(slide === 0 ? data.slides.length - 1 : slide - 1);
    }

    return (
        <div className="carousel">
            <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide}/>
            {data.slides?.map((item, idx) => {
                return (
                    <div key={idx} className={slide === idx ? "slide" : "slide slide-hidden"}>
                        <img src={item.src} alt={item.alt}  style={{height:"350px", borderRadius:"8px"}} />
                        <h5 style={{display:"flex", justifyContent:"right", fontFamily:"NewTextFontBold"}}>{item.descrip}</h5>
                    </div>
                )
            })}
            <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide}/>
            <div className="indicators">
                {data.slides?.map((_, idx) => {
                    return <div key={idx} onClick={() => setSlide(idx)} className={slide === idx ? "indicator" : "indicator indicator-inactive"}></div>
                })}
            </div>
        </div>
    );
}