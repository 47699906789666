import React, {useState} from "react";
import { FaStar } from "react-icons/fa";
import TextField from '@mui/material/TextField';

// THIS FUNCITON USES THE NOCODEAPI SITE

export function FeedbackNPS(props) {
    const [rating, setRating] = useState(5);
    const [hover, setHover] = useState(null);
    const [additionalFeedback, setAdditionalFeedback] = useState("");
    const [hearAboutFeedback, setHearAboutFeedback] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const huntTitle = props.huntTitle
    const user = props.user
    const email = props.email

    const handleSubmit = async () => {
        setSubmitted(true)

        try {
            const response = await fetch(
                "https://v1.nocodeapi.com/hiddenhunts/google_sheets/ZbjlCOeUFfprvppr?tabId=Feedback", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify([[new Date().toLocaleString(), user, email, huntTitle, rating, hearAboutFeedback, additionalFeedback]])
            })
            await response.json()
            .then(setTimeout(() => props.handleHomePage(), 2000))
        } catch (err) {
            console.log(err)
            .then(setTimeout(() => props.handleHomePage(), 2000))
        }
    }

    return (
    <div style={{display:"flex", flexDirection:"column", alignItems:"center", margin:"20px"}}>
                <h1 style={{marginBottom:"15px"}}>FEEDBACK</h1>
                {/* <hr/> */}
                <br/>
                <h6 style={{textAlign:"center", marginBottom:"30px"}}>This is internal feedback for our team. Nothing entered here will be posted anywhere online.</h6>
                
                {submitted === false ?
                <form style={{width:"100%"}} id="feedbackForm">
                    <h3 style={{fontSize:"12px"}}> How was the hunt?</h3><br/>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            {[...Array(5)].map((star, i) => {
                                const ratingValue = i + 1

                                return (
                                    <label>
                                        <input 
                                            type="radio"
                                            name="rating"
                                            style={{display:"none"}}
                                            value={ratingValue} 
                                            onClick={()=>{setRating(ratingValue)}}
                                            id={i}
                                        />
                                        <FaStar 
                                            className="star" 
                                            color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"} 
                                            size="40px"
                                            onMouseEnter={()=> setHover(ratingValue)}
                                            onMouseLeave={()=> setHover(null)}
                                        />
                                    </label>
                                )
                            })}
                        </div>
                    <br/>
                    <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                        <h3 style={{marginBottom:"5px", fontSize:"12px"}}>How did you hear about us?</h3>
                        <TextField
                            style={{width:"100%", marginBottom:"15px"}}
                            multiline
                            onChange={(e) =>{setHearAboutFeedback(e.target.value)}}
                            value={hearAboutFeedback}
                        />
                        <h3 style={{marginBottom:"5px", fontSize:"12px"}}>Additional feedback</h3>
                        <TextField
                            style={{width:"100%", marginBottom:"15px"}}
                            multiline
                            onChange={(e) =>{setAdditionalFeedback(e.target.value)}}
                            value={additionalFeedback}
                        />
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                            <div className="blackButtons" onClick={handleSubmit}>SUBMIT</div>
                        </div> 
                    </div>
                </form>
                :
                <div>
                    <h3 style={{textAlign:"center", marginTop:"15px"}}>Thank you for your feedback!</h3>
                    <br/><br/>
                    <h5 style={{textAlign:"center", marginBottom:"15px"}}>Loading hunts page...</h5>
                </div>
                }
            </div>
    )
    }