import React, {useState} from "react";

export function Kakuru(props) {

    const myArray = props.kakuruData
    const answerArray = props.kakuruAnswer

    const [kakuruArr, setKakuruArr] = useState(myArray);
    const [correctAnswer, setCorrectAnswer] = useState(false);

    function getDeepCopy(arr){
        return JSON.parse(JSON.stringify(arr));
    }

    function onInputChange(e, row, col) {
        var val = parseInt(e.target.value) || "", grid = getDeepCopy(kakuruArr);
        if ((val >=-504 && val <= 504) || (val === "")) {
            grid[row][col] = val;
        }
        setKakuruArr(grid);
    }

    function testAnswer(kakuruArr) {
        if (JSON.stringify(kakuruArr) === JSON.stringify(answerArray)) {
            setCorrectAnswer(true)
        } else {
            alert("That's not quite right, keep trying!")
        }
    }

    return ( 
        <div>
            <br/>
            <div style={{display:"flex", justifyContent:"center"}}>
            <table className="kakuru">
                <tbody>
                    {[0, 1, 2, 3, 4, 5, 6].map((row, rIndex) => {
                        return (
                        <tr key={rIndex}>
                            {[0, 1, 2, 3, 4, 5, 6].map((col, cIndex) => {
                                return (
                                <td key={rIndex + cIndex} className="kakuru">
                                    <input 
                                        className={((rIndex === 6 || cIndex === 6 || rIndex === 0 || cIndex === 0 || (rIndex === 1 && cIndex === 3) || (rIndex === 5 && cIndex === 3) || (rIndex === 3 && cIndex === 1) || (rIndex === 3 && cIndex === 5))) ? "kakuruDisabled" : "kakuruCellInput"}
                                        value={kakuruArr[rIndex][cIndex] === "" ? "" : kakuruArr[rIndex][cIndex] <= -1 ? "" : kakuruArr[rIndex][cIndex]}
                                        onChange={(e) => onInputChange(e, rIndex, cIndex)} 
                                        disabled={rIndex === 6 || cIndex === 6 || rIndex === 0 || cIndex === 0 || (rIndex === 1 && cIndex === 3) || (rIndex === 5 && cIndex === 3) || (rIndex === 3 && cIndex === 1) || (rIndex === 3 && cIndex === 5)}
                                        inputMode='numeric'
                                        />
                                </td>
                            )})}
                        </tr>
                    )})}
                </tbody>
            </table>
            </div>
            { correctAnswer === false ?
            <div className="blackButtons" onClick={()=>{testAnswer(kakuruArr)}}>TEST ANSWER</div>
            :
            <div><br/><div style={{minWidth:"270px"}}>{props.ClueK ? props.ClueK.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div> }</div></div>
            }
           
        </div>
    )
}