import { PrizesCard } from "./PrizesCard";
import { prizeArray } from "./PrizeListArray";

function PrizeStoreUT(props) {
    const prizeArrayAll = prizeArray.filter(prize => prize.State === "ALL")
    const prizeArrayOrem = prizeArray.filter(prize => prize.State === "UT" && prize.City === "Orem")
    const prizeArrayProvo = prizeArray.filter(prize => prize.State === "UT" && prize.City === "Provo")

    return (
        <div style={{display:"flex", alignItems:"center", flexDirection:"column", marginBottom:"250px"}}>
            <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}>
                    <h4>Anywhere</h4>
                </div>
                {/* <hr style={{backgroundColor:"rgb(235, 235, 235)", height:"3px", border:"none", padding:"0px", margin:"0px"}}/> */}
                {prizeArrayAll.map((prize, idx) => {
                    return (
                        <div key={idx}>
                            <PrizesCard prize={prize} coins={props.coins} user={props.user} func={props.func}/>
                        </div>
                    )           
                })}
                <div style={{height:"20px"}}></div>
            </div>

            <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}>
                    <h4>Orem</h4>
                </div>
                {/* <hr style={{backgroundColor:"rgb(235, 235, 235)", height:"3px", border:"none", padding:"0px", margin:"0px"}}/> */}
                {prizeArrayOrem.map((prize, idx) => {
                    return (
                        <div key={idx}>
                            <PrizesCard prize={prize} coins={props.coins} user={props.user} func={props.func}/>
                        </div>
                    )           
                })}
                <div style={{height:"20px"}}></div>
            </div>

            <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}>
                    <h4>Provo</h4>
                </div>
                {/* <hr style={{backgroundColor:"rgb(235, 235, 235)", height:"3px", border:"none", padding:"0px", margin:"0px"}}/> */}
                {prizeArrayProvo.map((prize, idx) => {
                    return (
                        <div key={idx}>
                            <PrizesCard prize={prize} coins={props.coins} user={props.user} func={props.func}/>
                        </div>
                    )           
                })}
                <div style={{height:"20px"}}></div>
            </div>

        </div> 
    )
}

export default PrizeStoreUT;