import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { SignIn } from "./Auth";
import { BrowserRouter, Route } from "react-router-dom";
import { TreasureHunt } from "./TreasureHunt";
import { AuthHuntPage } from "./AuthPageHunts";
import { AuthPagePurchases } from "./AuthPagePurchases";
import { AuthPrizePage } from "./AuthPagePrizes";
import { AuthSignInPage } from "./AuthPageSignIn";
import { Scoreboard } from "./Scoreboard";
import { AuthPageARlanding } from "./AuthSecARlanding";
import { AuthPageUTlanding } from "./AuthSecUTlanding";
import { Mission } from "./AuthSecMission";
import { Practice } from "./AuthPagePractice";
// import { NotFound } from "./404page";

// ReactGA.initialize('G-JF5DF58BV2')

ReactDOM.render(
<BrowserRouter>
    <div>
      <Route exact path="/" component={SignIn} />
      <Route path="/app" component={SignIn} />
      <Route exact path="/TreasureHunt/:id" component={TreasureHunt} />
      <Route path="/practice" component={Practice} />
      <Route path="/huntPage" component={AuthHuntPage} />
      <Route path="/purchases" component={AuthPagePurchases} />
      <Route path="/prizePage" component={AuthPrizePage} />
      <Route path="/signIn" component={AuthSignInPage} />
      <Route path="/scoreboard" component={Scoreboard} />
      <Route path="/ArkansasAdventures" component={AuthPageARlanding} />
      <Route path="/UtahAdventures" component={AuthPageUTlanding} />
      <Route path="/mission" component={Mission} />
      {/* <Route path='*' component={NotFound} /> */}
    </div>
  </BrowserRouter>, document.getElementById("root"));

serviceWorker.unregister();
