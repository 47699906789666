import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { db } from "./firebase";
import { ImNewTab } from "react-icons/im";
import { GiLaurelsTrophy } from "react-icons/gi";
import { BiWalk } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { IconContext } from "react-icons";
import Drawer from '@mui/material/Drawer';
import HuntCardPrices from "./HuntCardPrices";
import { Dialog, OutlinedInput  } from "@material-ui/core";
import { FaShare, FaPlay } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { AiOutlineTeam } from "react-icons/ai";
import { BsFire } from "react-icons/bs";

export function HuntCard(props) {
    const [openHuntDescription, setOpenHuntDescription] = useState(false);
    const product = props.hunt;

    return(
    <div style={{cursor:"pointer"}}>
        <div style={{flexDirection:"column", display:"flex"}}>
            <div style={{display:"flex"}} onClick ={() => {setOpenHuntDescription(true)}}>
                <div style={{marginLeft:"20px", width:"50px", height:"80px", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                  { product.Drive === "Walk" ?
                    <div style={{fontSize:"30px", marginTop:"10px"}}>
                      <svg width="30" height="30">
                        <defs>
                          <linearGradient id="myWalkGradient" gradientTransform="rotate(95)">
                            <stop offset="5%"  stopColor="greenyellow" />
                            <stop offset="100%" stopColor="green" />
                          </linearGradient>
                        </defs>
                        <IconContext.Provider value={{ attr: {fill: "url('#myWalkGradient')"}}}>
                          <BiWalk/>
                        </IconContext.Provider>
                      </svg>
                    </div>
                    : product.Drive === "Drive" ? 
                    <div style={{fontSize:"25px", marginTop:"15px"}}>
                      <svg width="30" height="25">
                        <defs>
                          <linearGradient id="myDriveGradient" gradientTransform="rotate(35)">
                            <stop offset="5%"  stopColor="greenyellow" />
                            <stop offset="100%" stopColor="green" />
                          </linearGradient>
                        </defs>
                        <IconContext.Provider value={{ attr: {fill: "url('#myDriveGradient')"}}}>
                          <FaCarSide/>
                        </IconContext.Provider>
                      </svg>
                    </div>
                    :
                    <div></div>
                  }
                    <div style={{whiteSpace:"pre", fontSize:"10px"}}>{product.Time}</div>
                    <div style={{whiteSpace:"pre", fontSize:"9px", marginTop:"2px"}}>{product.InOrOut}</div>    
                </div>
                <div style={{display:"flex", flexDirection:"column", flexGrow:"2"}}>
                    <div style={{height:"30px", display:"flex", alignItems:"flex-end", fontWeight:"bold", paddingLeft:"15px", fontSize:"13px"}}>{product.Title}</div>
                    {/* <div style={{height:"12px", paddingLeft:"5px", whiteSpace:"pre", fontSize:"12px", paddingTop:"10px"}}>{product.Difficulty} | {product.Time} | {product.Area}</div> */}
                    <div style={{paddingLeft:"20px", paddingBottom:"15px", fontSize:"10px"}}>
                        <div style={{display:"flex", paddingTop:"5px", alignItems:"center"}}>
                          {product.Difficulty === "Kids" ?
                            <BsFire style={{color:"greenyellow"}}/>
                          : product.Difficulty === "Easy" ?
                            <BsFire style={{color:"blue"}}/>
                          : product.Difficulty === "Medium" ?
                            <BsFire style={{color:"gold"}}/>
                          : product.Difficulty === "Hard" ?
                            <BsFire style={{color:"red"}}/>
                          :
                            <BsFire style={{color:"black"}}/>
                          }
                          <div style={{whiteSpace:"pre", paddingLeft:"5px"}}>{product.Difficulty}</div>
                        </div>
                        <div style={{display:"flex", paddingTop:"5px", alignItems:"center"}}>
                          <CiLocationOn />
                          <div style={{whiteSpace:"pre", paddingLeft:"5px"}}>{product.Area}</div>
                        </div>
                        <div style={{display:"flex", paddingTop:"5px", alignItems:"center"}}>
                          <AiOutlineTeam />
                          <div style={{whiteSpace:"pre", paddingLeft:"5px"}}>Up to {product.Teams} Teams</div>
                        </div>
                    </div>
                </div>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", textAlign:"center", alignItems:"center", width:"60px"}}><div style={{color:"black", fontSize:".8rem"}}>{product.PriceAmount}</div><div style={{color:"black", fontSize:"10px"}}>per team</div></div>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"20px", marginRight:"20px"}}><IoIosArrowForward/></div>
            </div>
        </div>
        <div style={{display:"flex", justifyContent:"center"}}>
            <hr style={{backgroundColor:"lightgray", height:"1px", border:"none", padding:"0px", margin:"0px", width:"90%"}}/>
        </div>

      
        <Drawer
          anchor="left"
          open={openHuntDescription} 
          PaperProps={{sx:{maxWidth:"400px", width:"90vw", backgroundColor:"#ebeef9"}}}
          onClose={() => {setOpenHuntDescription(false)}}
          >
          <div>
          <div className="huntCardDrawerMainDivDescription">
            {/* <a href={product.Directions} target="_blank" rel="noreferrer" style={{textDecoration:"none", color:"blue", textAlign:"right"}}><h5 style={{fontSize:"12px"}}>{product.Area} Map <ImNewTab style={{fontSize:"10px"}}/></h5></a>   */}
            <h4 style={{fontSize:"30px", paddingTop:"25px", paddingBottom:"10px"}}>{product.Title}</h4>
             <div style={{textAlign:"justify", margin:"15px"}}>
              <h5 style={{textJustify:"inter-character"}}>{product.Description}</h5>

              <h5 style={{fontFamily:"NewTextFontBold", marginTop:"20px"}}>Hunt Specific Info</h5>
              <table style={{fontFamily:"NewTextFont", fontSize:"12px"}}>
                <tbody>
                  <tr>
                    <td>Ages</td>
                    <td style={{width:"10px"}}></td>
                    <td>
                      { product.Difficulty === "Kids" ?
                        <div>Generally 6+</div>
                      : product.Difficulty === "Easy" ?
                        <div>Generally 12+</div>
                      : product.Difficulty === "Medium" ?
                        <div>Generally 16+</div>
                      : product.Difficulty === "Hard" ?
                        <div>Generally 18+</div>
                      :
                        <div></div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Difficulty</td>
                    <td style={{width:"10px"}}></td>
                    <td>
                      <div>{product.Difficulty}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Driving</td>
                    <td style={{width:"10px"}}></td>
                    <td>
                      { product.Drive === "Drive" ?
                      <div>You will need to drive for this hunt</div>
                      : product.Drive === "Walk" ?
                      <div>You will NOT drive for this hunt</div>
                      :
                      <div></div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Parking</td>
                    <td style={{width:"10px"}}></td>
                    <td>
                      <a href={product.Directions} target="_blank" rel="noreferrer" style={{textDecoration:"none", color:"blue", display:"inline"}}><div>{product.Area} Map <ImNewTab style={{fontSize:"10px"}}/></div></a>
                    </td>
                  </tr>
                  <tr>
                    <td>Seasons</td>
                    <td style={{width:"10px"}}></td>
                    <td>
                      { product.Winter === "Yes" ?
                      <div>Do this hunt in any season</div>
                      : product.Winter === "No" ?
                      <div>Snow on the ground may obscure clues</div>
                      :
                      <div></div>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>


              <h5 style={{fontFamily:"NewTextFontBold", marginTop:"20px"}}>General Info</h5>
              <ul style={{marginTop:"5px", listStyleType:"square", paddingLeft:"30px", fontFamily:"NewTextFont", fontSize:"12px"}}>
                <li><div style={{marginBottom:"5px"}}>As needed, use internet, pen, paper, etc.</div></li>
                <li><div style={{marginBottom:"5px"}}>Every clue has hints & puzzle answers within</div></li>
              </ul>         
              
            {product.Teams > 1 ?
              <div style={{display:"flex", flexDirection:"column", textAlign:"justify"}}>
                <h5 style={{textJustify:"inter-character"}}>Each option below has similar destinations & clues, but in different orders so you can race! </h5>
              </div>
              :
              <div></div>
            }
            </div>
            <HuntCardPrices id={product.id} user={props.user} />

            <h6 style={{textAlign:"center", marginTop:"1.75em", marginLeft:"10px", marginRight:"10px"}}>Click an option above to purchase the hunt. You'll then find your purchased hunt under the "Purchases" icon!</h6>
            <h6 style={{marginTop:"10px", marginBottom:"10px"}}>Purchased hunts don't expire until completed</h6>
          </div>
        </div>
        </Drawer>

    </div>
    )
}

export function PurchasedHuntCard(props) {
    const [value, setValue] = useState("");
    const [openOptions, setOpenOptions] = useState(false);
    const [openSendHunt, setOpenSendHunt] = useState(false)
    const [emailRecipient, setEmailRecipient] = useState("")
    const history = useHistory();

    const startTime = {
      startTime: new Date().getTime()
    }

    const buttonValues = () => {
          if (props.step > 7) {
              setValue(" | Complete")
              setOpenOptions(false)
          } else {
              if (props.step === "1" || props.step === 1) {
                db.collection("users").doc(props.user).collection("purchasedHunts").doc(props.id).update(startTime)
                .then(history.push(`/TreasureHunt/${props.id}`))
              } else {
                history.push(`/TreasureHunt/${props.id}`)
              }
          }
      }

    const sendHunt = () => {
      db.collection("sharedHunts").doc(emailRecipient).get().then(snapshot => {
        const queryData = snapshot.data()

        if (!queryData) {
          if (props.id === "A Small Sample") {
            alert("You cannot share the A Small Sample hunt. Everyone automatically receives this hunt when they create an account on our site!")
            setEmailRecipient("")
            setOpenSendHunt(false)
          } else {
            db.collection("sharedHunts").doc(emailRecipient).set({
              step: props.step,
              date: props.Date,
              hunt: props.id
            }).then(
              db.collection("users").doc(props.user).collection("giftedHunts").doc(props.id).set({recipient: emailRecipient, date: new Date()})
            ).then(
              db.collection("users").doc(props.user).collection("purchasedHunts").doc(props.id).delete()
            )
            setEmailRecipient("")
            setOpenSendHunt(false)
            props.func()
          }
        } else {
          alert("The recipient already has a hunt gifted to them. They need to accept the hunt in their account, under the purchases icon, before they can receive another gifted hunt")
          setEmailRecipient("")
          setOpenSendHunt(false)
        }
      })

    }

    return(
    <div>          
      {openOptions === true ?
        <div className="purchasedHuntCardDiv">
          <div>
            <div style={{display:"flex", flexGrow:"1"}}>
              <div onClick={() => {setOpenSendHunt(true)}} style={{width:"50%", paddingTop:"15px", display:"flex", flexDirection:"column", alignItems:"center", padding:"5px"}}>
                <div>
                  <svg width="30" height="30">
                    <defs>
                      <linearGradient id="myShareGradient" gradientTransform="rotate(35)">
                        <stop offset="5%"  stopColor="yellow" />
                        <stop offset="100%" stopColor="red" />
                      </linearGradient>
                    </defs>
                    <IconContext.Provider value={{ attr: {fill: "url('#myShareGradient')"}}}>
                      <FaShare style={{fontSize:"30px", width:"100%", color:"red"}}/>
                    </IconContext.Provider>
                  </svg>
                </div>
                <h6 style={{marginTop:"5px", textAlign:"center", fontSize:"10px"}}>Send Hunt To Another</h6>
              </div>

              <div onClick={buttonValues} style={{width:"50%", paddingTop:"15px", display:"flex", flexDirection:"column", alignItems:"center", borderLeft: "solid 1px black", padding:"5px"}}>
                <div>
                  <svg width="30" height="30">
                    <defs>
                      <linearGradient id="myBeginGradient" gradientTransform="rotate(35)">
                        <stop offset="5%"  stopColor="greenyellow" />
                        <stop offset="100%" stopColor="green" />
                      </linearGradient>
                    </defs>
                    <IconContext.Provider value={{ attr: {fill: "url('#myBeginGradient')"}}}>
                      <FaPlay style={{fontSize:"30px", width:"100%", color:"green"}}/>
                    </IconContext.Provider>
                  </svg>
                </div>
                <h6 style={{marginTop:"5px", textAlign:"center"}}>Begin<br/>Hunt</h6>
              </div>

            </div>
            <div style={{borderTop: "solid 1px black", fontSize:"11px", height:"20px", display:"flex", justifyContent:"center", alignItems:"center", zIndex:"3"}} onClick={() => {setOpenOptions(false)}}>GO BACK</div>
          </div>
        </div>
      :
        <div className="purchasedHuntCardDiv" onClick={() => {setOpenOptions(true)}}>
          <div style={{fontSize:"25px", paddingTop:"7px"}}>
            <svg width="60" height="60">
              <defs>
                <linearGradient id="myCupGradient" gradientTransform="rotate(35)">
                  <stop offset="5%"  stopColor="greenyellow" />
                  <stop offset="100%" stopColor="green" />
                </linearGradient>
              </defs>
              <IconContext.Provider value={{ attr: {fill: "url('#myCupGradient')"}}}>
                <GiLaurelsTrophy style={{fontSize:"60px"}}/>
              </IconContext.Provider>
            </svg>
          </div>
          
          <div className="purchasedHuntCardTextDiv">
            <h4 className="purchasedHuntCardText">{props.id}</h4>
            <h6 className="purchasedHuntCardText">{props.Date}{value}</h6>
          </div>
        </div>
      }  

       <Dialog
          anchor="left"
          open={openSendHunt} 
          onClose={() => {setOpenSendHunt(false)}}
          >
          <div className="authCard">
            <h3>Share Hunt</h3>
            <hr/>
            <h5>STEP 1: Enter the email address of the person who you want to send the hunt to
            <br/><br/>
            STEP 2: The recipient will need to create an account on HiddenHunts.com with the same email address and the hunt will automatically show up under the Purchases icon on their account.
            <br/><br/>
            No emails will be sent
            </h5>
            <br/>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                <OutlinedInput
                    placeholder={"Email address of recipient"}
                    style={{width:"280px", height:"45px"}}
                    onChange={e => {
                    setEmailRecipient(e.target.value.toUpperCase());
                    }}
                />     
                <br/>
                <div className="blackButtons" style={{width:"280px", marginTop:"10px"}} onClick={sendHunt}>SEND HUNT</div>
            </div>                   
          </div>
        </Dialog>

    </div>
    )
}

export function GiftedHuntCard(props) {

  const receiveHunt = () => {
    db.collection("users").doc(props.user).collection("purchasedHunts").doc(props.gift.hunt).set({
      date: new Date(),
      step: props.gift.step,
    }).then(
      db.collection("sharedHunts").doc(props.email.toUpperCase()).delete()
    )
    props.giftFunction()
  }

  return(
  <div>          
        <div className="giftedHuntCardDiv" onClick={receiveHunt}>
          <div style={{fontSize:"25px", paddingTop:"7px"}}>
            <svg width="60" height="60">
              <defs>
                <linearGradient id="myCupGradient" gradientTransform="rotate(35)">
                  <stop offset="5%"  stopColor="greenyellow" />
                  <stop offset="100%" stopColor="green" />
                </linearGradient>
              </defs>
              <IconContext.Provider value={{ attr: {fill: "url('#myCupGradient')"}}}>
                <GiLaurelsTrophy style={{fontSize:"60px"}}/>
              </IconContext.Provider>
            </svg>
          </div>
          
          <div className="purchasedHuntCardTextDiv">
            <h4 className="purchasedHuntCardText">Click to receive<br/>a gifted hunt</h4>
          </div>
        </div>

  </div>
  )
}