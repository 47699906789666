let alreadyCelebrating = false;

export const throwConfetti = (numConfettiSquares = 500, confettiSquareMaxSize = 15, sprayWidth = 7, sprayHeight = 15) => {
  if (alreadyCelebrating) {
    return;
  }

  alreadyCelebrating = true;
  
  function random_range(lo, hi) {
    return Math.floor(Math.random() * (hi - lo + 1)) + lo;
  }

  function random_uniform(lo, hi) {
    return Math.random() * (hi - lo) + lo;
  }

  function toRadians(degrees) {
    return degrees * Math.PI / 180;
  }

  const canvas = document.createElement('canvas');
  canvas.style.position = 'fixed';
  canvas.style.left = 0;
  canvas.style.bottom = 0;
  canvas.style.zIndex = 999999;
  canvas.style.pointerEvents = 'none';
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  const ctx = canvas.getContext('2d');

  document.body.appendChild(canvas);

  // OTHER IMPORTANT CONSTANTS
  const ANIMATION_SPEED = 2;
  const GRAVITY = 0.4 * ANIMATION_SPEED;
  const FRICTION = 0.99;

  // make confetti rectangles
  const confetti = Array(numConfettiSquares).fill(1).map((item, i) => {

    const startOnRightSide = !!random_range(0, 1);

    const sideLength = random_range(5, Math.max(10, confettiSquareMaxSize));

    const newRect = {
      color: {
        r: random_range(100, 255),
        g: random_range(100, 255),
        b: random_range(100, 255)
      },
      width: sideLength,
      height: sideLength,
      x: startOnRightSide ? canvas.width : 0,
      y: canvas.height,
      xspeed: startOnRightSide ? random_uniform(-1, -sprayWidth) : random_uniform(1, sprayWidth),
      yspeed: random_range(0,3) < 3 ? random_uniform(-sprayHeight * 0.75, -sprayHeight) : random_uniform(-sprayHeight * 0.2, -sprayHeight * 0.75),
      friction: random_uniform(FRICTION * 0.95, FRICTION),
      gravity: random_uniform(GRAVITY * 0.5, GRAVITY * 1.2),
      wind: random_uniform(-1.5, 1.5),
      theta: random_uniform(0, 359),
      thetaspeed: random_uniform(0.5, 5)
    };  

    newRect.xspeed *= ANIMATION_SPEED;
    newRect.yspeed *= ANIMATION_SPEED;
    newRect.thetaspeed *= ANIMATION_SPEED;

    return newRect;
  });

  let shouldContinueTicking = true;
  function tick() {

    function draw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (const c of confetti) {
        ctx.fillStyle = `rgb(${c.color.r}, ${c.color.g}, ${c.color.b})`;

        ctx.fillRect(c.x, c.y,
                     c.width * Math.cos(toRadians(c.theta)),
                     c.height * Math.sin(toRadians(c.theta))
                    );
      }
    }

    function update() {
      const maxFallSpeed = 5 * ANIMATION_SPEED;
      shouldContinueTicking = false;
      for (const c of confetti) {
        c.x += c.xspeed;
        c.x += c.wind;

        if (c.yspeed > 0) {
          c.y += Math.min(c.yspeed + c.yspeed * Math.sin(c.theta * (Math.PI / 180)), maxFallSpeed);
        } else {
          c.y += c.yspeed;
        }
        c.theta = (c.theta + c.thetaspeed) % 360;

        c.xspeed *= c.friction;
        c.yspeed = Math.min(c.yspeed + c.gravity, maxFallSpeed);

        if (c.y < canvas.height + c.height) {
          shouldContinueTicking = true;
        }
      }
    }

    update();
    draw();

    if (shouldContinueTicking) {
      setTimeout(tick, 1000 / 60);
    } else {
      canvas.remove();
      alreadyCelebrating = false;
    }
  }

  window.requestAnimationFrame(tick);
}


