import React, {useState} from "react";

export function MathSquare(props) {
    
    const myArray = props.mathData
    const answerArray = props.mathAnswer

    const [mathArr, setMathArr] = useState(myArray);
    const [correctAnswer, setCorrectAnswer] = useState(false);
    
    function getDeepCopy(arr){
        return JSON.parse(JSON.stringify(arr));
    }

    function onInputChange(e, row, col) {
        var val = parseInt(e.target.value) || -2000, grid = getDeepCopy(mathArr);
        if (val === -2000 || (val >=-504 && val <= 504)) {
            grid[row][col] = val;
        }
        setMathArr(grid);
    }

    function testAnswer(mathArr) {
        if (JSON.stringify(mathArr) === JSON.stringify(answerArray)) {
            setCorrectAnswer(true)
        } else {
            alert("That's not quite right, keep trying! Remember, unique digits of 1-9 in the blank cells, and the puzzle is solved left to right and top to bottom - ignore PEMDAS")
        }
    }

    return ( 
        <div>
            <br/>
            <table className="mathSquare">
                <tbody>
                    {[0, 1, 2, 3, 4, 5].map((row, rIndex) => {
                        return (
                        <tr key={rIndex}>
                            {[0, 1, 2, 3, 4, 5].map((col, cIndex) => {
                                return (
                                <td key={rIndex + cIndex} className="mathSquare">
                                    <input 
                                        className={((row === 5 || col === 5) & myArray[row][col] !== -2000) ? "mathSquareTotalCell" : (myArray[row][col] === -2000) ? "mathSquareEmptyCells" : (myArray[row][col] === "X" || myArray[row][col] === "-" || myArray[row][col] === "+" || myArray[row][col] === "/") ? "mathSquareDisabled" : "mathSquareCellInput"}
                                        value={mathArr[row][col] === "" ? "" : mathArr[row][col] === -2000 ? "" : mathArr[row][col]}
                                        onChange={(e) => onInputChange(e, row, col)} 
                                        disabled={myArray[row][col] === "X" || myArray[row][col] === "-" || myArray[row][col] === "+" || myArray[row][col] === "/"}
                                        inputMode='numeric'
                                        />
                                </td>
                            )})}
                        </tr>
                    )})}
                </tbody>
            </table>
            { correctAnswer === false ?
            <div className="blackButtons" onClick={()=>{testAnswer(mathArr)}}>TEST ANSWER</div>
            :
            <div><br/><div style={{width:"270px"}}>COORDINATES:<br/>{props.ClueMS ? props.ClueMS.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div> }</div></div>
            }
           
        </div>
    )
}