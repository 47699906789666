import React, { useState } from "react";
import { db, snapshotToArray } from "./firebase";
import { PracticeCard } from "./PracticeCard";
import { HeaderBar } from "./HeaderBar";
import { FooterTwo } from "./Footer";
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';

export function Practice(props) {
  // GENERAL SET UP VARIABLES
  const [huntData, setHuntData] = useState([]);
  // ALL OTHER BUTTON VARIABLES
  const [loadPuzzles, setLoadPuzzles] = useState(false)

  const startPuzzles = () => {
  
    const landingData = db.collection("hunts").doc("landingPage").collection("clues").get().then(snapshot => {
      const queryData = snapshotToArray(snapshot);
      setHuntData(queryData)
      setLoadPuzzles(true)
    })
      return landingData;
  }

    return (
      <HelmetProvider>
      <div className="pageContainer">
        <div className="appBackground">

          <Helmet link = {[{ rel : 'canonical', href : "https://www.hiddenhunts.com/welcome"  }]}>
              <title>Hidden Hunts - Sample Clues</title>
              <meta name='description' content="Test out some sample easy, medium, and hard clues to see which level of difficulty hunt you should take on for the most fun"/>
              <meta name="keywords" content="sample clues, example treasure hunt, test clues, free riddles, free puzzles"/>
          </Helmet>  

          <HeaderBar PageID="Practice"/>

          <div className="authHeader">
            <div className="landingPageOfferDiv">
              <h4 style={{fontSize:"1.5rem", textAlign:"center"}}>Welcome to<br/>Hidden Treasure Hunts!</h4>
              <br/>
              <h4 style={{textAlign:"center"}}>The following practice clues are meant to give you a little taste of the difficulty of clues we include in our Easy, Medium and Hard hunts.<br/><br/>Actual hunts that you purchase will lead you around a series of destinations, but these clues won't, so you're able to try them from wherever you are now!<br/><br/><br/></h4>
              {(loadPuzzles===false) ? <div><div className="landingPageLoadPuzzleButtons" style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"20px", marginBottom:"200px"}} onClick={()=>{startPuzzles()}}>Let's get started!</div></div> : <></> }
            </div>
          </div>

          {(loadPuzzles===false) ?
            <></>
            :
            <div>
              {huntData?.map((hunt, idx) => {
                  return (
                      <div key={idx} style={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                          <PracticeCard clueNumber={hunt.id} Answer={hunt.Answer} Clue={hunt.Clue} ClueI={hunt.ClueI} ClueFW={hunt.ClueFW} ClueWL={hunt.ClueWL} Hint={hunt.Hint} I={hunt.I} Question={hunt.Question} />
                      </div>
                  )           
              })}
            </div>
          }

          <FooterTwo/>
        </div>   
           
      </div>
      </HelmetProvider>
    );

  }
