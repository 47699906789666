import React, {useState} from "react";
import { Kakuru } from "../ClueKakuru";

export function KakuruData(props) {
    const [kakuruArrayData, setKakuruArrayData] = useState([]);
    const [kakuruAnswer, setKakuruAnswer] = useState([]);
    const [clueBegan, setClueBegan] = useState(false);

    const handleLoadPuzzle = () => {
        setClueBegan(true)
        if (props.ClueKid === "Get a Clue") {
            setKakuruArrayData([
                [-2,    17,     35,     14,      18,    11,      -2],

                [16,    -1,      7,     -2,       1,     2,       3],
                [34,     8,      6,     -1,      -1,     9,      -2],
                [ 8,    -2,      5,     -1,      -1,    -2,      -2],
                [23,     3,     -1,      6,       5,     1,      -2],
                [16,    -1,      9,     -2,       6,     3,       9],
                
                [-2,    10,     -2,     -2,      -2,     4,      -2]
                ])
            setKakuruAnswer([
                [-2,    17,     35,     14,     18,     11,      -2],

                [16,     9,      7,     -2,       1,     2,       3],
                [34,     8,      6,      7,       4,     9,      -2],
                [ 8,    -2,      5,      1,       2,    -2,      -2],
                [23,     3,      8,      6,       5,     1,      -2],
                [16,     7,      9,     -2,       6,     3,       9],
                
                [-2,    10,     -2,     -2,      -2,     4,      -2]
                ])
        } else if (props.ClueKid === "NumberOne") {
            setKakuruArrayData([
                [-2,    11,     24,     12,      11,    11,      -2],

                [10,    -1,      8,     -2,      -2,    -2,      -2],
                [21,    -1,      7,     -1,      -2,    -2,      -2],
                [23,    -2,      9,     -1,      -1,    -2,      -2],
                [ 6,    -2,     -2,     -1,      -1,    -1,      -2],
                [ 9,    -2,     -2,     -2,       1,     8,      -2],
                
                [-2,    -2,     -2,     -2,      -2,    -2,      -2]
                ])
            setKakuruAnswer([
                [-2,    11,     24,     12,      11,    11,      -2],

                [10,     2,      8,     -2,      -2,    -2,      -2],
                [21,     9,      7,      5,      -2,    -2,      -2],
                [23,    -2,      9,      6,       8,    -2,      -2],
                [ 6,    -2,     -2,      1,       2,     3,      -2],
                [ 9,    -2,     -2,     -2,       1,     8,      -2],
                
                [-2,    -2,     -2,     -2,      -2,    -2,      -2]
                ])
        } else if (props.ClueKid === "NumberTwo") {
            setKakuruArrayData([
                [-2,    17,     13,      8,       9,    12,      -2],

                [ 4,    -2,     -2,     -2,       1,     3,      -2],
                [13,    -2,     -2,     -1,      -1,    -1,      -2],
                [12,    -2,     -1,     -1,      -1,    -2,      -2],
                [14,     8,     -1,     -1,      -2,    -2,      -2],
                [16,     9,     -1,     -2,      -2,    -2,      -2],
                
                [-2,    -2,     -2,     -2,      -2,    -2,      -2]
                ])
            setKakuruAnswer([
                [-2,    17,     13,      8,       9,    12,      -2],

                [ 4,    -2,     -2,     -2,       1,     3,      -2],
                [13,    -2,     -2,      1,       3,     9,      -2],
                [12,    -2,      4,      3,       5,    -2,      -2],
                [14,     8,      2,      4,      -2,    -2,      -2],
                [16,     9,      7,     -2,      -2,    -2,      -2],
                
                [-2,    -2,     -2,     -2,      -2,    -2,      -2]
                ])
        } else if (props.ClueKid === "NumberThree") {
            setKakuruArrayData([
                [-2,     4,     23,     24,      19,     5,      -2],

                [10,     1,     -1,     -2,      -2,    -2,      -2],
                [17,     3,      6,     -1,      -2,    -2,      -2],
                [17,    -2,     -1,     -1,      -1,    -2,      -2],
                [21,    -2,     -2,      9,      -1,     4,      -2],
                [10,    -2,     -2,     -2,      -1,     1,      -2],
                
                [-2,    -2,     -2,     -2,      -2,    -2,      -2]
                ])
            setKakuruAnswer([
                [-2,     4,     23,     24,      19,     5,      -2],

                [10,     1,      9,     -2,      -2,    -2,      -2],
                [17,     3,      6,      8,      -2,    -2,      -2],
                [17,    -2,      8,      7,       2,    -2,      -2],
                [21,    -2,     -2,      9,       8,     4,      -2],
                [10,    -2,     -2,     -2,       9,     1,      -2],
                
                [-2,    -2,     -2,     -2,      -2,    -2,      -2]
                ])
        } else if (props.ClueKid === "NumberFour") {
            setKakuruArrayData([
                [-2,    16,     26,      5,      20,     9,      -2],

                [ 3,    -2,      2,      1,      -2,    -2,      -2],
                [23,    -1,      9,      4,      -1,    -2,      -2],
                [17,    -1,      8,     -2,       2,    -1,       3],
                [24,    -2,      7,      3,      -1,    -1,      -2],
                [10,    -2,     -2,      1,      -1,    -2,      -2],
                
                [-2,    -2,     -2,      4,      -2,    -2,      -2]
                ])
            setKakuruAnswer([
                [-2,    16,     26,      5,      20,     9,      -2],

                [ 3,    -2,      2,      1,      -2,    -2,      -2],
                [23,     7,      9,      4,       3,    -2,      -2],
                [17,     9,      8,     -2,       2,     1,       3],
                [24,    -2,      7,      3,       6,     8,      -2],
                [10,    -2,     -2,      1,       9,    -2,      -2],
                
                [-2,    -2,     -2,      4,      -2,    -2,      -2]
                ])
        } 
    }


    return (
        <div> 
            <br/>
            {clueBegan === false ?
              <div className="loadPuzzleButtons" onClick={()=>{handleLoadPuzzle()}}>Load Puzzle</div>
              :
              <Kakuru kakuruData={kakuruArrayData} kakuruAnswer={kakuruAnswer} ClueK={props.ClueK}/>
            }
            <br/>
        </div>
    )
}