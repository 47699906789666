import { useContext } from "react";
import { CartContext } from "./CartContext";
import { HiShoppingCart } from "react-icons/hi";
import { IconButton } from "@material-ui/core";

function CartIcon(props) {
    // const [numberInCart, setNumberInCart] = useState(0)

    const appUser = props.user
    const cart = useContext(CartContext);

    function loadCart (user) {
        cart.updateCartProducts(user)
      }

    return (
        <div>
            <IconButton onClick={() => {loadCart(appUser)}}>
                <HiShoppingCart style={{color:"blue", fontSize:"1.6rem"}}/>
            </IconButton>
        </div>
    )
}

export default CartIcon;