// AUTHENTICATION GENERAL IMPORTS
import React, {useEffect, useState} from "react";
import {auth} from "./firebase";
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { HeaderBar } from "./HeaderBar";
import CartProvider from "./CartContext";

// AUTHENTICATION MAIN CONTENT IMPORTS
import HuntStoreAR from "./HuntStoreAR";
import HuntStoreUT from "./HuntStoreUT";
import {FooterTwo} from "./Footer";
import { Carousel } from "./Carousel";
import slides from "./pictures/carousel/carouselData.json";

export function AuthHuntPage(props) {
  const [user, setUser] = useState(null);
  const [stateSelect, setStateSelect] = useState("UT")

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(u => {
      if (u) {
        setUser(u);
      }
    });
    return unsubscribe;
  }, [props.history]);
  
  const handleStateChange = (event) => {
    setStateSelect(event.target.value);
  };


  return (
    <CartProvider>
    <HelmetProvider>
    <div className="pageContainer">
        <div className="appBackground">

            {/* SEO INFO */}
            <Helmet link = {[{ rel : 'canonical', href : "https://www.hiddenhunts.com/huntPage"  }]}>
                <title>Treasure Hunt Adventures</title>
                <meta name='description' content="Explore locally like never before with progressive puzzle adventures - take on clues, discover unique places, and win prizes!"/>
                <meta name="keywords" content="Utah adventures, NWA activities, escape rooms, scavenger hunts, treasure hunts, unique activites"/>
            </Helmet>  

            {/* RENDERED PAGE INFO */}
            <HeaderBar PageID="Hunts" user={user}/>
                
            <div className="appMainContentDiv">
              <div>
                <div style={{display:"flex", justifyContent:"center", marginBottom:"2em", marginTop:"-1em", color:"#2E59FF", paddingLeft:"20px", paddingRight:"20px", textAlign:"center"}}>
                  <h4>
                    NOW THROUGH AUGUST - Use coupon code SUMMER24 for 50% off!
                  </h4>
                </div>
                <div style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>
                  <Carousel data={slides} />
                </div>
                {/* <h4 style={{width:"100%", textAlign:"center", fontSize:"2rem",color:'#2E59FF'}}>Adventure Options</h4> */}
                {/* <hr style={{width:"90%", marginTop:"50px", height:"3px", backgroundColor:"black", border:"none"}}/> */}
                <div style={{display:"flex", justifyContent:"center", color:"black", marginBottom:"1em", marginTop:"2.5em"}}>
                    <FormControl sx={{ m: 0, boxShadow:"none", '.MuiOutlinedInput-notchedOutline': { border: 0}}} size="small">
                      <Select
                        MenuProps={{ MenuListProps: { sx: { "li.MuiButtonBase-root": { display: "flex", flexDirection: "column", alignItems:"flex-start", justifyContent:"center", paddingLeft:"15px", width:"120px", fontFamily:"NewTextFontBold"}}}}}
                        sx={{fontFamily:"NewTextFontBold", fontSize:"40px", color:"#2E59FF"}}
                        value={stateSelect}
                        onChange={handleStateChange}
                      >
                        <MenuItem value={"UT"}>UTAH</MenuItem>
                        <MenuItem value={"AR"}>ARKANSAS</MenuItem>
                      </Select>
                    </FormControl>
                </div>
                {/* <hr style={{width:"90%", marginBottom:"50px", height:"3px", backgroundColor:"black", border:"none"}}/> */}
                <div>
                  { stateSelect === "UT" ? <div>{(user===null) ? <HuntStoreUT /> : <HuntStoreUT user = {user.uid} /> } </div> : <div></div> }
                  { stateSelect === "AR" ? <div>{(user===null) ? <HuntStoreAR /> : <HuntStoreAR user = {user.uid} /> } </div> : <div></div> }
                </div>
              </div>
            </div>

            <FooterTwo />
        </div>
    
    </div>
    </HelmetProvider>
    </CartProvider>
  );
}
