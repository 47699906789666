import { IconContext } from "react-icons";
import { RiTeamFill } from "react-icons/ri";
import { CartContext } from "./CartContext";
import { useContext, useState } from "react";
import { Dialog } from "@material-ui/core";

function HuntCardPricesCard (props) {
    const [classChange, setClassChange] = useState("whiteButtons")
    const [openNeedUser, setOpenNeedUser] = useState(false)

    const appUser = props.user
    const cart = useContext(CartContext);

    const makePurchase = () => {
        if (props.user) {
            cart.addOneToCart(props, appUser)
            setClassChange("whiteButtonsClicked")
        } else {
            setOpenNeedUser(true)
        } 
    }
    

    return (
        <div>
            <div id="teamButton" className={classChange} onClick={makePurchase}>
            { classChange !== "whiteButtonsClicked" ?
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                    <div style={{padding:"2px"}}>Team {props.team}</div>
                    <div style={{fontSize:"25px"}}>
                    <svg width="30" height="30">
                        <defs>
                        <linearGradient id="myTeamGradient" gradientTransform="rotate(35)">
                            <stop offset="5%"  stopColor="greenyellow" />
                            <stop offset="100%" stopColor="green" />
                        </linearGradient>
                        </defs>
                        <IconContext.Provider value={{ attr: {fill: "url('#myTeamGradient')"}}}>
                            <RiTeamFill/>
                        </IconContext.Provider>
                    </svg>
                    </div>
                    <div style={{fontSize:"10px"}}>(2-3 people)</div>
                </div>
            :
                <div>Added To Cart</div>
            }
            </div>

{/* DIALOG BOXES */}
      <Dialog open={openNeedUser} onClose={() => {setOpenNeedUser(false)}}>
        <div>
          <div className="authCard"><h2>NOT PURCHASED</h2><hr/>
            <h4 style={{textAlign:"center"}}><a href="/signIn" rel="noreferrer" style={{color:"blue"}}>Sign in</a> to your free account to purchase a hunt</h4>
          </div>
        </div>
      </Dialog>

        </div>
    )
}

export default HuntCardPricesCard;