import { huntArray } from "./HuntListArray";
import HuntCardPricesCard from "./HuntCardPricesCard";

function HuntCardPrices(props) {
    const smallPricesList = huntArray.filter(hunt => hunt.id === props.id)

    return (
        <div>
            {smallPricesList.map((hunt, idx) => {
                return (
                    <div key={idx} style={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                        {hunt.priceList?.map((data, idx) => {
                            return (
                                <div key={idx}>
                                    <HuntCardPricesCard team={data.team} price={data.price} id={data.id} user={props.user} PriceAmount={data.PriceAmount}/>
                                </div>
                            )
                        })}
                    </div>
                )           
            })}

        </div> 
    )
}

export default HuntCardPrices;