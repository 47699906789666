import { HuntCard } from "./HuntCard"
import { huntArray } from "./HuntListArray"

function HuntStoreUT(props) {
    const huntArrayDraper = huntArray.filter(hunt => hunt.State === "UT" && hunt.City === "Draper")
    const huntArrayHerriman = huntArray.filter(hunt => hunt.State === "UT" && hunt.City === "Herriman")
    const huntArrayLehi = huntArray.filter(hunt => hunt.State === "UT" && hunt.City === "Lehi")
    const huntArrayOrem = huntArray.filter(hunt => hunt.State === "UT" && hunt.City === "Orem")
    const huntArrayProvo = huntArray.filter(hunt => hunt.State === "UT" && hunt.City === "Provo")
    const huntArrayWestJordan = huntArray.filter(hunt => hunt.State === "UT" && (hunt.City === "West Jordan" || hunt.City === "Midvale"))
    
    return (
        <div style={{display:"flex", alignItems:"center", flexDirection:"column", marginBottom:"250px"}}>
            <div>
                <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                    <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}><h4>Draper</h4></div>
                    {huntArrayDraper.map((hunt, idx) => {return (<div key={idx}><HuntCard hunt={hunt} user={props.user}/></div>)})}
                    <div style={{height:"20px"}}></div>
                </div>
                
                <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                    <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}><h4>Herriman</h4></div>
                    {huntArrayHerriman.map((hunt, idx) => {return (<div key={idx}><HuntCard hunt={hunt} user={props.user}/></div>)})}
                    <div style={{height:"20px"}}></div>
                </div>

                <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                    <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}><h4>Lehi</h4></div>
                    {huntArrayLehi.map((hunt, idx) => {return (<div key={idx}><HuntCard hunt={hunt} user={props.user}/></div>)})}
                    <div style={{height:"20px"}}></div>
                </div>

                <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                    <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}><h4>Orem</h4></div>
                    {huntArrayOrem.map((hunt, idx) => {return (<div key={idx}><HuntCard hunt={hunt} user={props.user}/></div>)})}
                    <div style={{height:"20px"}}></div>
                </div>

                <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                    <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}><h4>Provo</h4></div>
                    {huntArrayProvo.map((hunt, idx) => {return (<div key={idx}><HuntCard hunt={hunt} user={props.user}/></div>)})}
                    <div style={{height:"20px"}}></div>
                </div>

                <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                    <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}><h4>West Jordan/Midvale</h4></div>
                    {huntArrayWestJordan.map((hunt, idx) => {return (<div key={idx}><HuntCard hunt={hunt} user={props.user}/></div>)})}
                    <div style={{height:"20px"}}></div>
                </div>
            </div>
        </div> 
    )
}

export default HuntStoreUT;