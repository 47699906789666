import React from "react";
import {getMatrixPosition, getVisualPosition} from "./ClueSlidingPuzzleHelpers";
import { TILE_COUNT, GRID_SIZE, BOARD_SIZE } from "./ClueSlidingPuzzleConstants";

function Tile(props) {
    // const { handleTileClick } = props;

    const { row, col } = getMatrixPosition(props.index);
    const visualPos = getVisualPosition(row, col, props.width, props.height);
    const tileStyle = {
        width: `calc(100% / ${GRID_SIZE})`,
        height: `calc(100% / ${GRID_SIZE})`,
        translateX: visualPos.x,
        translateY: visualPos.y,
        backgroundImage: `url(${props.imgUrl})`,
        backgroundSize: `${BOARD_SIZE}px`,
        backgroundPosition: `${132 * ((props.tile % GRID_SIZE) / GRID_SIZE)}% ${((132 / GRID_SIZE) * (Math.floor(props.tile / GRID_SIZE)))}%`
    }
    
    return (
        <li style={{
            ...tileStyle,
            transform: `translate3d(${tileStyle.translateX}px, ${tileStyle.translateY}px`,
            // Is last tile?
            opacity: props.tile === TILE_COUNT - 1 ? 0 : 1,
        }}
        className ="slidingPuzzleTile"
        onClick={()=>{props.handleTileClick(props.index)}}
        >
            {props.tile + 1}
        </li>
    )
}

export default Tile;