// AUTHENTICATION GENERAL IMPORTS
import React, {useEffect, useState} from "react";
import { auth, db, snapshotToArray } from "./firebase";
import {IconButton} from "@material-ui/core";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Drawer from '@mui/material/Drawer';
import CartView from "./CartView";
import CartProvider from "./CartContext";
import CartIcon from "./CartIcon";
import { loadStripe } from "@stripe/stripe-js";


// AUTHENTICATION HEADER IMPORTS
import { AiFillHome } from "react-icons/ai";
import { GiTreasureMap, GiPayMoney } from "react-icons/gi";
import { BsPersonFill } from "react-icons/bs";
import { GiLaurelsTrophy } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { BiMenu } from "react-icons/bi";
import LogoutIcon from '@mui/icons-material/Logout';


export function HeaderBar(props) {
  const [user, setUser] = useState(null);
  const [menuList, setMenuList] = useState(false)
  const [openCart, setOpenCart] = useState(false)
  const [purchaseInitiated, setPurchaseInitiated] = useState(false)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(u => {
      if (u) {
        setUser(u);
      }
    });
    return unsubscribe;
  }, [props.history]);

  const PageID = props.PageID

  function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  const handleSignOut = () => {
    auth
      .signOut()
      .then(
        window.location.reload()
      )
      .catch(error => {
        alert(error.message);
      });
  };

  function loadCart (props) {
    setOpenCart(true)
    setPurchaseInitiated(false)
  }

  const linkToStripe = () => {
    setPurchaseInitiated(true)

    db.collection("users").doc(user.uid).collection("shoppingCart").get().then(snapshot => {
      const queryData = snapshotToArray(snapshot);
      const lineItems = []
      queryData.forEach((item) => {
        lineItems.push({
          price: item.price,
          quantity: 1,
        })
      })
      return lineItems
    }).then((lineItemsData) => {
    db.collection("users").doc(user.uid).collection("checkout_sessions").add({
      line_items: lineItemsData,
      mode: 'payment',
      success_url: window.location.href,
      cancel_url: window.location.href,
      return_url: window.location.href,
      allow_promotion_codes: true,
    })
    .then((docRef) => {
      docRef.onSnapshot(async (snap) => {
        const {error, sessionId} = snap.data();
        if (error) {
          alert(`An error occurred: ${error.message}`)
        }

        if (sessionId) {
          const stripe = await loadStripe(
            "pk_live_51JrDpgGAEvrTnitxkmAhgzWqlVDA9UkzMbooSSDBbvpHqN0HZDQXDMnNOo067V87Q0h5I7L7pQOGjRyzPSDUjxSQ002KOJG7Du"
          )
          await stripe.redirectToCheckout({sessionId})
        }
      })
    })

  })}

  return (
    <CartProvider>
    <div>
        {/* TOP ICON BAR */}
        <div>
        <ElevationScroll {...props}>
            <AppBar style={{ backgroundColor: "#ebeef9", width:"100%", height:"60px"}}>
                <Toolbar style={{display:"flex", justifyContent:"center"}}>
                    <div style={{display:"flex", justifyContent:"space-between", width:"100%", maxWidth:"800px", alignItems:"center"}}>
                        {(PageID==="Hunts") ? <h3 style={{color:"black", fontFamily:"NewTextFontBold"}}>Adventure Options</h3> : <h3 style={{color:"black", fontFamily:"NewTextFontBold"}}>Hidden Treasure Hunts</h3> }
                        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                        {(PageID==="Hunts") ? <div>{(user===null) ? <div></div> : <div>{user.uid ? <div onClick={()=>{loadCart(user.uid)}}><CartIcon user={user.uid}/></div> : <div></div>}</div>}</div> : <></> }
                          <BiMenu style={{fontSize:"30px", color:"black"}} onClick={() => {setMenuList(true)}}/>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
        </div>

        <Drawer
          anchor="right"
          open={menuList} 
          onClose={() => {setMenuList(false)}}
          PaperProps={{ sx: { backgroundColor:"#ebeef9" } }}
          >
            <div>
              {PageID ?
                <div style={{width:"80vw", maxWidth:"400px", display:"flex", flexDirection:"column", height:"22em", justifyContent:"space-around", paddingTop:"2em", alignItems:"flex-start"}}>
                    {(PageID==="Home") ? <a href="/"          className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><AiFillHome className="universalSelectedIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalSelectedIconFormat" style={{width:"10em"}}>Home</h4><IoIosArrowForward   style={{color:"black"}} /></div></a> : <a href="/"          className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><AiFillHome className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat" style={{width:"10em"}}>Home</h4><IoIosArrowForward   style={{color:"black"}} /></div></a> }
                    {(PageID==="Hunts") ? <a href="/huntPage"  className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><GiTreasureMap className="universalSelectedIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalSelectedIconFormat" style={{width:"10em"}}>Hunts</h4><IoIosArrowForward  style={{color:"black"}}/></div></a> : <a href="/huntPage"  className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><GiTreasureMap className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat" style={{width:"10em"}}>Hunts</h4><IoIosArrowForward  style={{color:"black"}}/></div></a> }
                    {(user!==null) ? <div>{(PageID==="Purchases") ? <a href="/purchases"    className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><GiPayMoney className="universalSelectedIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalSelectedIconFormat" style={{width:"10em"}}>Purchases</h4><IoIosArrowForward style={{color:"black"}}/></div></a> : <a href="/purchases"    className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><GiPayMoney className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat" style={{width:"10em"}}>Purchases</h4><IoIosArrowForward style={{color:"black"}}/></div></a> }</div> : <></>}
                    {(PageID==="Prizes") ? <a href="/prizePage" className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><GiLaurelsTrophy className="universalSelectedIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalSelectedIconFormat" style={{width:"10em"}}>Prizes</h4><IoIosArrowForward style={{color:"black"}}/></div></a> : <a href="/prizePage" className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><GiLaurelsTrophy className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat" style={{width:"10em"}}>Prizes</h4><IoIosArrowForward style={{color:"black"}}/></div></a> }
                    {(user===null) ? <div>{(PageID==="SignIn") ? <a href="/signIn"    className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><BsPersonFill className="universalSelectedIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalSelectedIconFormat" style={{width:"10em"}}>Sign In</h4><IoIosArrowForward style={{color:"black"}}/></div></a> : <a href="/signIn"    className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><BsPersonFill className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat" style={{width:"10em"}}>Sign In</h4><IoIosArrowForward style={{color:"black"}}/></div></a> }</div> : <></>}
                    {(user!==null) ? <h4 onClick={handleSignOut}  className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><LogoutIcon className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat" style={{width:"10em"}}>Sign Out</h4><IoIosArrowForward style={{color:"black"}}/></div></h4> : <></>}
                    <hr style={{width:"90%", marginBottom:"0"}}/>
                    {(PageID==="Mission") ? <a href="/mission"    style={{textDecoration:"none", fontSize:".8rem", fontFamily:"NewTextFont", display:"flex", justifyContent:"flex-end", width:"90%", color:"#FD2EFF", marginTop:"-25px"}}>Our Mission</a> : <a href="/mission"    style={{textDecoration:"none", fontSize:".8rem", fontFamily:"NewTextFont", display:"flex", justifyContent:"flex-end", width:"90%", color:"black", marginTop:"-25px"}}>Our Mission</a>}       
                    {(PageID==="Practice") ? <a href="/practice"    style={{textDecoration:"none", fontSize:".8rem", fontFamily:"NewTextFont", display:"flex", justifyContent:"flex-end", width:"90%", color:"#FD2EFF", marginTop:"-25px"}}>Practice Clues</a> : <a href="/practice"    style={{textDecoration:"none", fontSize:".8rem", fontFamily:"NewTextFont", display:"flex", justifyContent:"flex-end", width:"90%", color:"black", marginTop:"-25px"}}>Practice Clues</a>}       
                </div>
              :
                <div style={{width:"80vw", maxWidth:"400px", display:"flex", flexDirection:"column", height:"22em", justifyContent:"space-around", paddingTop:"2em", alignItems:"flex-start"}}>
                    <a href="/"          className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><AiFillHome className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat"                 style={{width:"10em"}}>Home</h4><IoIosArrowForward   style={{color:"black"}} /></div></a>
                    <a href="/huntPage"  className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><GiTreasureMap className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat"              style={{width:"10em"}}>Hunts</h4><IoIosArrowForward  style={{color:"black"}}/></div></a>
                    {(user!==null) ? <a href="/purchases"  className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><GiPayMoney className="universalSelectedIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalSelectedIconFormat" style={{width:"10em"}}>Purchases</h4><IoIosArrowForward style={{color:"black"}}/></div></a> : <></> }
                    <a href="/prizePage" className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><GiLaurelsTrophy className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat"            style={{width:"10em"}}>Prizes</h4><IoIosArrowForward style={{color:"black"}}/></div></a>
                    {(user===null) ? <a href="/signIn"    className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><BsPersonFill className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat"               style={{width:"10em"}}>Sign In</h4><IoIosArrowForward style={{color:"black"}}/></div></a> : <></> }           
                    {(user!==null) ? <h4 onClick={handleSignOut}  className="authHeaderIcon" style={{textDecoration:"none", fontSize:"20px"}}><div style={{display:"flex", alignItems:"center"}}><IconButton style={{padding:"0px"}}><LogoutIcon className="universalIconFormat" style={{width:"60px"}}/></IconButton><h4 className="universalIconFormat" style={{width:"10em"}}>Sign Out</h4><IoIosArrowForward style={{color:"black"}}/></div></h4> : <></>}
                    <hr style={{width:"90%", marginBottom:"0"}}/>
                    <a href="/mission"    style={{textDecoration:"none", fontSize:".8rem", fontFamily:"NewTextFont", display:"flex", justifyContent:"flex-end", width:"90%", color:"black", marginTop:"-25px"}}>Our Mission</a>            
                    <a href="/practice"    style={{textDecoration:"none", fontSize:".8rem", fontFamily:"NewTextFont", display:"flex", justifyContent:"flex-end", width:"90%", color:"black", marginTop:"-25px"}}>Practice Clues</a>            
                </div>
              }
              </div>
        </Drawer>    

        <Drawer
          anchor="left"
          open={openCart} 
          onClose={() => {setOpenCart(false)}}
          PaperProps={{ sx: { backgroundColor:"#ebeef9" } }}
          >
            <div style={{padding:"20px", width:"80vw", maxWidth:"400px"}}>
              <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", fontSize:"25px", marginBottom:"30px"}}><h3 style={{textAlign:"left"}}>Shopping Cart</h3></div>
              {(user===null) ? <div></div> : <CartView user={user.uid}/> }
              <br/>
              <div style={{width:"80vw", maxWidth:"400px"}}>
                { purchaseInitiated === false ? 
                  <div className="FAQButtons" onClick={linkToStripe}>Checkout</div>
                :
                  <div className="FAQButtons" onClick={linkToStripe}>Loading...</div>
                }
              </div>
            </div>
        </Drawer>
    </div>
    </CartProvider>
  );
}
