import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth, db } from "./firebase";
import { OutlinedInput, IconButton, Dialog } from "@material-ui/core";
import HomeIcon from '@mui/icons-material/Home';
import {FeedbackNPS} from "./FeedbackNPS";
import {throwConfetti} from "./Confetti";
import { ImInfo } from "react-icons/im";
import { MdPhotoCamera } from "react-icons/md";
import { BiBookBookmark } from "react-icons/bi";
import { SudokuData } from "./clues/ClueS/ClueSudokuData";
import { SlidingPuzzle } from "./clues/ClueSlidingPuzzle";
import { CrosswordData } from "./clues/ClueCW/ClueCrosswordData";
import { MathSquareData } from "./clues/ClueMS/ClueMathSquareData";
import { WordLadder } from "./clues/ClueWordLadder";
import { FillInTheWords } from "./clues/ClueFillInTheWords";
import { KakuruData } from "./clues/ClueK/ClueKakuruData";
import { MdOutlineCheckCircle } from "react-icons/md";
import { AiOutlineFileDone } from "react-icons/ai";
import { MdHelpOutline } from "react-icons/md";
import { IconContext } from "react-icons";
import { GiLaurelsTrophy } from "react-icons/gi";
import { BiCheckCircle } from "react-icons/bi";
import { BiCamera } from "react-icons/bi";
import { RiCouponFill } from "react-icons/ri";
import { ImNewTab } from "react-icons/im";

export function TreasureHunt(props) {
  // GENERAL SET UP VARIABLES
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [huntData, setHuntData] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  // INPUT & SUBMISSION VARIABLES
  const [clientAnswer, setClientAnswer] = useState("");
  const [openFinishHunt, setOpenFinishHunt] = useState(false);
  const [feedback, setFeedback] = useState(false);
  // ALL OTHER BUTTON VARIABLES
  const [openInstructions, setOpenInstructions] = useState(false);
  const [openPicture, setOpenPicture] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openHint, setOpenHint] = useState(false);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [openQuestionAnswer, setOpenQuestionAnswer] = useState(false);
  const [openHowItWorks, setOpenHowItWorks] = useState(false);
  const [openAddToScorecard, setOpenAddToScorecard] = useState(false);
  const [openAddToScorecardTwo, setOpenAddToScorecardTwo] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [totalTime, setTotalTime] = useState(0);
  const [hintUsed, setHintUsed] = useState(false);
  const [answerUsed, setAnswerUsed] = useState(false);
  const [index, setIndex] = useState([]);
  const [chessAnswer, setChessAnswer] = useState("");
  const [chessCorrect, setChessCorrect] = useState(false);
  const [chessPart, setChessPart] = useState(0);
  const [mapCoord, setMapCoord] = useState("");
  const [correctAnswerAlert, setCorrectAnswerAlert] = useState(false);

  const {id} = useParams();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(u => {
      if (u) {
        setUser(u.uid)
        setEmail(u.email)

        db.collection("users").doc(u.uid).collection("purchasedHunts").doc(id).get().then(snapshot => {
          const querySnapshot = snapshot.data()

          if (!querySnapshot) {
            props.history.push("/")
          } else {
            setCurrentStep(querySnapshot.step)
            db.collection("hunts").doc(id).collection("clues").doc(String(querySnapshot.step)).get().then(snapshot => {
              const queryData = snapshot.data()
              setHuntData(queryData)
              setHintUsed(false)
              setAnswerUsed(false)
            })
            db.collection("hunts").doc("0_Index").get().then(snapshot => {
              const queryData = snapshot.data()
              setIndex(queryData)
            })
          }
        })

      } else {
        props.history.push("/");
      }
    });

    return unsubscribe;
  }, [props.history, id, currentStep]);

  const testAnswer = () => {
    if (user === "OaMBnOycXQhOCVzAvToQHZHODJ52") {
      // FUNCTIONS IF THE ANSWER IS NOT A NUMBER
      if (isNaN(clientAnswer.toUpperCase().trim()) || clientAnswer.toUpperCase().trim() === "") {
        if (Number(currentStep) === 7) {
          db.collection("users").doc(user).collection("purchasedHunts").doc(id).update({step: Number(currentStep) + 1})
          setOpenFinishHunt(true)
          throwConfetti()

        } else {
          db.collection("users").doc(user).collection("purchasedHunts").doc(id).update({step: Number(currentStep) + 1}).then(() => {
            db.collection("users").doc(user).collection("purchasedHunts").doc(id).get().then(snapshot => {
              const queryData = snapshot.data()
                setCurrentStep(queryData.step)
            });
          }).then(() => {
            setClientAnswer("")
          })
        }
      // FUNCTIONS IF THE ANSWER IS A NUMBER
      } else {
        db.collection("users").doc(user).collection("purchasedHunts").doc(id).update({step: Number(clientAnswer)}).then(() => {
          db.collection("users").doc(user).collection("purchasedHunts").doc(id).get().then(snapshot => {
            const queryData = snapshot.data()
              setCurrentStep(queryData.step)
          });
        }).then(() => {
          setClientAnswer("")
        })
      }
        
    // FUNCTIONS FOR ALL NORMAL ANSWER CHECKS
    } else if (clientAnswer.toUpperCase().trim() === huntData.Answer) {      
      if (Number(currentStep) === 7) { 
        setOpenFinishHunt(true)
        throwConfetti()
        db.collection("users").doc(user).collection("purchasedHunts").doc(id).update({step: Number(currentStep) + 1}).then(
        db.collection("users").doc(user).collection("purchasedHunts").doc(id).get().then(snapshot => {
          const queryData = snapshot.data()
          if (queryData.startTime) {
            const endTime = new Date().getTime()
            const timeElapsedMilliseconds = (endTime - queryData.startTime)
            const timeElapsedMinutes = Math.floor(timeElapsedMilliseconds / 60000)
            setTotalTime(timeElapsedMinutes)
            db.collection("users").doc(user).collection("purchasedHunts").doc(id).update({finishTime: endTime, elapsedTime: timeElapsedMinutes})
            .then(db.collection("scoreboard").doc(id).get().then(snapshot => {
              if (snapshot.exists) {
                const newQueryData = snapshot.data()
                if (newQueryData.time > timeElapsedMinutes) {
                  setOpenAddToScorecard(true)
                }
              } else {
                if (queryData.hintUsed || queryData.answerUsed) {
                } else {
                  setOpenAddToScorecardTwo(true)
                }
              }  
            }))
          }

          if (queryData.hintUsed) {
            setHintUsed(true)
          }
          
          if (queryData.answerUsed) {
            setAnswerUsed(true)
          }
        }))
      } else {
        setCorrectAnswerAlert(true)

        setTimeout(()=> {
          setCorrectAnswerAlert(false)
        }, 2000);

        db.collection("users").doc(user).collection("purchasedHunts").doc(id).update({step: Number(currentStep) + 1}).then(() => {
          db.collection("users").doc(user).collection("purchasedHunts").doc(id).get().then(snapshot => {
            const queryData = snapshot.data()
              setCurrentStep(queryData.step)
          });
        }).then(() => {
          setClientAnswer("")
        })
      }
    } else {
      alert("Wrong! Remember that you can't answer the question until you make it to your next destination.")
    }
  }

  const handleFinishHunt = () => {
      db.collection("users").doc(user).collection("PrizeMoney").doc("coins").get().then(snapshot => {
        db.collection("users").doc(user).collection("PrizeMoney").doc("coins").update({coins: snapshot.data().coins + 1})
      }).then(() => {
        const today = new Date()
        const yyyy = today.getFullYear()
        let mm = today.getMonth() +1
        let dd = today.getDate()
        const formattedToday = mm + '/' + dd + '/' + yyyy
        if (openAddToScorecard === true) {
          db.collection("scoreboard").doc(id).update({time: totalTime, name: teamName, date: formattedToday})
        } else if (openAddToScorecardTwo === true) {
          db.collection("scoreboard").doc(id).set({time: totalTime, name: teamName, date: formattedToday})
        }
      }).then(()=> {handleFeedback()})
  }

  const handleFeedback = () => {
    setOpenFinishHunt(false)
    setFeedback(true)
  }

  const handleHomePage = () => {
    props.history.push("/")
    setFeedback(false)
  }

  const handleCloseAnswer = () => {
    setOpenAnswer(false)
    setOpenQuestionAnswer(true)
  }

  const handleOpenHint = () => {
    setOpenHint(true)
    if (hintUsed === false) {
      db.collection("users").doc(user).collection("purchasedHunts").doc(id).get().then(snapshot => {
        const newQueryData = snapshot.data()
        if (newQueryData.hintUsed) {
          db.collection("users").doc(user).collection("purchasedHunts").doc(id).update({hintUsed: Number(newQueryData.hintUsed) + 1})
          setHintUsed(true)
        } else {
          db.collection("users").doc(user).collection("purchasedHunts").doc(id).update({hintUsed: 1})
          setHintUsed(true)
        }
      })
    }
  }

  const handleOpenAnswer = () => {
    setOpenAnswer(true)
    if (answerUsed === false) {
      db.collection("users").doc(user).collection("purchasedHunts").doc(id).get().then(snapshot => {
        const newQueryData = snapshot.data()
        if (newQueryData.answerUsed) {
          db.collection("users").doc(user).collection("purchasedHunts").doc(id).update({answerUsed: Number(newQueryData.answerUsed) + 1})
          setAnswerUsed(true)
        } else {
          db.collection("users").doc(user).collection("purchasedHunts").doc(id).update({answerUsed: 1})
          setAnswerUsed(true)
        }
      })
    }
  }

  const handleTestChess = () => {
    const noSpaces = chessAnswer.replace(/ /g, '');
    if (huntData.ClueChessAnswer2) {
      if (chessPart === 0) {
        if (noSpaces.toUpperCase().trim() === huntData.ClueChessAnswer) {
          setChessPart(1)
          setChessAnswer("")
        } else {
          alert("Wrong move! Try again.")
        }
      } else {
        if (noSpaces.toUpperCase().trim() === huntData.ClueChessAnswer2) {
          setChessCorrect(true)
          setChessPart(0)
        } else {
          alert("Wrong move! Try again.")
        }
      }
    } else {
      if (noSpaces.toUpperCase().trim() === huntData.ClueChessAnswer) {
        setChessCorrect(true)
      } else {
        alert("Wrong move! Try again.")
      }
    }

  }

    return (
    <div>
      <div className="treasureHuntBackground"></div>
      
      <div style={{display:"flex", justifyContent:"center"}}>
        <div className="treasureHuntCard">
          <div className="treasureHuntMainDiv">

            {/* CLUE CARD HEADER */}
            <div className="treasureHuntTitle"><h2>{id}</h2><IconButton onClick={handleHomePage} style={{padding:"3px"}}><HomeIcon style={{color:"black"}}/></IconButton></div>
            {/* <hr style={{marginBottom:"15px", backgroundColor:"black", height:"1px", border:"none"}}/> */}
            <br/><br/>

            {/* CLUE CARD TEXT */}
            <div className="treasureHuntTextDiv">
              <div className="treasureHuntText">

                {/* CLUE NUMBER & CONDITIONAL INSTRUCTIONS ICON */}
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                  <h3 className="treasureHuntHeaders" style={{marginBottom:"10px", fontWeight:"bolder"}}>CLUE {currentStep} of 7</h3>
                  <div style={{display:"flex"}}>
                    {huntData.Hist ? 
                      <IconButton style={{fontSize:"20px", paddingTop:"1px", marginRight:"-5px"}} onClick={() => {setOpenHistory(true)}}>
                        <svg width="20" height="20">
                          <defs>
                            <linearGradient id="myBookmarkGradient" gradientTransform="rotate(95)">
                              <stop offset="5%"  stopColor="black" />
                              <stop offset="100%" stopColor="green" />
                            </linearGradient>
                          </defs>
                          <IconContext.Provider value={{ attr: {fill: "url('#myBookmarkGradient')"}}}>
                            <BiBookBookmark/>
                          </IconContext.Provider>
                        </svg>
                      </IconButton> 
                    : 
                      <div style={{width:"50px"}}></div> 
                    }

                    {huntData.Pic ? 
                      <IconButton style={{fontSize:"20px", paddingTop:"1px", marginRight:"-5px"}} onClick={() => {setOpenPicture(true)}}>
                        <svg width="20" height="20">
                          <defs>
                            <linearGradient id="myPicGradient" gradientTransform="rotate(95)">
                              <stop offset="5%"  stopColor="black" />
                              <stop offset="100%" stopColor="green" />
                            </linearGradient>
                          </defs>
                          <IconContext.Provider value={{ attr: {fill: "url('#myPicGradient')"}}}>
                            <MdPhotoCamera/>
                          </IconContext.Provider>
                        </svg>
                      </IconButton>
                    : 
                      <div style={{width:"50px"}}></div>
                    }

                    {huntData.I ?
                      <IconButton style={{fontSize:"20px", paddingTop:"1px", marginRight:"-5px"}} onClick={() => {setOpenInstructions(true)}}>
                        <svg width="20" height="20">
                          <defs>
                            <linearGradient id="myInstructionsGradient" gradientTransform="rotate(95)">
                              <stop offset="5%"  stopColor="black" />
                              <stop offset="100%" stopColor="green" />
                            </linearGradient>
                          </defs>
                          <IconContext.Provider value={{ attr: {fill: "url('#myInstructionsGradient')"}}}>
                            <ImInfo/>
                          </IconContext.Provider>
                        </svg>
                      </IconButton>
                    :
                      <div style={{width:"50px"}}></div>
                    }
                  </div>
                </div>

                {/* CLUE CARD CLUE, IMAGE, SUDOKU, ETC. INFORMATION */}
                {huntData.Clue ?
                  <>
                    { (huntData.Clue === "FWC") ? <> { index.FWC ? index.FWC.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Clue === "KC") ? <> { index.KC ? index.KC.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Clue === "KeyC") ? <> { index.KeyC ? index.KeyC.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Clue === "MSC") ? <> { index.MSC ? index.MSC.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Clue === "OC") ? <> { index.OC ? index.OC.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Clue === "RebC") ? <> { index.RebC ? index.RebC.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Clue === "SPC") ? <> { index.SPC ? index.SPC.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Clue === "SudC") ? <> { index.SudC ? index.SudC.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Clue === "WLC") ? <> { index.WLC ? index.WLC.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Clue === "ChessC") ? <> { index.ChessC ? index.ChessC.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : 
                      huntData.Clue.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) 
                    }
                  </>
                  // huntData.Clue.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) 
                : 
                  <div></div>
                }

                {huntData.ClueTwo ? huntData.ClueTwo.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px", marginTop:"0px"}}>{item}</h5></div> )}) : <div></div> }
                {huntData.ClueWL ? <WordLadder myArray={huntData.ClueWL}/> : <div></div> }
                {huntData.ClueFW ? <FillInTheWords ClueFW={huntData.ClueFW}/> : <div></div> }
              </div>

              {/* CLUE CARD OPTIONAL CLUES */}
              {huntData.ClueI ? 
                <div>
                  {chessPart === 0 ?
                  <img className="treasureHuntClueImage" src={huntData.ClueI} alt="" /> 
                  :
                  <div></div>
                  }
                </div> 
              : 
                <div></div>
              }
              {huntData.ClueI2 ? 
                <div>
                  {chessPart === 1 ?
                  <img className="treasureHuntClueImage" src={huntData.ClueI2} alt="" /> 
                  :
                  <div></div>
                  }
                </div>
              : 
              <div></div> 
              }
              {huntData.ClueS ? <SudokuData ClueS={huntData.ClueS}/> : <div></div> }
              {huntData.ClueSP ? <SlidingPuzzle ClueSP={huntData.ClueSP}/> : <div></div> }
              {huntData.ClueCW ? <CrosswordData ClueCW={huntData.ClueCW} huntID={huntData.ClueCWid}/> : <div></div> }
              {huntData.ClueMS ? <MathSquareData ClueMS={huntData.ClueMS} ClueMSid={huntData.ClueMSid}/> : <div></div> }
              {huntData.ClueK ? <KakuruData ClueK={huntData.ClueK} ClueKid={huntData.ClueKid}/> : <div></div> }
              {huntData.ClueChess ? 
                <div style={{width:"80vw", maxWidth:"400px", minWidth:"280px"}}>
                  {chessCorrect === false ? 
                    <div> <OutlinedInput placeholder={""} fullWidth={true} value={chessAnswer} className="treasureHuntInputBackground" onChange={e => {setChessAnswer(e.target.value)}}/>
                      <div className="chessCheckButton" style={{textAlign:"center"}} onClick={handleTestChess}>Test Answer</div>
                    </div>
                    : 
                    <div>
                      <br/><h5>LOCATION INFO</h5>
                      <h5>{huntData.ClueChess.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px", marginTop:"5px"}}>{item}</h5></div> )})}</h5>
                    </div>
                  } 
                </div>
                :
                <div></div>
              }
              {huntData.Map ? <div style={{width:"80vw", maxWidth:"400px", minWidth:"280px", marginTop:"20px"}}> <h5>Your coordinates are: {huntData.Map} <br/>Once you've solved for the missing numbers, enter the completed coordinates in the field below, then click the button</h5> <OutlinedInput placeholder={""} fullWidth={true} value={mapCoord} className="treasureHuntInputBackground" onChange={e => {setMapCoord(e.target.value)}}/> <a className="chessCheckButton" href={'https://www.google.com/maps/place/' + mapCoord} target="_blank" rel="noreferrer" style={{textAlign:"center"}} >Test Coordinates</a></div> :<div></div> }
              {huntData.ClueThree ? huntData.ClueThree.split("\\n").map(function(item, index) { return ( <div key={index} style={{marginTop:"1em"}}><h5 style={{marginBottom:"3px", marginTop:"0px"}}>{item}</h5></div> )}) : <div></div> }
                

              
              {/* CLUE CARD DESTINATION QUESTION */}
              {/* <hr style={{marginTop:"15px", marginBottom:"15px", backgroundColor:"greenyellow", height:"1px", border:"none"}}/> */}
              <br/><br/>
              <div className="treasureHuntText">
                <h3 className="treasureHuntHeaders" style={{marginBottom:"10px", fontWeight:"bolder"}}>QUESTION</h3>
                <h5>{huntData.Question}</h5>
              </div>
            </div>

            {/* CLUE CARD INPUT AND BUTTONS */}
            <OutlinedInput placeholder={""} fullWidth={true} value={clientAnswer} className="treasureHuntInputBackground" onChange={e => {setClientAnswer(e.target.value)}}/>
            {/* <hr style={{marginBottom:"0px", backgroundColor:"greenyellow", height:"1px", border:"none"}}/> */}
            <br/>
            <div style={{display:"flex"}}>
              <div className="treasureHuntWhiteButtons" style={{textAlign:"center"}} onClick={handleOpenAnswer}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <svg width="40" height="40">
                    <defs>
                      <linearGradient id="myAnswerGradient" gradientTransform="rotate(95)">
                        <stop offset="5%"  stopColor="red" />
                        <stop offset="100%" stopColor="red" />
                      </linearGradient>
                    </defs>
                    <IconContext.Provider value={{ attr: {fill: "url('#myAnswerGradient')"}}}>
                      <AiOutlineFileDone style={{fontSize:"40px", marginBottom:"5px"}}/>
                    </IconContext.Provider>
                  </svg>
                  <div>GIVE UP</div>
                  <h6>Get answer</h6>
                </div>
              </div>

              <div className="treasureHuntWhiteButtons" style={{textAlign:"center"}} onClick={handleOpenHint}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <svg width="40" height="40">
                    <defs>
                      <linearGradient id="myHintGradient" gradientTransform="rotate(95)">
                        <stop offset="5%"  stopColor="greenyellow" />
                        <stop offset="100%" stopColor="red" />
                      </linearGradient>
                    </defs>
                    <IconContext.Provider value={{ attr: {fill: "url('#myHintGradient')"}}}>
                      <MdHelpOutline style={{fontSize:"40px", marginBottom:"5px"}}/>
                    </IconContext.Provider>
                  </svg>
                  <div>HINT</div>
                </div>
              </div>

              <div className="treasureHuntWhiteButtons" onClick={testAnswer}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <svg width="40" height="40">
                    <defs>
                      <linearGradient id="mySubmitGradient" gradientTransform="rotate(95)">
                        <stop offset="5%"  stopColor="greenyellow" />
                        <stop offset="100%" stopColor="green" />
                      </linearGradient>
                    </defs>
                    <IconContext.Provider value={{ attr: {fill: "url('#mySubmitGradient')"}}}>
                      <MdOutlineCheckCircle style={{fontSize:"40px", marginBottom:"5px"}}/>
                    </IconContext.Provider>
                  </svg>
                  <div>SUBMIT</div>
                  {correctAnswerAlert === true ?
                    <div className='alert-container'>
                      <div className='alert-inner'>
                          Correct!<br/>Loading next clue...
                      </div>
                    </div>
                    :
                    <div></div>
                  }
                </div>
              </div>
            </div>  
            <div style={{marginTop:"15px", marginBottom:"10px", color:"blue", fontSize:"14px", cursor:"pointer"}} onClick={()=>{setOpenHowItWorks(true)}}>How do these clues work?</div>       
            <h6 style={{marginBottom:"-5px", color:"black", fontWeight:"normal"}}>&copy; {new Date().getFullYear()} - Hidden Treasure Hunts</h6>

          </div>
        </div>
        </div>

        {/* DIALOG BOXES */}
        <Dialog open={openHowItWorks} onClose={() => {setOpenHowItWorks(false)}}><div className="treasureHuntButtonDiv"><h4 style={{fontWeight:"bolder"}}>HOW IT WORKS</h4><hr/><h5 style={{margin:"10px"}}><div style={{fontFamily:"NewTextFontBold", fontSize:"16px"}}>Clue</div>Each clue is some type of puzzle that you need to solve. When you have solved it correctly, it will lead you to your next destination - remember that - you are solving for a DESTINATION. Also keep in mind that sometimes the destination might come in different forms - words, coordinates, numbers, etc.</h5><h5 style={{margin:"10px"}}><div style={{fontFamily:"NewTextFontBold", fontSize:"16px"}}>Instructions</div>If there are instructions for that clue, a little icon will appear right above the clue (below the home icon).</h5><h5 style={{margin:"10px"}}><div style={{fontFamily:"NewTextFontBold", fontSize:"16px"}}>Question</div>You CANNOT answer this question until you have solved the clue and have gone to the new destination. The question verifies that you made it to the next correct destination.</h5><h5 style={{margin:"10px"}}><div style={{fontFamily:"NewTextFontBold", fontSize:"16px"}}>Photo Op</div>Totally optional - some clues will have a little camera icon below the home icon; if you click on it, it will give you a silly photo prompt if you want some Insta inspo.</h5></div></Dialog>
        <Dialog open={openInstructions} onClose={() => {setOpenInstructions(false)}}>
          <div className="treasureHuntButtonDiv">
            <h4 style={{fontWeight:"bolder"}}>INSTRUCTIONS</h4><hr/>
            <h4 style={{textAlign:"center"}}>
              {huntData.I ?
                  <>
                    { (huntData.I === "ExI") ? <> { index.ExI ? index.ExI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "KI") ? <> { index.KI ? index.KI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "KeyI") ? <> { index.KeyI ? index.KeyI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "MSI") ? <> { index.MSI ? index.MSI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "OI") ? <> { index.OI ? index.OI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "RebI") ? <> { index.RebI ? index.RebI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "SPI") ? <> { index.SPI ? index.SPI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "SudI") ? <> { index.SudI ? index.SudI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "WLI") ? <> { index.WLI ? index.WLI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "CiphI") ? <> { index.CiphI ? index.CiphI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "ChessI") ? <> { index.ChessI ? index.ChessI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.I === "ChessMedI") ? <> { index.ChessMedI ? index.ChessMedI.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : 
                      huntData.I.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) 
                    }
                  </>
                : 
                  <div></div>
                }
            </h4>
          </div>
        </Dialog>

        <Dialog open={openPicture} onClose={() => {setOpenPicture(false)}}><div className="treasureHuntButtonDiv"><h4 style={{fontWeight:"bolder"}}>PHOTO OP!</h4><hr/><h4 style={{textAlign:"center"}}>{huntData.Pic}</h4></div></Dialog>
        <Dialog open={openHistory} onClose={() => {setOpenHistory(false)}}><div className="treasureHuntButtonDiv"><h4 style={{fontWeight:"bolder"}}>DID YOU KNOW...</h4><hr/><h4 style={{textAlign:"center"}}>{huntData.Hist}</h4></div></Dialog>
        <Dialog open={openHint} onClose={() => {setOpenHint(false)}}>
          <div className="treasureHuntButtonDiv">
            <h4 style={{fontWeight:"bolder"}}>HINT</h4><hr/>
            <h4 style={{textAlign:"center"}}>
                {huntData.Hint ?
                  <>
                    { (huntData.Hint === "ExH") ? <> { index.ExH ? index.ExH.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Hint === "KH") ? <> { index.KH ? index.KH.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Hint === "KeyH") ? <> { index.KeyH ? index.KeyH.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Hint === "MSH") ? <> { index.MSH ? index.MSH.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Hint === "MorseH") ? <> { index.MorseH ? index.MorseH.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Hint === "MorseWordsH") ? <> { index.MorseWordsH ? index.MorseWordsH.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Hint === "OH") ? <> { index.OH ? index.OH.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Hint === "RebH") ? <> { index.RebH ? index.RebH.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Hint === "SudH") ? <> { index.SudH ? index.SudH.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : (huntData.Hint === "WLH") ? <> { index.WLH ? index.WLH.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div>} </>
                    : 
                      huntData.Hint.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )})
                    }
                  </>
                : 
                  <div></div>
                }
              {/* {huntData.Hint ? huntData.Hint.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div> } */}
            </h4>
          </div>
        </Dialog>

        <Dialog open={openAnswer} onClose={() => {setOpenAnswer(false)}}>
          <div className="treasureHuntButtonDiv">
            <h4 style={{fontWeight:"bolder"}}>NEXT DESTINATION</h4><hr/> 
            {huntData.Coord ? <div style={{display:"flex", alignItems:"center"}}><div style={{display:"inline", textAlign:"center"}}><a style={{textDecoration:"none"}} href={'https://www.google.com/maps/place/' + huntData.Coord} target="_blank" rel="noreferrer">{huntData.Destination}</a> <ImNewTab style={{color:"blue", fontSize:"12px", marginLeft:"4px"}}/></div><br/><br/></div> : <h4 style={{textAlign:"center"}}>{huntData.Destination}</h4> }
            <div className="blackButtons" onClick={handleCloseAnswer}><h5>QUESTION'S ANSWER</h5></div></div></Dialog>
        <Dialog open={openQuestionAnswer} onClose={() => {setOpenQuestionAnswer(false)}}><div className="treasureHuntButtonDiv"><h4 style={{fontWeight:"bolder"}}>QUESTION'S ANSWER</h4><hr/><h4 style={{textAlign:"center"}}>{huntData.Answer}</h4></div></Dialog>
        
        <Dialog open={openFinishHunt} onClose={() => {setOpenFinishHunt(false)}}>
          <div className="treasureHuntButtonDiv">
            <div style={{display:"flex", alignItems:"center", width:"95%", marginBottom:"10px"}}>
              <svg width="50px" height="50px" style={{marginTop:"10px"}}>
                <defs>
                  <linearGradient id="myCompleteIcon" gradientTransform="rotate(35)">
                    <stop offset="5%"  stopColor="green" />
                    <stop offset="100%" stopColor="greenyellow" />
                  </linearGradient>
                </defs>
                <IconContext.Provider value={{ attr: {fill: "url('#myCompleteIcon')"}}}>
                  <BiCheckCircle style={{fontSize:"50px"}}/>
                </IconContext.Provider>
              </svg>
              <h4 style={{fontFamily:"NewTextFontBold", fontSize:"16px", height:"50px", marginTop:"10px", marginLeft:"20px", display:"flex", alignItems:"center"}}>CONGRATS - YOU DID IT!</h4>
            </div>

            <div style={{display:"flex", alignItems:"center", width:"95%", marginBottom:"10px"}}>
              <svg width="50px" height="50px" style={{marginTop:"10px"}}>
                <defs>
                  <linearGradient id="myCameraFinishIcon" gradientTransform="rotate(35)">
                    <stop offset="5%"  stopColor="greenyellow" />
                    <stop offset="100%" stopColor="red" />
                  </linearGradient>
                </defs>
                <IconContext.Provider value={{ attr: {fill: "url('#myCameraFinishIcon')"}}}>
                  <BiCamera style={{fontSize:"50px"}}/>
                </IconContext.Provider>
              </svg>
              <h4 style={{fontSize:"14px", marginTop:"10px", marginLeft:"20px", display:"flex", alignItems:"center", flexWrap:"wrap", width:"70%"}}>Take a pic & tag @hiddenhunts in your social media posts - we'll give you 50% off your next hunt</h4>
            </div>

            <div style={{display:"flex", alignItems:"center", width:"95%", marginBottom:"10px"}}>
              <svg width="50px" height="50px" style={{marginTop:"10px"}}>
                <defs>
                  <linearGradient id="myFinishPrizeIcon" gradientTransform="rotate(35)">
                    <stop offset="5%"  stopColor="red" />
                    <stop offset="80%" stopColor="goldenrod" />
                  </linearGradient>
                </defs>
                <IconContext.Provider value={{ attr: {fill: "url('#myFinishPrizeIcon')"}}}>
                  <RiCouponFill style={{fontSize:"50px"}}/>
                </IconContext.Provider>
              </svg>
              <h4 style={{fontSize:"14px", marginTop:"10px", marginLeft:"20px", display:"flex", alignItems:"center", flexWrap:"wrap", width:"70%"}}>Redeem a prize on the prizes page when you're ready</h4>
            </div>

          { ((openAddToScorecard === true || openAddToScorecardTwo === true) && (hintUsed === false) && (answerUsed === false)) ?
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"95%"}}>
              <div style={{display:"flex", alignItems:"center", marginBottom:"10px"}}>
                <svg width="50px" height="50px" style={{marginTop:"10px"}}>
                  <defs>
                    <linearGradient id="myFinishScoreboardIcon" gradientTransform="rotate(35)">
                      <stop offset="5%"  stopColor="gold" />
                      <stop offset="100%" stopColor="goldenrod" />
                    </linearGradient>
                  </defs>
                  <IconContext.Provider value={{ attr: {fill: "url('#myFinishScoreboardIcon')"}}}>
                    <GiLaurelsTrophy style={{fontSize:"50px"}}/>
                  </IconContext.Provider>
                </svg>
                <div style={{marginTop:"10px", marginLeft:"20px", width:"70%", display:"inline-block"}}><h4 style={{fontFamily:"NewTextFontBold", fontSize:"16px", display:"inline"}}>HIGH SCORE!</h4><h4 style={{fontSize:"14px", fontFamily:"NewTextFont", display:"inline"}}> - Let's get you added to our scoreboard</h4></div>
              </div>
              <OutlinedInput placeholder={"Scoreboard Team Name"} inputProps={{maxLength: 15}} style={{width:"90%", marginTop:"10px"}} value={teamName} className="universalInputBackground" onChange={e => {setTeamName(e.target.value)}}/>
            </div>
          :
            <div></div>
          }
            <br/>
            <div className="blackButtons" onClick={handleFinishHunt} style={{marginTop:"10px", marginBottom:"5px"}}>FINISH</div>
          </div>
        </Dialog>
        {/* <Dialog open={feedback} onClose={handleHomePage}><Feedback/><div className="treasureHuntButtonDiv"><div className="blackButtons" onClick={handleHomePage}>DONE</div></div></Dialog> */}
        <Dialog style={{width:"100%"}} open={feedback} onClose={handleHomePage}><FeedbackNPS huntTitle={id} user={user} email={email} handleHomePage={handleHomePage}/></Dialog>
      </div>
    );

  }
