// AUTHENTICATION GENERAL IMPORTS
import React, {useEffect, useState} from "react";
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import {auth, firebase} from "./firebase";
import {OutlinedInput, Dialog} from "@material-ui/core";
import { HeaderBar } from "./HeaderBar";

export function AuthSignInPage(props) {
  // AUTHENTICATION SIGN IN VARIABLES
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(u => {
      if (u) {
        props.history.push("/huntPage");
      }
      // do something
    });
    return unsubscribe;
  }, [props.history]);

  const handleSignIn = () => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {})
      .catch(error => {
        // alert(error.message);
        auth
          .createUserWithEmailAndPassword(email, password)
          .then(() => {})
          .catch(error => {
            alert(error.message);
          });
      });
  };

  const handleSignInWithGoogle = () => {
    auth.onAuthStateChanged(u => {
      if (u) {
        props.history.push("/huntPage");
      } else {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider)
        .catch((err) => {
          console.log(err)
        })
      }
    })
  }

  const handleOpenResetPasswordDialog = () => {
    setOpenResetPasswordDialog(true)
  }

  const handleResetPassword = () => {
    auth.sendPasswordResetEmail(resetPasswordEmail)
    setOpenResetPasswordDialog(false)
    setResetPasswordEmail("")
  }

  return (
    <HelmetProvider>
    <div className="pageContainer">
        <div className="authBackground">

                <Helmet link = {[{ rel : 'canonical', href : "https://www.hiddenhunts.com/signIn"  }]}>
                    <title>Hidden Hunts - Sign In</title>
                    <meta name='description' content="Create a free account to explore our progressive puzzle adventures, win prizes, and take on a sample hunt!"/>
                    <meta name="keywords" content="create an account, sign in, sample treasure hunt, free account"/>
                </Helmet>  

            <HeaderBar PageID="SignIn"/>

            {/* AUTHENTICATION PAGE MAIN CONTENT */}
            <div className="appMainContentDiv">
                <form id="loginForm">
                    <div>
                        <div className="authCard">
                            {/* <h3 style={{fontSize:"30px"}}>Start Hunting</h3><br/> */}
                            <div className="SignInMainDiv">
                                <div className="GoogleSignInMainDiv" onClick={handleSignInWithGoogle}><img className="GoogleSignInPicDiv" src="https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntCardImages%2FGoogleIcon.webp?alt=media&token=d039686b-238f-4df4-b3f4-d0c8f2b7217b" alt="Google"/><h5 className="GoogleSignInText">CONTINUE WITH GOOGLE</h5></div>
                                <div className="SignInHrDiv">
                                    <hr className="SignInHr"/>
                                    <h6 className="SignInHrText">OR</h6>
                                    <hr className="SignInHr"/>
                                </div>
                                <OutlinedInput 
                                    placeholder={"Email"}
                                    fullWidth={true}
                                    value={email}
                                    className="universalInputBackground"
                                    onChange={e => {setEmail(e.target.value)}}
                                />
                                <OutlinedInput
                                    type={"password"}
                                    placeholder="Password"
                                    fullWidth={true}
                                    style={{marginTop: "10px"}}
                                    className="universalInputBackground"
                                    value={password}
                                    onChange={e => {setPassword(e.target.value)}}
                                />
                                <div className="OtherSignInMainDiv" onClick={handleSignIn} id="logIn"><h5 className="OtherSignInText">MANUAL SIGN IN/UP</h5></div>
                                <h6 className="SignInResetPassword" onClick={()=>{handleOpenResetPasswordDialog()}}>Reset Password</h6>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </div>
        
{/* AUTHENTICATION PAGE DIALOG BOXES */}      
        <Dialog open={openResetPasswordDialog} onClose={() => {setOpenResetPasswordDialog(false)}}>
            <div>
              <div className="authCard">
                <h1>Reset Password</h1><br/>
                  <div className="SignInMainDiv">
                    <OutlinedInput 
                        placeholder={"Email"}
                        fullWidth={true}
                        value={resetPasswordEmail}
                        className="universalInputBackground"
                        onChange={e => {setResetPasswordEmail(e.target.value)}}
                    />
                    <div className="blackButtons" onClick={handleResetPassword} id="logIn">SEND EMAIL</div>
                  </div>
              </div>
            </div>
        </Dialog>

    </div>
    </HelmetProvider>
  );
}
