// AUTHENTICATION GENERAL IMPORTS
import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import { HeaderBar } from "./HeaderBar";
import HuntStoreUT from "./HuntStoreUT";
import {FooterTwo} from "./Footer";

export function AuthPageUTlanding(props) {

  const mainPic = require('./pictures/AuthPic4.jpg')

  return (
    <HelmetProvider>
    <div className="pageContainer">
        <div className="appBackground">
            <div className="authHeader">

              <Helmet link = {[{ rel : 'canonical', href : "https://www.hiddenhunts.com/UtahAdventures"  }]}>
                <title>Utah Scavenger Hunts | Utah Team Building | Provo Treasure Hunts</title>
                <meta name='description' content="Discover Provo Treasure Hunts, Date Ideas, Scavenger Hunts, Team Building, and Puzzle Adventures in scenic Utah."/>
                <meta name="keywords" content="team building, date idea, adventure, game, puzzle, family home evening idea, scavenger hunt"/>
              </Helmet>

              <HeaderBar/>
                
            </div>

            {/* AUTHENTICATION PAGE MAIN CONTENT */}
            <div className="appMainContentDiv" style={{marginTop:"-20px"}}>
              <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <div className="mainPicContainer">
                    <img src={mainPic} alt="" className="mainPicImg" />
                  <h4 className="mainPicText" style={{fontSize:"25px"}}>Welcome to HiddenHunts<br/>Unleash your Inner Explorer!</h4>  
                </div>
                <br/>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <div style={{width:"90%", maxWidth:"800px", minWidth:"280px", padding:"15px"}}>
                    <h3 style={{marginBottom:"10px"}}>Embark on a Journey Like Never Before</h3>
                    <h4>Are you ready to break free from the ordinary and dive into a world of mystery, excitement, and teamwork? HiddenHunts invites you to experience the thrill of our Utah puzzle adventures – where every step you take brings you closer to unlocking the secret locations of each clue in an unforgettable activity.</h4>
                    <br/><br/>
                    <h3 style={{marginBottom:"10px"}}>Why HiddenHunts?</h3>
                    <h4>🧩 <b>Engaging Challenges: </b>Immerse yourself in a series of mind-bending puzzles that will test your intellect and teamwork. Our Midvale, South Jordan, Herriman, Lehi, Orem, and Provo treasure hunts are crafted to provide a perfect blend of excitement and mental stimulation. No two hunts are exactly the same!</h4>
                    <br/>
                    <h4>🗝️ <b>Treasure Hunt Extravaganza:</b> Discover random locations as you progress through the challenges. Each puzzle you solve reveals a piece of the overall puzzle journey, guiding you to the finish line. It's not just a game; it's a quest for glory!</h4>
                    <br/>
                    <h4>👫 <b>Perfect for Groups: </b>Whether you're looking for a Utah team-building activity, an Orem birthday celebration, or a unique Provo date idea, HiddenHunts is designed for groups of all sizes. Strengthen bonds and create lasting memories as you work together to conquer the puzzles.</h4>
                    <br/>
                    <h4>🏆 <b>Customized Adventures:</b> Tailor your adventure to suit your group's preferences. Choose from a variety of difficulty levels, ensuring an experience that is both challenging and enjoyable for everyone involved.</h4>
                    <br/>
                    <h4>🌍 <b>Explore Unique Locations: </b>Our experiences are like Utah scavenger hunts mixed with outdoor escape rooms; they take you to fascinating and sometimes random locations with totally unique puzzles. From historic sites to intriguing artwork, each setting is a new canvas for your adventure.</h4>
                    <br/><br/>
                    <h3 style={{marginBottom:"10px"}}>How it works:</h3>
                    <h4 style={{lineHeight:"26px", textIndent:"10px"}}>
                        <li>Purchase a hunt from the list below</li>
                        <li>Gather your team</li>
                        <li>Begin the hunt (under purchases icon)</li>
                        <li>Solve the puzzles to complete the hunt</li>
                        <li>Win a coupon to a local biz!</li>
                    </h4>
                    <br/><br/>
                </div>

                <HuntStoreUT searchFilter=""/>

                <div style={{width:"90%", maxWidth:"800px", minWidth:"280px", padding:"15px", marginTop:"-80px"}}>

                <h3 style={{marginBottom:"10px"}}>X Marks the Fun: The Dual Nature of Treasure Hunt Adventures</h3>
                <h4>
                  In a world saturated with digital entertainment, there's something uniquely thrilling about the prospect of a treasure hunt adventure. Whether pursued for casual fun or embraced as a competitive collaborative experience, these quests offer a dynamic blend of excitement and teamwork.
                  <br/><br/>
                  <b>Casual Fun: Joy in the Journey</b>
                  <br/>
                  Hidden's treasure hunts, in their most casual form, are a delightful escape from the routine. They beckon participants to become modern-day explorers, unraveling clues and following the trail in pursuit of hidden locations. The inherent sense of mystery and discovery transforms an ordinary day into an extraordinary adventure.
                  <br/>
                  <li style={{paddingLeft:"10px", marginTop:"5px"}}><b>Bonding Moments:</b> Casual treasure hunts provide an excellent opportunity for friends, families, or team bonding. The shared laughter and the thrill of the chase create lasting memories, fostering a sense of camaraderie among participants.</li>
                  <li style={{paddingLeft:"10px", marginTop:"5px"}}><b>Exploration:</b> These adventures can turn familiar spaces into uncharted territories. How often have you wandered around Provo or Lehi and missed out on so much of the art, history, and innovations? Participants in our hunts gain a newfound appreciation for their surroundings as they navigate through parks, neighborhoods, or even within the confines of some buildings.</li>
                  <li style={{paddingLeft:"10px", marginTop:"5px"}}><b>Problem-Solving Satisfaction:</b> The puzzles and clues, though challenging, are designed to entertain rather than perplex. They stimulate the brain in a playful manner, promoting problem-solving skills without the pressure of failure since every clue contains hints and the answer - should participants get stuck.</li>
                  <br/>
                  <b>Competitive Collaboration: The Quest for Victory</b>
                  <br/>
                  For those seeking a more competitive edge, these Utah progressive puzzle adventures can be faced as high-stakes collaborative experiences. Whether in corporate team-building events or community challenges, the competitive spirit adds a layer of excitement to the quest.
                  <br/>
                  <li style={{paddingLeft:"10px", marginTop:"5px"}}><b>Team Dynamics:</b> Competitive treasure hunts encourage teammates to work together, think quickly, and capitalize on the knowledge of each person. The teams who collaborate most efficiently to decipher the clues and interpret the instructions will stay one step ahead of their rivals and win the challenge!</li>
                  <li style={{paddingLeft:"10px", marginTop:"5px"}}><b>Time-Pressured Thrills:</b> Adding the competitive element of a ticking clock injects an adrenaline rush into the adventure. Not only do we have a leaderboard on the site which tracks the best times across hunts, but if you race your friends at the same time you complete the hunt, you'll be able to earn bragging rights as well ;)</li>
                  <li style={{paddingLeft:"10px", marginTop:"5px"}}><b>How it works:</b> Groups with more than one team can find a hunt that supports more than one team and purchase multiple team versions - these are built to be very similar, but with clues in different orders so no one can follow each other around!</li>
                  <br/>
                  What makes these Utah County adventures truly special is their adaptability. They can seamlessly shift between casual and competitive modes, catering to diverse preferences and objectives. Whether you're out for a leisurely stroll with friends or vying for victory in a corporate challenge, the versatility of treasure hunts ensures there's an adventure for everyone.
                 <br/><br/>

                </h4>
                <br/><br/><br/><br/><br/><br/><br/>
                </div>

              </div>
              </div>
            </div>

            {/* AUTHENTICATION PAGE FOOTERS */}
            <div style={{paddingBottom:"100px"}}><FooterTwo /></div>
        </div>
    
    </div>
    </HelmetProvider>
  );
}
