import React from "react"

export function FillInTheWords(props) {

    const finalArray = props.ClueFW.split(";")

    return (
        <div>
          <br/>
          <table style={{width:"95%"}}>
            <tbody>
                {finalArray.map((rowValue) => {
                    return (
                    <tr key={rowValue} style={{display:"flex", flexDirection:"column"}}>
                        <td className="fillWordClue">{rowValue}</td>
                        <td><input className="fillWordInput"></input></td>
                    </tr>
                )})}
            </tbody>
          </table>
          
        </div>
    )
}