import React from "react"

export function WordLadder(props) {

    const finalArray = props.myArray.split(";")

    return (
        <div>
          <br/>
          <table>
            <tbody>
                {finalArray.map((rowValue) => {
                    return (
                    <tr key={rowValue} >
                        <td className="wordLadderClue">{rowValue}</td>
                        <td><input className="wordLadderInput" maxLength="4"></input></td>
                    </tr>
                )})}
            </tbody>
          </table>
        </div>
    )
}