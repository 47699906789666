// AUTHENTICATION GENERAL IMPORTS
import React, {useEffect, useState} from "react";
import {auth, db, snapshotToArray} from "./firebase";
import { HeaderBar } from "./HeaderBar";

// AUTHENTICATION MAIN CONTENT IMPORTS
import {FooterTwo} from "./Footer";
import { GiftedHuntCard } from "./HuntCard";
import { HistoricHunts } from "./PurchasedHunts";

export function AuthPagePurchases(props) {
  const [user, setUser] = useState(null);
  const [previousHunts, setPreviousHunts] = useState([]);
  const [giftedHunt, setGiftedHunt] = useState()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(u => {
      if (u) {
        setUser(u);

        db.collection("users").doc(u.uid).collection("purchasedHunts").orderBy("date", "desc").get()
            .then(snapshot => {
                const data = snapshotToArray(snapshot);
                const correctedData = data.map(rowItem => {
                rowItem.date = rowItem.date.seconds * 1000;
                return rowItem
                })
                setPreviousHunts(correctedData);
                })
            db.collection("sharedHunts").doc(u.email.toUpperCase()).get().then(snapshot => {
            const giftedData = snapshot.data()
            setGiftedHunt(giftedData)
        })
      }
    });
    return unsubscribe;
  }, [props.history]);

    // Functions for Gifting Hunt on Purchases Page
    function updateHuntListFromGivingGift () {
        db.collection("users").doc(user.uid).collection("purchasedHunts").orderBy("date", "desc").get()
          .then(snapshot => {
            const data = snapshotToArray(snapshot);
            const correctedData = data.map(rowItem => {
              rowItem.date = rowItem.date.seconds * 1000;
              return rowItem
            })
            setPreviousHunts(correctedData);
              })
      }
    
      function updateHuntListFromReceivingGift () {
        setGiftedHunt()
        db.collection("users").doc(user.uid).collection("purchasedHunts").orderBy("date", "desc").get()
          .then(snapshot => {
            const data = snapshotToArray(snapshot);
            const correctedData = data.map(rowItem => {
              rowItem.date = rowItem.date.seconds * 1000;
              return rowItem
            })
            setPreviousHunts(correctedData);
              })
      }


  return (
    <div className="pageContainer">
        <div className="appBackground">

            {/* RENDERED PAGE INFO */}
            <HeaderBar PageID="Purchases"/>
                
            <div className="appMainContentDiv">
                <div>
                    {(user!==null) ?
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginBottom:"200px"}}>
                            { giftedHunt === undefined ? <div></div> : <GiftedHuntCard gift={giftedHunt} user={user.uid} email={user.email} giftFunction={updateHuntListFromReceivingGift}/> } 
                            <HistoricHunts previousHunts = {previousHunts} user = {user.uid} giftFunction={updateHuntListFromGivingGift}/>
                        </div>
                    : 
                        <div></div>
                    }
                </div>
            </div>

            <FooterTwo />
        </div>
    
    </div>
  );
}
