import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyClCNmPaOKyoLrraNYZzmENGCZWBMNVtxM",
  authDomain: "pursuit-99890.firebaseapp.com",
  projectId: "pursuit-99890",
  storageBucket: "pursuit-99890.appspot.com",
  messagingSenderId: "533845920450",
  appId: "1:533845920450:web:f2bc48901194349f6dc95b",
  measurementId: "G-JF5DF58BV2"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const db = firebase.firestore();

export const analytics = firebase.analytics();

export function snapshotToArray(snapshot) {
  const updatedArray = [];
  snapshot.forEach(s => {
    const data = s.data();
    data.id = s.id;
    updatedArray.push(data);
  });
  return updatedArray;
}

export {firebase}