import React, { useState } from "react";
import Tile from "./ClueSlidingPuzzleTile";
import { TILE_COUNT, GRID_SIZE, BOARD_SIZE } from "./ClueSlidingPuzzleConstants"
import { canSwap, shuffle, swap } from "./ClueSlidingPuzzleHelpers";

export function Board(props) {
    const [tiles, setTiles] = useState([...Array(TILE_COUNT).keys()]);
    const [startGame, setStartGame] = useState("block");
    const [gameDisplay, setGameDisplay] = useState("none")

    const shuffleTiles = () => {
        const shuffledTiles = shuffle(tiles)
        setTiles(shuffledTiles);
    }

    const swapTiles = (tileIndex) => {
        if(canSwap(tileIndex, tiles.indexOf(tiles.length - 1))) {
            const swappedTiles = swap(tiles, tileIndex, tiles.indexOf(tiles.length - 1))
            setTiles(swappedTiles)
        }
    }

    const handleTileClick = (index) => {
        swapTiles(index)
    }

    const handleShuffleClick = () => {
        shuffleTiles()
        setStartGame("none")
        setGameDisplay("block")
    }

    const pieceWidth = Math.round(BOARD_SIZE / GRID_SIZE);
    const pieceHeight = Math.round(BOARD_SIZE / GRID_SIZE);
    const style = {
        width: BOARD_SIZE,
        height: BOARD_SIZE,
    }

    return (
        <div>
            <br/>
            <div style={{display: gameDisplay}}>
                <ul style={style} className="slidingPuzzleBoard">
                    {tiles.map((tile, index) => (
                        <Tile 
                            key = {tile}
                            index = {index}
                            tile = {tile}
                            width = {pieceWidth}
                            height = {pieceHeight}
                            handleTileClick = {handleTileClick}
                            imgUrl = {props.imgUrl}
                        />
                    ))}
                </ul>
            </div>
            <div style={{display: startGame}}>
                <div className="loadPuzzleButtons" onClick={() => {handleShuffleClick()}}>
                    Begin Puzzle
                </div>
                <br/>
            </div>
        </div>
    )

}