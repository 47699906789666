import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import { IoIosArrowForward } from "react-icons/io";
import { MdFastfood } from "react-icons/md";
import { MdLocalActivity } from "react-icons/md";
import { IconContext } from "react-icons";
import Drawer from '@mui/material/Drawer';
import { ImNewTab } from "react-icons/im";

export function PrizesCard(props) {
    const [openRedeem, setOpenRedeem] = useState(false);
    const [success, setSuccess] = useState();
    const [openNeedUser, setOpenNeedUser] = useState(false)

    const handleRedeem = () => {
      if (props.user) {
        if (props.coins < 1) {
          setOpenRedeem(false)
          alert("You have no coins remaining. Complete a treasure hunt to earn more!")
        } else {
          setSuccess(true)
           props.func()
        }
      } else {
        setOpenRedeem(false)
        setOpenNeedUser(true)
      }
    }

    const resetPrize = () => {
      setOpenRedeem(false)
      setSuccess(false)
    }

    return(
    <div>
      <div style={{flexDirection:"column", display:"flex"}}>
            <div style={{display:"flex", cursor:"pointer"}} onClick ={() => {setOpenRedeem(true)}}>
                <div style={{marginLeft:"20px", width:"50px", height:"70px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                  { props.prize.Item === "Food" ?
                    <div style={{fontSize:"30px", marginTop:"10px"}}>
                      <svg width="30" height="30">
                        <defs>
                          <linearGradient id="myFoodGradient" gradientTransform="rotate(95)">
                            <stop offset="5%"  stopColor="greenyellow" />
                            <stop offset="100%" stopColor="green" />
                          </linearGradient>
                        </defs>
                        <IconContext.Provider value={{ attr: {fill: "url('#myFoodGradient')"}}}>
                          <MdFastfood/>
                        </IconContext.Provider>
                      </svg>
                    </div>
                    :
                    <div style={{fontSize:"25px", marginTop:"15px"}}>
                      <svg width="30" height="30">
                        <defs>
                          <linearGradient id="myActivityGradient" gradientTransform="rotate(35)">
                            <stop offset="5%"  stopColor="greenyellow" />
                            <stop offset="100%" stopColor="green" />
                          </linearGradient>
                        </defs>
                        <IconContext.Provider value={{ attr: {fill: "url('#myActivityGradient')"}}}>
                          <MdLocalActivity/>
                        </IconContext.Provider>
                      </svg>
                    </div>
                  }
                </div>
                <div style={{display:"flex", flexDirection:"column", flexGrow:"2"}}>
                    <div style={{height:"30px", display:"flex", alignItems:"flex-end", fontWeight:"bold", padding:"5px", fontSize:"13.5px"}}>{props.prize.Business}</div>
                    <div style={{height:"30px", padding:"5px", whiteSpace:"pre", fontSize:"12px"}}>{props.prize.Description}</div>
                </div>
                {/* <div style={{display:"flex", flexDirection:"column", justifyContent:"center", textAlign:"center", alignItems:"center", width:"60px"}}><div style={{color:"black"}}>{props.prize.Cost}</div></div> */}
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"20px", marginRight:"20px"}}><IoIosArrowForward/></div>
            </div>
        </div>
        <div style={{display:"flex", justifyContent:"center"}}>
            <hr style={{backgroundColor:"lightgray", height:"1px", border:"none", padding:"0px", margin:"0px", width:"90%"}}/>
        </div>

        <Drawer
          anchor="left"
          open={openRedeem} 
          onClose={() => {setOpenRedeem(false)}}
          PaperProps={{
            sx: {
              backgroundColor:"#ebeef9"
            }
          }}
          >
          <div>
          {!success ?
            <div className="huntCardDrawerMainDivDescription">
                <a href={props.prize.LocationUrl} target="_blank" rel="noreferrer" style={{textDecoration:"none", color:"blue", textAlign:"right"}}><h5 style={{fontSize:"12px"}}>{props.prize.Location} Map <ImNewTab style={{fontSize:"10px"}}/></h5></a><br/>
                <div style={{display:"flex", margin:"5px", alignItems:"center"}}>
                    <img className="prizeCardDialogImg" src={props.prize.Picture} alt=""/>
                    <h3 style={{fontSize:"30px", paddingLeft:"15px"}}>{props.prize.Business}</h3>
                </div>
                <hr/>

                <div style={{textAlign:"justify", margin:"15px"}}><h3 style={{textJustify:"inter-character"}}>{props.prize.Paragraph}</h3></div><br/>
                
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", width:"100%", marginTop:"30px"}} className="prizeButtons" onClick={handleRedeem}>
                    <div  style={{marginBottom:"5px"}}>USE COUPON</div>
                    <h6 style={{color:"black", fontSize:"12px", fontFamily:"NewTextFont"}}>ONLY click this button in front of the cashier</h6>
                </div>

            </div>
          :
            <div className="prizeCardDialogDiv">
              {props.prize.Code ?
                <div style={{width:"280px", display:"flex", flexDirection:"column", alignItems:"center", marginTop:"20px"}}>
                  <h4>REDEEMED SUCCESSFULLY!</h4>
                  <hr/>
                  <br/>
                  <h4>CODE: {props.prize.Code}</h4>
                  <br/>
                </div>
              :
                <>
                {props.prize.Link ?
                  <div style={{width:"280px", display:"flex", flexDirection:"column", alignItems:"center", marginTop:"20px"}}>
                    <h4>REDEEMED SUCCESSFULLY!</h4>
                    <hr/>
                    <br/>
                    <a href={props.prize.Link} alt="" style={{textDecoration:"none"}}>Click for Link</a>
                    <br/>
                  </div>
                  :
                  <h4 style={{marginTop:"20px", marginBottom:"15px"}}>REDEEMED SUCCESSFULLY!</h4>
                }
                </>
              }
              
              <div className="blackButtons" style={{width:"80%"}} onClick={resetPrize}>DONE</div>
          </div>
        }
            
        </div>
        </Drawer>


{/* DIALOG BOXES */}
      <Dialog open={openNeedUser} onClose={() => {setOpenNeedUser(false)}}>
        <div>
          <div className="authCard"><h2>NOT REDEEMED</h2><hr/>
            <h4 style={{textAlign:"center"}}><a href="/signIn" rel="noreferrer" style={{color:"#2E59FF"}}>Sign in</a> to your free account to redeem a coupon</h4>
            
          </div>
        </div>
      </Dialog>
    </div>
    )
}
