import { createContext, useState } from "react";
import { db, snapshotToArray } from "./firebase";

// https://www.youtube.com/watch?v=_8M-YVY76O8&ab_channel=TraversyMedia

export const CartContext = createContext({
    updateCartProducts: () => {},
    getCartProducts: () => {},
    getProductQuantity: () => {},
    addOneToCart: () => {},
    removeOneFromCart: () => {},
    getTotalCost: () => {},
})

export function CartProvider({children}) {
    const [cartProducts, setCartProducts] = useState([])   

    function updateCartProducts(user) {
        db.collection("users").doc(user).collection("shoppingCart").get().then(snapshot => {
            const queryData = snapshotToArray(snapshot)
            setCartProducts(queryData)
          })
    }

    function getCartProducts() {
        return cartProducts
    }

    function getProductQuantity(id, user) {
        db.collection("users").doc(user).collection("shoppingCart").get().then(snapshot => {
            const queryData = snapshotToArray(snapshot)
            setCartProducts(queryData)
          })

        const quantity = cartProducts.find(product => product.id === id)

        if (quantity === undefined) {
            return 0;
        }
        return 1;
    }

    function addOneToCart(id, user) {
        const quantity = getProductQuantity(id.id, user);

        const data = {
            id: id.id,
            price: id.price,
            PriceAmount: id.PriceAmount
        }

        if (quantity === 0) {
            db.collection("users").doc(user).collection("shoppingCart").doc(id.id).set(data)
            .then(db.collection("users").doc(user).collection("shoppingCart").get().then(snapshot => {
                const queryData = snapshotToArray(snapshot)
                setCartProducts(queryData)
              }))
        } 
    }

    function removeOneFromCart(id, user) {
        const quantity = getProductQuantity(id, user);

        if (quantity === 0) {
            
        } else {
            db.collection("users").doc(user).collection("shoppingCart").doc(id).delete()
            .then(db.collection("users").doc(user).collection("shoppingCart").get().then(snapshot => {
                const queryData = snapshotToArray(snapshot)
                setCartProducts(queryData)
              }))
        }
    }

    function getTotalCost() {
        let totalCost = 0;
        cartProducts.map((cartItem) => {
            totalCost += (+cartItem.PriceAmount);
        })
        return totalCost
    }


    const contextValue = {
        // items: cartProducts,
        updateCartProducts,
        getCartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        getTotalCost
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider;