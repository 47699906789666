const prizeArray = [
    {
        Business: "Ozark Mtn Candle Co",
        City: "ALL",
        Code: "SHOPPINGNWA",
        Cost: "Varies",
        Description: "Free Shipping Code",
        id: "OzarkCandles",
        Location: "ozarkmountaincandle.etsy.com",
        LocationUrl: "https://www.etsy.com/shop/ozarkmountaincandle/?etsrc=sdt",
        Paragraph: "Redeem for a code to get free shipping on your order!",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FOzarkCandles.webp?alt=media&token=d4c3e290-ff39-4b93-955f-0a7a221fdb37",
        State: "ALL",
        When: "Anytime",
    },
    {
        Business: "Medici Pizza",
        City: "Orem",
        Cost: "$20+",
        Description: "20% off $20 or more",
        id: "Medici",
        Item: "Food",
        Location: "541 E University Pkwy B",
        LocationUrl: "https://www.google.com/maps/place/MidiCi+The+Neapolitan+Pizza+Company/@40.2748022,-111.6838377,15z/data=!4m2!3m1!1s0x0:0xdc9ca4adc01529e3?sa=X&ved=2ahUKEwiegsSPvtf_AhUFIX0KHaehAL4Q_BJ6BAh9EAg",
        Paragraph: "Get 20% off your order when you spend $20 or more!",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FMediciLogo%20(small).jpg?alt=media&token=e225d03a-6c82-4291-aebd-dd9d2d84060d",
        State: "UT",
        When: "M-Sat 11 AM - 12 AM | Sun 12 PM - 12 AM",
    },
    // {
    //     Business: "YogaSix",
    //     City: "Orem",
    //     Cost: "$26",
    //     Description: "Unlimited Week of Yoga",
    //     id: "YogaSix",
    //     Item: "Activity",
    //     Link: "https://www.clubready.com/JoinUs/11386/545651",
    //     Location: "542 E University Pkwy",
    //     LocationUrl: "https://www.google.com/maps/place/YogaSix/@40.272775,-111.682313,15z/data=!4m2!3m1!1s0x0:0xb952e04350242c22?sa=X&ved=2ahUKEwiO9s_MsoH9AhVQDkQIHR2bDccQ_BJ6BAhqEAg",
    //     Paragraph: "Get an unlimited week of yoga classes for $26!",
    //     Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FYoga6.webp?alt=media&token=dfb23e1d-6ac7-4d3a-9f94-c4534dc02774",
    //     State: "UT",
    //     When: "Morning & Afternoon hours vary - See Google",
    // },
    {
        Business: "Yummy's Korean BBQ",
        City: "Orem",
        Cost: "$25+",
        Description: "$5 Off an order of $25 or more",
        id: "YummysBBQ",
        Item: "Food",
        Location: "360 S State St Bldg C Suite 102",
        LocationUrl: "https://www.google.com/maps/place/Yummy's+Korean+BBQ+%26+Sushi+%EA%B3%A0%EA%B8%B0+%EC%A0%84%EB%AC%B8+%7C+Korean+School+%ED%95%9C%EA%B8%80+%ED%95%99%EA%B5%90+%7C+Snowflake+Shave+Ice+Bingsoo/@40.2913195,-111.6925921,18.28z/data=!4m6!3m5!1s0x874d9ac12972214f:0xa81605943968c009!8m2!3d40.292032!4d-111.6931304!16s%2Fg%2F11f4_3d4b8",
        Paragraph: "Get $5 off an order of $25 or more. Not valid with All You Can Eat menus. Dine in only.",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FYummys-logo.webp?alt=media&token=18096105-68e4-4114-975b-1596817a6ccb",
        State: "UT",
        When: "M-Th 11-2PM, 5-8PM | F-Sat 11-9PM",
    },
    {
        Business: "Bahama Bucks",
        City: "Provo",
        Cost: "Varies",
        Description: "BOGO shaved ice",
        id: "BahamaBucks",
        Item: "Food",
        Location: "2265 N University Parkway",
        LocationUrl: "https://www.google.com/maps?q=provo+bahama+bucks&um=1&ie=UTF-8&sa=X&ved=2ahUKEwi_n-nZo_X8AhU-nGoFHVuCCEwQ_AUoAXoECAMQAw",
        Paragraph: "Buy one shaved ice, get one free!",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FBahamaBucks.webp?alt=media&token=dd4ecea3-4696-42a3-a256-64cfe6e6370b",
        State: "UT",
        When: "M-Th 12-10:30PM | F-Sat 12-11PM",
    },
    {
        Business: "The Paleta Bar",
        City: "Provo",
        Cost: "Varies",
        Description: "BOGO Paleta Bar",
        id: "PaletaBar",
        Item: "Food",
        Location: "1283 N University Ave Ste 101",
        LocationUrl: "https://www.google.com/maps/place/Paleta+Bar+Provo/@40.2517164,-111.6592021,15z/data=!4m2!3m1!1s0x0:0x95d953f32cf242e4?sa=X&ved=2ahUKEwjLwtWdwNf_AhXHIkQIHdHfDFkQ_BJ6BAhrEAg",
        Paragraph: "Buy one paleta bar, get one free!",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FPaletaBarLogo%20(small).jpg?alt=media&token=d4970d9f-b425-45ea-82ce-fc9c4860bf14",
        State: "UT",
        When: "M-Th 2-10 PM | F-Sat 12 PM - 12 AM | Sun 3-10 PM",
    },
    // {
    //     Business: "Rincon Poblano",
    //     City: "Provo",
    //     Cost: "Varies",
    //     Description: "BOGO menu item",
    //     id: "Poblanos",
    //     Item: "Food",
    //     Location: "Provo Center Street",
    //     LocationUrl: "https://www.google.com/maps/place/36+N+University+Ave,+Provo,+UT+84601/@40.234018,-111.6590711,18.63z/data=!4m5!3m4!1s0x874d975316a4c50d:0xd88eabc66ca51195!8m2!3d40.2342964!4d-111.6584432",
    //     Paragraph: "Buy one menu item and get the second free! Cannot be combined with other offers.",
    //     Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FPoblano.jpg?alt=media&token=c3c6ab4c-7393-4a09-b868-4445c8e2cf14",
    //     State: "UT",
    //     When: "M-W 11-5PM | Th-Sat 11-9PM",
    // },
    {
        Business: "Sub Zero Ice Cream",
        City: "Provo",
        Cost: "Varies",
        Description: "BOGO menu item",
        id: "SubZero",
        Item: "Food",
        Location: "62 W Center St, Provo",
        LocationUrl: "https://www.google.com/maps/place/Sub+Zero+Nitrogen+Ice+Cream/@40.2339288,-111.6618702,17z/data=!3m1!4b1!4m5!3m4!1s0x874d9753614d1f8d:0xd2385fdaa3c1c898!8m2!3d40.2339288!4d-111.6596815",
        Paragraph: "Buy one menu item and get the second free! The second item must be of equal or lesser value. This offer excludes full ice cream cakes or pies.",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FSubzero.jpg?alt=media&token=d578fc46-b196-482b-bf02-2ab9b7fcd6d1",
        State: "UT",
        When: "M-Th 12-10:30PM | F-Sa 12-11PM",
    },
    {
        Business: "Peak Nights",
        City: "Provo",
        Cost: "$6",
        Description: "2 for 1 country swing entries",
        id: "PeakNights",
        Item: "Activity",
        Location: "Peak Performance Center",
        LocationUrl: "https://www.google.com/maps/place/Peak+Performance+Dance+Center/@40.310328,-111.7869785,12z/data=!4m9!1m2!2m1!1speak+performance+center!3m5!1s0x874d9788463a60c1:0xac3c04ba9fa21eb9!8m2!3d40.2098986!4d-111.649854!15sChdwZWFrIHBlcmZvcm1hbmNlIGNlbnRlcloZIhdwZWFrIHBlcmZvcm1hbmNlIGNlbnRlcpIBDGRhbmNlX3NjaG9vbJoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VOVmFEZHBMVkZuRUFF",
        Paragraph: "Pay $6 and get TWO entries at Peak Nights on Fridays! Instruction from 9-9:30 PM.",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FPeak%20Nights.jpg?alt=media&token=fd083c5c-7ced-445e-a334-fbee0550e76c",
        State: "UT",
        When: "Fridays from 9 PM to 1 AM",
    },
    // {
    //     Business: "Moosejaw",
    //     City: "Bentonville",
    //     Cost: "$25+",
    //     Description: "$10 off a purchase of $25+",
    //     id: "Moosejaw",
    //     Item: "Activity",
    //     Location: "Bentonville S Main St",
    //     LocationUrl: "https://www.google.com/maps/place/111+S+Main+St,+Bentonville,+AR+72712/@36.3728491,-94.211159,17z/data=!3m1!4b1!4m5!3m4!1s0x87c91a99dd16994f:0x717455b6a85eb84a!8m2!3d36.3728448!4d-94.2089703",
    //     Paragraph: "Get $10 off your purchase of $25 or more! Cannot be combined with other offers.",
    //     Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FMOOSEJAW%20LOGO%20RED.jpg?alt=media&token=bfdba434-9b7b-45da-885c-72c52e2e04cd",
    //     State: "AR",
    //     When: "M-Sat 10-8PM | Sun 11-6PM",
    // },
    {
        Business: "Ozark Mtn Bagel Co.",
        City: "Bentonville",
        Cost: "Varies",
        Description: "BOGO breakfast sandwich",
        id: "Ozark Mountain Bagel Co.",
        Item: "Food",
        Location: "105 W Central Ave",
        LocationUrl: "https://www.google.com/maps/place/Ozark+Mountain+Bagel+Co.+on+the+Square/@36.3708777,-94.2091237,17.27z/data=!4m9!1m2!2m1!1sozark+mountain+bagel+bentonville+ar!3m5!1s0x87c91b9d98badac5:0xad36b3b73de69118!8m2!3d36.372126!4d-94.20921!15sCiNvemFyayBtb3VudGFpbiBiYWdlbCBiZW50b252aWxsZSBhclolIiNvemFyayBtb3VudGFpbiBiYWdlbCBiZW50b252aWxsZSBhcpIBCmJhZ2VsX3Nob3DgAQA",
        Paragraph: "Buy one breakfast sandwich, get one free!",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FOzarkMountainBagels.jpg?alt=media&token=84514a21-96a5-41f3-87ab-f6e0511ac029",
        State: "AR",
        When: "M-Sun 7AM-1PM",
    },
    {
        Business: "Strider Store",
        City: "Bentonville",
        Cost: "FREE",
        Description: "Free Pocket Bike",
        id: "Strider",
        Item: "Activity",
        Location: "109 N Main St",
        LocationUrl: "https://www.google.com/maps/place/Strider+Bikes+Bentonville/@36.3727136,-94.2089687,15z/data=!4m5!3m4!1s0x0:0x16059a82609d241f!8m2!3d36.3727136!4d-94.2089687",
        Paragraph: "Get one Free Pocket Bike!",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FStrider.jpg?alt=media&token=39fbbae8-fdd7-42f4-a56e-10a157564522",
        State: "AR",
        When: "W-F 10-6PM | Sat 9-6PM | Sun 10-4PM",
    },
    {
        Business: "Strider Store",
        City: "Bentonville",
        Cost: "$75+",
        Description: "$10 off a purchase of $75+",
        id: "Strider",
        Item: "Activity",
        Location: "109 N Main St",
        LocationUrl: "https://www.google.com/maps/place/Strider+Bikes+Bentonville/@36.3727136,-94.2089687,15z/data=!4m5!3m4!1s0x0:0x16059a82609d241f!8m2!3d36.3727136!4d-94.2089687",
        Paragraph: "Get $10 off a purchase of $75 or more!",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FStrider.jpg?alt=media&token=39fbbae8-fdd7-42f4-a56e-10a157564522",
        State: "AR",
        When: "W-F 10-6PM | Sat 9-6PM | Sun 10-4PM",
    },
    {
        Business: "Three Dog Bakery",
        City: "Bentonville",
        Cost: "FREE",
        Description: "Free Small Pupcake",
        id: "ThreeDogBakery",
        Item: "Activity",
        Location: "113 W Central Ave #101",
        LocationUrl: "https://www.google.com/maps/place/Three+Dog+Bakery/@36.3722557,-94.2116103,17z/data=!3m1!4b1!4m5!3m4!1s0x87c91a99c7781ebd:0x732d81482f6ca29c!8m2!3d36.3722557!4d-94.2094216",
        Paragraph: "Get one Free Small Pupcake! Cannot be combined with other offers.",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FThreeDogBakery.jpg?alt=media&token=6973500a-80aa-4d1e-ad30-003120263ac8",
        State: "AR",
        When: "M-Sat 10-7PM | Sun 12-5PM",
    },
    {
        Business: "Anime Cafe",
        City: "Rogers",
        Cost: "Varies",
        Description: "15% off highest priced item",
        id: "AnimeCafe",
        Item: "Food",
        Location: "111 S 2nd St",
        LocationUrl: "https://www.google.com/maps/place/Anime+Cafe/@36.3327403,-94.1172993,15z/data=!4m2!3m1!1s0x0:0x52472498455cf61d?sa=X&ved=2ahUKEwio2_Ps28L7AhUEJEQIHT60BzQQ_BJ6BQiLARAI",
        Paragraph: "Take 15% off of the highest priced item in your purchase!",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FAnime-Cafe-Logo.jpg?alt=media&token=0a328bf0-09ff-46fe-b57a-7da866d5f2f7",
        State: "AR",
        When: "Tues-Sat 11AM - 7PM",
    },
    {
        Business: "Nola's Pantry",
        City: "Rogers",
        Cost: "$25+",
        Description: "$5 off a purchase of $25+",
        id: "NolasPantry",
        Item: "Food",
        Location: "103 W Chestnut St Suite 100",
        LocationUrl: "https://www.google.com/maps/place/Nola's+Pantry/@36.3342542,-94.1162189,15z/data=!4m5!3m4!1s0x0:0x8719966de81d3842!8m2!3d36.3342542!4d-94.1162189",
        Paragraph: "$5 off a purchase of $25 or more.",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FNola_sPantry.jpg?alt=media&token=e3c665c4-c1d8-44cd-a7ac-3fb8d5e2075b",
        State: "AR",
        When: "Tues-Sat 8AM - 4PM",
    },
    {
        Business: "Urban Bath & Body Co.",
        City: "Rogers",
        Cost: "Varies",
        Description: "15% off all custom products",
        id: "UrbanBath&BodyCo",
        Item: "Activity",
        Location: "111 W Walnut St",
        LocationUrl: "https://www.google.com/maps/place/Urban+Bath+%26+Body+Company/@36.3331911,-94.1166658,15z/data=!4m5!3m4!1s0x0:0x6c49a3cb43f1fece!8m2!3d36.333194!4d-94.1166997",
        Paragraph: "15% off all 'custom made' products. Excludes finchberry, bathbombs, and shower steamers.",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FUrbanB_B.jpg?alt=media&token=1aad96f0-c9a0-4511-95e7-e3e9ae4c884c",
        State: "AR",
        When: "Mon-Sat 10AM - 5PM",
    },
    {
        Business: "Pineapple Bliss",
        City: "Springdale",
        Cost: "Varies",
        Description: "BOGO cup or cake cone",
        id: "PineappleBliss",
        Item: "Food",
        Location: "1345 E Henri De Tonti Blvd",
        LocationUrl: "https://www.google.com/maps/place/Pineapple+Bliss+NWA/@36.1753261,-94.2127449,15z/data=!4m2!3m1!1s0x0:0xcc03889668ba8fbd?sa=X&ved=2ahUKEwjuid3l7b38AhWgIkQIHZK_Cf8Q_BJ6BAhhEAg",
        Paragraph: "Buy one cup or cake cone and get one of equal or lesser value free!",
        Picture: "https://firebasestorage.googleapis.com/v0/b/pursuit-99890.appspot.com/o/HuntImages%2FPineapple%20Bliss%20Logo%20(small).jpg?alt=media&token=e3ba6867-00a8-46a4-b9ef-872aa48a9428",
        State: "AR",
        When: "W-Fri 2-8:30PM | Sat-Sun 1-8:30PM",
    }
]

export { prizeArray }