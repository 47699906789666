import React, {useState} from "react";
import { MathSquare } from "../ClueMathSquare";

export function MathSquareData(props) {
    const [mathArrayData, setMathArrayData] = useState([]);
    const [mathAnswer, setMathAnswer] = useState([]);
    const [clueBegan, setClueBegan] = useState(false);

    const handleLoadPuzzle = () => {
        setClueBegan(true)
        if (props.ClueMSid === "MSone") {
            setMathArrayData([
                [ "",     "X",    "",    "-",    "",       -2],
                ["+",   -2000,   "X",   -2000,  "-",    -2000],
                [ "",     "X",    "",    "-",    "",       37],
                ["/",   -2000,   "X",   -2000,  "X",    -2000],
                [ "",     "+",    "",    "+",    "",       12],
                [  3,   -2000,   224,   -2000,   36,    -2000]
                ])
            setMathAnswer([
                [1,       'X',     7,    '-',     9,       -2],
                ['+',   -2000,   'X',  -2000,   '-',    -2000],
                [5,       'X',     8,    '-',     3,       37],
                ['/',   -2000,   'X',  -2000,   'X',    -2000],
                [2,       '+',     4,    '+',     6,       12],
                [3,     -2000,   224,  -2000,    36,    -2000]
                ])
        } else if (props.ClueMSid === "MStwo") {
            setMathArrayData([
                ["8",     "+",   "6",    "+",    "",       19],
                ["+",   -2000,   "-",   -2000,  "+",    -2000],
                [ "",     "X",    "",    "/",    "",       12],
                ["-",   -2000,   "-",   -2000,  "-",    -2000],
                [ "",     "X",    "",    "X",    "",      126],
                [  9,   -2000,    -7,   -2000,   -1,    -2000]
                ])
            setMathAnswer([
                ["8",     "+",   "6",    "+",   "5",       19],
                ["+",   -2000,   "-",   -2000,  "+",    -2000],
                ["3",     "X",   "4",    "/",   "1",       12],
                ["-",   -2000,   "-",   -2000,  "-",    -2000],
                ["2",     "X",   "9",    "X",   "7",      126],
                [  9,   -2000,    -7,   -2000,   -1,    -2000]
                ])
        } else if (props.ClueMSid === "MSthree") {
            setMathArrayData([
                [ "",     "X",    "",    "X",   "9",      144],
                ["/",   -2000,   "X",   -2000,  "X",    -2000],
                [ "",     "X",    "",     "+",   "",       13],
                ["+",   -2000,   "-",   -2000,  "-",    -2000],
                [ "",     "X",    "",     "+",   "",       19],
                [ 11,   -2000,     9,   -2000,   50,    -2000]
                ])
            setMathAnswer([
                ["8",     "X",   "2",    "X",   "9",      144],
                ["/",   -2000,   "X",   -2000,  "X",    -2000],
                ["1",     "X",   "7",     "+",  "6",       13],
                ["+",   -2000,   "-",   -2000,  "-",    -2000],
                ["3",     "X",   "5",     "+",  "4",       19],
                [ 11,   -2000,     9,   -2000,   50,    -2000]
                ])
        } 
    }


    return (
        <div>
            <br/>
            {clueBegan === false ?
              <div className="loadPuzzleButtons" onClick={()=>{handleLoadPuzzle()}}>Load Puzzle</div>
              :
              <MathSquare mathData={mathArrayData} mathAnswer={mathAnswer} ClueMS={props.ClueMS}/>
            }
            <br/>
        </div>
    )
}