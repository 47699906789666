

 const huntArray = [
    {
        Area: "J. Lynn Crane Park",
        City: "Herriman",
        Description: `Come take on riddles, language clues, codes, sliding puzzles, number games, and word scrambles as you are led to random locations within J. Lynn Crane park - locations may be prominent objects or hidden among grassy lawns; see if you can find them all!`,
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/J.+Lynn+Crane+Park/@40.5156594,-112.018178,17z/data=!3m1!4b1!4m5!3m4!1s0x8752852891e61713:0x41a997a6e4e80806!8m2!3d40.5156553!4d-112.0159893",
        Drive: "Walk",
        Filter: "largeHunt",
        FilterTwo: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Herriman Hunt",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "18",
        Time: "60 min",
        Title: "Herriman Hunt",
        Winter: "No",
        priceList: [
            { id: "Herriman Hunt",          team: 1,    price: "price_1LaXZZGAEvrTnitxX4NbhjpB", PriceAmount: "18" },
            { id: "Herriman Hunt II",       team: 2,    price: "price_1LaXa2GAEvrTnitxJhZLbGYu", PriceAmount: "18" },
            { id: "Herriman Hunt III",      team: 3,    price: "price_1LaXc4GAEvrTnitxazvkXIBK", PriceAmount: "18" },
            { id: "Herriman Haste",         team: 4,    price: "price_1LaXV0GAEvrTnitxBv7lXIM1", PriceAmount: "18" },
            { id: "Herriman Haste II",      team: 5,    price: "price_1LaXX4GAEvrTnitxDJm7Auao", PriceAmount: "18" },
            { id: "Herriman Haste III",     team: 6,    price: "price_1LaXXZGAEvrTnitxdQ4HsTs1", PriceAmount: "18" },
            { id: "Herriman Hike",          team: 7,    price: "price_1LaXY6GAEvrTnitxofSWTXqO", PriceAmount: "18" },
            { id: "Herriman Hike II",       team: 8,    price: "price_1LaXYVGAEvrTnitxhJe5nc5G", PriceAmount: "18" },
            { id: "Herriman Hike III",      team: 9,    price: "price_1LaXZ3GAEvrTnitxfwiTIa3u", PriceAmount: "18" },
            { id: "Herriman Hurtle",        team: 10,   price: "price_1LaXckGAEvrTnitxJOPf8H8Y", PriceAmount: "18" },
            { id: "Herriman Hurtle II",     team: 11,   price: "price_1LaXdIGAEvrTnitxSrBl7vLT", PriceAmount: "18" },
            { id: "Herriman Hurtle III",    team: 12,   price: "price_1LaXdfGAEvrTnitxO7tYMlqb", PriceAmount: "18" },
            { id: "Herriman Hunt B",        team: 13,   price: "price_1LaXmjGAEvrTnitxTagZFjBn", PriceAmount: "18" },
            { id: "Herriman Hunt B II",     team: 14,   price: "price_1LaXomGAEvrTnitx0pAZUuM5", PriceAmount: "18" },
            { id: "Herriman Hunt B III",    team: 15,   price: "price_1LaXpGGAEvrTnitxXFZqEgZ8", PriceAmount: "18" },
            { id: "Herriman Hunt C",        team: 16,   price: "price_1LaXpsGAEvrTnitxJWVyEUWY", PriceAmount: "18" },
            { id: "Herriman Hunt C II",     team: 17,   price: "price_1LaXqEGAEvrTnitxVzeQxtQn", PriceAmount: "18" },
            { id: "Herriman Hunt C III",    team: 18,   price: "price_1LaXqYGAEvrTnitxPdiANtpv", PriceAmount: "18" }
        ]
    },
    {
        Area: "J. Lynn Crane Park",
        City: "Herriman",
        Description: "Feeling like cranking up the difficulty?? This hunt happens in the same location as the medium adventure, 'Herriman Hunt', but these riddles, word games, codes, ciphers, and number manipulation clues are much more thought provoking!",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/place/J.+Lynn+Crane+Park/@40.5156594,-112.018178,17z/data=!3m1!4b1!4m5!3m4!1s0x8752852891e61713:0x41a997a6e4e80806!8m2!3d40.5156553!4d-112.0159893",
        Drive: "Walk",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "Herriman Hustle",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "UT",
        Teams: "6",
        Time: "75 min",
        Title: "Herriman Hustle",
        Winter: "No",
        // Herriman Hustle Prices
        priceList: [
            { id: "Herriman Hustle",          team: 1,    price: "price_1LaXe2GAEvrTnitxUKBLo5Aa", PriceAmount: "20" },
            { id: "Herriman Hustle II",       team: 2,    price: "price_1LaXeUGAEvrTnitx3bQNkvRk", PriceAmount: "20" },
            { id: "Herriman Hustle III",      team: 3,    price: "price_1LaXewGAEvrTnitxaUoqvH3r", PriceAmount: "20" },
            { id: "Herriman Hustle IV",       team: 4,    price: "price_1N0xgxGAEvrTnitxCv0INgNc", PriceAmount: "20" },
            { id: "Herriman Hustle V",        team: 5,    price: "price_1N0xh9GAEvrTnitxuplEccRI", PriceAmount: "20" },
            { id: "Herriman Hustle VI",       team: 6,    price: "price_1N0xhMGAEvrTnitxW3wYe9TE", PriceAmount: "20" },
        ]
    },
    {
        Area: "Centennial Park",
        City: "Lehi",
        Description: "Gather your group at the location below to start the hunts all together; the first clue will lead each team to their first destination. Take on brain teasers, word games, riddles, logic puzzles, and comparison clues on your journey around various Lehi locations!",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/Lehi,+UT/@40.4158775,-111.8589176,267m/data=!3m1!1e3!4m6!3m5!1s0x874d7fedd81a1b69:0xfd6fac81600d61c9!8m2!3d40.3916172!4d-111.8507662!16zL20vMDEwZ3N6?entry=ttu",
        Drive: "Drive",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Lehi Logic",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "UT",
        Teams: "3",
        Time: "60 min",
        Title: "Lehi Logic",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Lehi Logic",          team: 1,    price: "price_1NznVsGAEvrTnitxjt4NfE2V", PriceAmount: "15" },
            { id: "Lehi Logic II",       team: 2,    price: "price_1NznW3GAEvrTnitxWBtbpiwT", PriceAmount: "15" },
            { id: "Lehi Logic III",      team: 3,    price: "price_1NznWFGAEvrTnitxHMZ2Wd7N", PriceAmount: "15" },
            // { id: "Lehi Logic IV",       team: 4,    price: "", PriceAmount: "15" },
            // { id: "Lehi Logic V",        team: 5,    price: "", PriceAmount: "15" },
            // { id: "Lehi Logic VI",       team: 6,    price: "", PriceAmount: "15" },
        ]
    },
    {
        Area: "Lehi Legacy Center",
        City: "Lehi",
        Description: "The entire hunt hovers within a block or two of the Lehi Legacy Center. Solve the riddles, orienteering clues, word games, and codes to complete the journey from object to object near the center - how fast can you do it? NOTE: this hunt happens in the same location as 'Mog along Main' and 'Mystery on Main'.",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/Lehi+Main+Street+Historic+District,+Lehi,+UT+84043/@40.3878671,-111.883012,14z/data=!3m1!4b1!4m8!1m2!2m1!1sveterans+building!3m4!1s0x874d81d166d3777b:0x853bdc5f82f907de!8m2!3d40.3878726!4d-111.8491934",
        Drive: "Walk",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Meander down Main",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "UT",
        Teams: "6",
        Time: "45 min",
        Title: "Meander down Main",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Meander down Main",          team: 1,    price: "price_1LaWu1GAEvrTnitxUceKNziR", PriceAmount: "15" },
            { id: "Meander down Main II",       team: 2,    price: "price_1LaWumGAEvrTnitxqLL1Ozku", PriceAmount: "15" },
            { id: "Meander down Main III",      team: 3,    price: "price_1LaWwDGAEvrTnitxgnif0ENr", PriceAmount: "15" },
            { id: "Meander down Main IV",       team: 4,    price: "price_1N49RzGAEvrTnitxGVdxDvVy", PriceAmount: "15" },
            { id: "Meander down Main V",        team: 5,    price: "price_1N49SEGAEvrTnitxa3Pyu6it", PriceAmount: "15" },
            { id: "Meander down Main VI",       team: 6,    price: "price_1N49SQGAEvrTnitx6Cd9oVi9", PriceAmount: "15" },
        ]
    },
    {
        Area: "Lehi Legacy Center",
        City: "Lehi",
        Description: "Gather your group at the location below to start the hunts all together; the first clue will lead each team to their first destination. You will drive to a number of Lehi's parks while deciphering the riddles, directional clues, and word games! There will be two to three clues in each park, so don't leave the parks too quickly. NOTE: this hunt happens in the same locations as 'Park Pursuit' and 'Park Puzzles'.",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/Lehi+Legacy+Center/@40.3889904,-111.8528416,827m/data=!3m2!1e3!4b1!4m6!3m5!1s0x874d81d3c06646c9:0x54972c6f4969009b!8m2!3d40.3889904!4d-111.8502667!16s%2Fg%2F1tjs5dxn?entry=ttu",
        Drive: "Drive",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Park Pathways",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "UT",
        Teams: "6",
        Time: "45 min",
        Title: "Park Pathways",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Park Pathways",          team: 1,    price: "price_1LaXA6GAEvrTnitxQBwV0YsF", PriceAmount: "15" },
            { id: "Park Pathways II",       team: 2,    price: "price_1LaXAbGAEvrTnitxsSA62dI3", PriceAmount: "15" },
            { id: "Park Pathways III",      team: 3,    price: "price_1LaXB5GAEvrTnitxU0XcZ47D", PriceAmount: "15" },
            { id: "Park Pathways IV",       team: 4,    price: "price_1N3mjBGAEvrTnitxTPpVw8L8", PriceAmount: "15" },
            { id: "Park Pathways V",        team: 5,    price: "price_1N3mjRGAEvrTnitxUMEq8IOP", PriceAmount: "15" },
            { id: "Park Pathways VI",       team: 6,    price: "price_1N3mjfGAEvrTnitx9F5Ln1Xo", PriceAmount: "15" },
        ]
    },
    {
        Area: "Lehi Legacy Center",
        City: "Lehi",
        Description: "The entire hunt hovers within a block or two of the Lehi Legacy Center. You'll be faced with word clues, directional challenges, number puzzles, and riddles as you attempt to find each new destination on this hunt! NOTE: this hunt happens in the same location as 'Meander down Main' and 'Mystery on Main'.",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Lehi+Main+Street+Historic+District,+Lehi,+UT+84043/@40.3878671,-111.883012,14z/data=!3m1!4b1!4m8!1m2!2m1!1sveterans+building!3m4!1s0x874d81d166d3777b:0x853bdc5f82f907de!8m2!3d40.3878726!4d-111.8491934",
        Drive: "Walk",
        Filter: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Mog along Main",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "Mog along Main",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Mog along Main",          team: 1,    price: "price_1LaWwkGAEvrTnitxlNePvMDK", PriceAmount: "18" },
            { id: "Mog along Main II",       team: 2,    price: "price_1LaWyNGAEvrTnitx3LqdBhSR", PriceAmount: "18" },
            { id: "Mog along Main III",      team: 3,    price: "price_1LaWyNGAEvrTnitx3LqdBhSR", PriceAmount: "18" },
            { id: "Mog along Main IV",       team: 4,    price: "price_1N4VKaGAEvrTnitxpJ1Jn0pe", PriceAmount: "18" },
            { id: "Mog along Main V",        team: 5,    price: "price_1N4VKlGAEvrTnitxfwITVHuJ", PriceAmount: "18" },
            { id: "Mog along Main VI",       team: 6,    price: "price_1N4VKvGAEvrTnitxhP3T5Ygc", PriceAmount: "18" },
        ]
    },
    {
        Area: "Lehi Legacy Center",
        City: "Lehi",
        Description: "Gather your group at the location below to start the hunts all together; the first clue will lead each team to their first destination. You will drive to a number of Lehi's parks while deciphering the riddles, directional clues, word games, and maybe even a computer puzzle! There will be two to three clues in each park, so don't leave the parks too quickly. NOTE: this hunt happens in the same locations as 'Park Pathways' and 'Park Puzzles'.",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Lehi+Legacy+Center/@40.3889904,-111.8528416,827m/data=!3m2!1e3!4b1!4m6!3m5!1s0x874d81d3c06646c9:0x54972c6f4969009b!8m2!3d40.3889904!4d-111.8502667!16s%2Fg%2F1tjs5dxn?entry=ttu",
        Drive: "Drive",
        Filter: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Park Pursuit",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "6",
        Time: "75 min",
        Title: "Park Pursuit",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Park Pursuit",          team: 1,    price: "price_1LaXBcGAEvrTnitxeLD6E7uU", PriceAmount: "18" },
            { id: "Park Pursuit II",       team: 2,    price: "price_1LaXC7GAEvrTnitx216lywxN", PriceAmount: "18" },
            { id: "Park Pursuit III",      team: 3,    price: "price_1LaXCTGAEvrTnitx94Oj0clV", PriceAmount: "18" },
            { id: "Park Pursuit IV",       team: 4,    price: "price_1N6aWZGAEvrTnitxCAoLrvIU", PriceAmount: "18" },
            { id: "Park Pursuit V",        team: 5,    price: "price_1N6aWuGAEvrTnitxYe2ssodW", PriceAmount: "18" },
            { id: "Park Pursuit VI",       team: 6,    price: "price_1N6aX8GAEvrTnitxOqT4kKvD", PriceAmount: "18" },
        ]
    },
    {
        Area: "Lehi Legacy Center",
        City: "Lehi",
        Description: "The entire hunt hovers within a block or two of the Center. If you're looking for an extra challenge, see if you can solve the riddles, word games, number manipulation, and logic puzzles contained in this clue to complete the hunt! NOTE: this hunt happens in the same location as 'Meander down Main' and 'Mog along Main'.",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/place/Lehi+Main+Street+Historic+District,+Lehi,+UT+84043/@40.3878671,-111.883012,14z/data=!3m1!4b1!4m8!1m2!2m1!1sveterans+building!3m4!1s0x874d81d166d3777b:0x853bdc5f82f907de!8m2!3d40.3878726!4d-111.8491934",
        Drive: "Walk",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "Mystery on Main",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "UT",
        Teams: "3",
        Time: "75 min",
        Title: "Mystery on Main",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Mystery on Main",          team: 1,    price: "price_1LaWzMGAEvrTnitxp3SzlFqo", PriceAmount: "20" },
            { id: "Mystery on Main II",       team: 2,    price: "price_1LaX7tGAEvrTnitxAdVIgDgx", PriceAmount: "20" },
            { id: "Mystery on Main III",      team: 3,    price: "price_1LaX8YGAEvrTnitx9UxyB1ji", PriceAmount: "20" },
        ]
    },
    {
        Area: "Lehi Legacy Center",
        City: "Lehi",
        Description: "Gather your group at the location below to start the hunts all together; the first clue will lead each team to their first destination. You will drive to a number of Lehi's parks while deciphering the difficult riddles, directional clues, word games, and maybe even a computer puzzle! There will be two to three clues in each park, so don't leave the parks too quickly. NOTE: this hunt happens in the same locations as 'Park Pathways' and 'Park Pursuit'.",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/place/Lehi+Legacy+Center/@40.3889904,-111.8528416,827m/data=!3m2!1e3!4b1!4m6!3m5!1s0x874d81d3c06646c9:0x54972c6f4969009b!8m2!3d40.3889904!4d-111.8502667!16s%2Fg%2F1tjs5dxn?entry=ttu",
        Drive: "Drive",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "Park Puzzles",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "UT",
        Teams: "3",
        Time: "75 min",
        Title: "Park Puzzles",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Park Puzzles",          team: 1,    price: "price_1LaXCwGAEvrTnitxNOBAeJ4b", PriceAmount: "20" },
            { id: "Park Puzzles II",       team: 2,    price: "price_1LaXDJGAEvrTnitxTqznK2wP", PriceAmount: "20" },
            { id: "Park Puzzles III",      team: 3,    price: "price_1LaXDpGAEvrTnitxRfaAbzPi", PriceAmount: "20" },
        ]
    },
    {
        Area: "Nielsen's Grove Park",
        City: "Orem",
        Description: "Park at Nielsen's Grove Park; all clues are within the park. Explore one of Orem's oldest and largest parks! Bring a frisbee or football for some fun along the way.",
        Difficulty: "Kids",
        Directions: "https://www.google.com/maps/place/Nielsen's+Grove+Park/@40.2622492,-111.7054437,17z/data=!3m1!4b1!4m5!3m4!1s0x874d9a573f81d73b:0x8c91b1c0bb0af9ba!8m2!3d40.2622492!4d-111.703255",
        Drive: "Walk",
        HuntCardCSS: "huntCardDivVeryEasy",
        id: "Games at the Grove",
        InOrOut: "Outside",
        PriceAmount: "$10",
        State: "UT",
        Teams: "1",
        Time: "30 min",
        Title: "Games at the Grove",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Games at the Grove",          team: 1,    price: "price_1LaWhLGAEvrTnitx71RaR91c", PriceAmount: "10" },
        ]
    },
    {
        Area: "Windsor Park",
        City: "Orem",
        Description: "Park at Windsor Park; all clues are within the park. Come enjoy this hidden gem of a park! Explore with fun clues leading you all over, and bring frisbees, footballs, basketballs, tennis gear, or beanbags along for added fun along the way!",
        Difficulty: "Kids",
        Directions: "https://www.google.com/maps/place/Windsor+Park/@40.2746923,-111.7022443,12z/data=!4m9!1m2!2m1!1swindsor+park!3m5!1s0x874d853c9471c971:0x47486a68427ec8a5!8m2!3d40.3207214!4d-111.6988926!15sCgx3aW5kc29yIHBhcmuSAQRwYXJr",
        Drive: "Walk",
        HuntCardCSS: "huntCardDivVeryEasy",
        id: "Wandering Windsor",
        InOrOut: "Outside",
        PriceAmount: "$10",
        State: "UT",
        Teams: "1",
        Time: "30 min",
        Title: "Wandering Windsor",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Wandering Windsor",          team: 1,    price: "price_1LaWiVGAEvrTnitxY6j1cmI8", PriceAmount: "10" },
        ]
    },
    {
        Area: "Scera Park",
        City: "Orem",
        Description: "Park at Scera Park; all clues are within the park. Take your kids on a fun adventure solving clues on their way around this beautiful park. You'll explore random murals, statues, and playgrounds on your way around!",
        Difficulty: "Kids",
        Directions: "https://www.google.com/maps/place/Scera+Park/@40.2857621,-111.6881895,127m/data=!3m1!1e3!4m6!3m5!1s0x874d9a9502e63beb:0x581693ebcedb1fef!8m2!3d40.2867544!4d-111.6876852!16s%2Fg%2F1w0h7_8h?entry=ttu",
        Drive: "Walk",
        HuntCardCSS: "huntCardDivVeryEasy",
        id: "Scera Search",
        InOrOut: "Outside",
        PriceAmount: "$10",
        State: "UT",
        Teams: "1",
        Time: "45 min",
        Title: "Scera Search",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Scera Search",          team: 1,    price: "price_1Nj3L9GAEvrTnitxnV12LNbP", PriceAmount: "10" },
        ]
    },
    {
        Area: "Orem Library",
        City: "Orem",
        Description: "Avoid the outdoors and hunt through the some books inside the Orem Library. Decipher riddles, codes, ciphers and more as you race through this hunt. Attention to detail and a good memory is a plus!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Orem+Public+Library/@40.2986731,-111.6968587,874m/data=!3m2!1e3!4b1!4m5!3m4!1s0x874d9ac8b0aa6f63:0x595700ed210f2254!8m2!3d40.2986722!4d-111.6942447",
        Drive: "Walk",
        HuntCardCSS: "huntCardDivMedium",
        id: "Library Lockdown II",
        InOrOut: "Indoors",
        PriceAmount: "$18",
        State: "UT",
        Teams: "2",
        Time: "60 min",
        Title: "Library Lockdown II",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Library Lockdown II",          team: 1,    price: "price_1LaWBqGAEvrTnitx9jghPR0H", PriceAmount: "18" },
            { id: "Library Lockdown II B",       team: 2,    price: "price_1LaWsOGAEvrTnitxjC4dVmMF", PriceAmount: "18" },
        ]
    },
    {
        Area: "Orem Maceys",
        City: "Orem",
        Description: "Gather your group at the location below to start the hunts all together; the first clue will lead each team to their first destination. There are multiple parks which you will need to visit in Orem to complete this hunt. Some clues lead you to a new park, some clues are answered within the park you will be in - pay attention!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/@40.312419,-111.7012298,392m/data=!3m1!1e3?entry=ttu",
        Drive: "Drive",
        Filter: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Parade through Parks",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "Parade through Parks",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Parade through Parks",          team: 1,    price: "price_1LaWHCGAEvrTnitxX02cvh0E", PriceAmount: "18" },
            { id: "Parade through Parks II",       team: 2,    price: "price_1LaWbYGAEvrTnitx19QAholF", PriceAmount: "18" },
            { id: "Parade through Parks III",      team: 3,    price: "price_1LaWcBGAEvrTnitxjxsL1RWS", PriceAmount: "18" },
            { id: "Parade through Parks IV",       team: 4,    price: "price_1N5ZFyGAEvrTnitxZJSfWEoU", PriceAmount: "18" },
            { id: "Parade through Parks V",        team: 5,    price: "price_1N5ZG9GAEvrTnitxAIuCriIO", PriceAmount: "18" },
            { id: "Parade through Parks VI",       team: 6,    price: "price_1N5ZGIGAEvrTnitx4qOX3nvV", PriceAmount: "18" },
        ]     
    },
    {
        Area: "Orem City Center Park",
        City: "Orem",
        Description: "All clues are within the Orem City Center Park, or a block away. Can you beat your friends' teams as you solve these word games, orientational clues, riddles, and codes to complete the hidden hunt? NOTE: this hunt happens in the same location as 'City Park Puzzles'.",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Orem+City+Center+Park/@40.2962597,-111.6902668,16.4z/data=!4m9!1m2!2m1!1sorem+city+park!3m5!1s0x874d9bc794c05515:0x90b210b7232d6fa3!8m2!3d40.2981259!4d-111.688092!15sCg5vcmVtIGNpdHkgcGFya5IBBHBhcms",
        Drive: "Walk",
        Filter: "largeHunt",
        FilterTwo: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "City Park Pursuit",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "18",
        Time: "60 min",
        Title: "City Park Pursuit",
        Winter: "No",
        priceList: [
            { id: "City Park Pursuit",          team: 1,     price: "price_1LaXRPGAEvrTnitxvA6spdsw", PriceAmount: "18" },
            { id: "City Park Pursuit II",       team: 2,     price: "price_1LaXS3GAEvrTnitxgoCvmpmQ", PriceAmount: "18" },
            { id: "City Park Pursuit III",      team: 3,     price: "price_1LaXSSGAEvrTnitx1FA181zw", PriceAmount: "18" },
            { id: "City Park Pursuit B",        team: 4,     price: "price_1LaXrBGAEvrTnitxTwHEKsOR", PriceAmount: "18" },
            { id: "City Park Pursuit B II",     team: 5,     price: "price_1LaXrVGAEvrTnitx4OloEleI", PriceAmount: "18" },
            { id: "City Park Pursuit B III",    team: 6,     price: "price_1LaXrwGAEvrTnitx5B9xpSk2", PriceAmount: "18" },
            { id: "City Park Pursuit C",        team: 7,     price: "price_1LaXsFGAEvrTnitxk0YUKF0b", PriceAmount: "18" },
            { id: "City Park Pursuit C II",     team: 8,     price: "price_1LaXucGAEvrTnitxBChzPzxA", PriceAmount: "18" },
            { id: "City Park Pursuit C III",    team: 9,     price: "price_1LaXvCGAEvrTnitxxekl5o7W", PriceAmount: "18" },
            { id: "City Park Pursuit D",        team: 10,    price: "price_1LaXvtGAEvrTnitxPv5JLH1x", PriceAmount: "18" },
            { id: "City Park Pursuit D II",     team: 11,    price: "price_1LaXwNGAEvrTnitxcSR6FmGk", PriceAmount: "18" },
            { id: "City Park Pursuit D III",    team: 12,    price: "price_1LaXwrGAEvrTnitxOL3oXqU0", PriceAmount: "18" },
            { id: "City Park Pursuit E",        team: 13,    price: "price_1LaXxLGAEvrTnitxOrNhsg38", PriceAmount: "18" },
            { id: "City Park Pursuit E II",     team: 14,    price: "price_1LaXxpGAEvrTnitxyB7xSL9x", PriceAmount: "18" },
            { id: "City Park Pursuit E III",    team: 15,    price: "price_1LaXyBGAEvrTnitxNroRx8l7", PriceAmount: "18" },
            { id: "City Park Pathways",         team: 16,    price: "price_1LaXPmGAEvrTnitxzdfxOuvS", PriceAmount: "18" },
            { id: "City Park Pathways II",      team: 17,    price: "price_1LaXQ6GAEvrTnitxABVO3BUJ", PriceAmount: "18" },
            { id: "City Park Pathways III",     team: 18,    price: "price_1LaXQZGAEvrTnitxQoqy9K6a", PriceAmount: "18" },
        ]
    },
    {
        Area: "Orem City Center Park",
        City: "Orem",
        Description: "All clues are within the Orem City Center Park, or a block away. Take on some difficult riddles, codes, formula puzzles, and numerical clues as you race around the park! NOTE: this hunt happens in the same location as 'City Park Pursuit'.",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/place/Orem+City+Center+Park/@40.2962597,-111.6902668,16.4z/data=!4m9!1m2!2m1!1sorem+city+park!3m5!1s0x874d9bc794c05515:0x90b210b7232d6fa3!8m2!3d40.2981259!4d-111.688092!15sCg5vcmVtIGNpdHkgcGFya5IBBHBhcms",
        Drive: "Walk",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "City Park Puzzles",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "UT",
        Teams: "3",
        Time: "75 min",
        Title: "City Park Puzzles",
        Winter: "No",
        // Prices
        priceList: [
            { id: "City Park Puzzles",          team: 1,     price: "price_1LaXT3GAEvrTnitxqzz73NO1", PriceAmount: "20" },
            { id: "City Park Puzzles II",       team: 2,     price: "price_1LaXTPGAEvrTnitxt4D5ema4", PriceAmount: "20" },
            { id: "City Park Puzzles III",      team: 3,     price: "price_1LaXTlGAEvrTnitxilSA1ecu", PriceAmount: "20" },
        ]
    },
    {
        Area: "Kiwanis Park",
        City: "Provo",
        Description: "Park at Kiwanis Park - all clues are within this park and will take you on a fun journey around as you solve through some beginner riddles!",
        Difficulty: "Kids",
        Directions: "https://www.google.com/maps/place/Kiwanis+Park/@40.2469371,-111.6419627,875m/data=!3m2!1e3!4b1!4m5!3m4!1s0x874d90c853a996c7:0xd0555ec0fe7f4aca!8m2!3d40.246933!4d-111.639774",
        Drive: "Walk",
        HuntCardCSS: "huntCardDivVeryEasy",
        id: "Kiwanis for Kids",
        InOrOut: "Outside",
        PriceAmount: "$10",
        State: "UT",
        Teams: "1",
        Time: "30 min",
        Title: "Kiwanis for Kids",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Kiwanis for Kids",          team: 1,     price: "price_1LaWOuGAEvrTnitxBypw358Y", PriceAmount: "10" },
        ]
    },
    {
        Area: "Paul Ream Park",
        City: "Provo",
        Description: "Park at Paul Ream Park; all clues are within the park or near the parking lot. Explore this beautiful park in a new and fun way!",
        Difficulty: "Kids",
        Directions: "https://www.google.com/maps/place/Paul+Ream+Wilderness+Park/@40.241439,-111.688295,17z/data=!3m1!4b1!4m5!3m4!1s0x874d9a170fa4c73b:0x422ef60609b27cad!8m2!3d40.241439!4d-111.6861063",
        Drive: "Walk",
        HuntCardCSS: "huntCardDivVeryEasy",
        id: "Race around the Ream",
        InOrOut: "Outside",
        PriceAmount: "$10",
        State: "UT",
        Teams: "1",
        Time: "30 min",
        Title: "Race around the Ream",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Race around the Ream",          team: 1,     price: "price_1LaWi2GAEvrTnitxsBcIF53j", PriceAmount: "10" },
        ]
    },
    {
        Area: "BYU Campus",
        City: "Provo",
        Description: "Park anywhere at BYU - there's a visitor lot by the Wilkinson Center. You will not leave the campus on this hunt. Wander around BYU Campus solving riddles which lead to various indoor and outdoor sites! Beware, some campus buildings may lock up around 9 PM.",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/Brigham+Young+University/@40.2481433,-111.6489022,1324m/data=!3m1!1e3!4m5!3m4!1s0x874d90bc4aa0b68d:0xbf3eb3a3f30fdc4c!8m2!3d40.2518435!4d-111.6493156",
        Drive: "Walk",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "BYU Campus Concern",
        InOrOut: "In & Out",
        PriceAmount: "$15",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "BYU Campus Concern",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "BYU Campus Concern",         team: 1,     price: "price_1LaVlBGAEvrTnitxClW4UzWP", PriceAmount: "15" },
            { id: "BYU Campus Concern II",      team: 2,     price: "price_1LaWIFGAEvrTnitxCQav4kDY", PriceAmount: "15" },
            { id: "BYU Campus Concern III",     team: 3,     price: "price_1LaWJAGAEvrTnitxYl6PTAaM", PriceAmount: "15" },
            { id: "BYU Campus Concern IV",      team: 4,     price: "price_1MbofqGAEvrTnitxCcJsTNOH", PriceAmount: "15" },
            { id: "BYU Campus Concern V",       team: 5,     price: "price_1Mbog5GAEvrTnitxhG8N2FOF", PriceAmount: "15" },
            { id: "BYU Campus Concern VI",      team: 6,     price: "price_1MbogGGAEvrTnitx48indwRa", PriceAmount: "15" },
        ]
    },
    {
        Area: "Kiwanis Park",
        City: "Provo",
        Description: "All clues are within Kiwanis Park - riddles, codes, and word games will lead you on a fun journey from location to location in this course!",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/Kiwanis+Park/@40.2469371,-111.6419627,875m/data=!3m2!1e3!4b1!4m5!3m4!1s0x874d90c853a996c7:0xd0555ec0fe7f4aca!8m2!3d40.246933!4d-111.639774",
        Drive: "Walk",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Kiwanis Klues",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "Kiwanis Klues",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Kiwanis Klues",         team: 1,     price: "price_1LaXz5GAEvrTnitxfrbiG94p", PriceAmount: "15" },
            { id: "Kiwanis Klues II",      team: 2,     price: "price_1LaXzQGAEvrTnitxDENIb8St", PriceAmount: "15" },
            { id: "Kiwanis Klues III",     team: 3,     price: "price_1LaXzkGAEvrTnitxMCnZdRTA", PriceAmount: "15" },
            { id: "Kiwanis Klues IV",      team: 4,     price: "price_1N6EIpGAEvrTnitxJbL6CBDh", PriceAmount: "15" },
            { id: "Kiwanis Klues V",       team: 5,     price: "price_1N6EJGGAEvrTnitxpAtHBk1y", PriceAmount: "15" },
            { id: "Kiwanis Klues VI",      team: 6,     price: "price_1N6EJeGAEvrTnitxxmzkNTA3", PriceAmount: "15" },
        ]
    },
    {
        Area: "Lions Park",
        City: "Provo",
        Description: "All clues are within Lions Park. See how quickly you can solve the riddles, codes, word games, and '15 puzzle' picture clues to complete the hunt!",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/Lions+Park/@40.2503271,-111.6783227,875m/data=!3m2!1e3!4b1!4m5!3m4!1s0x874d9a0d49cb518b:0x80e12cee72e7652e!8m2!3d40.250323!4d-111.676134",
        Drive: "Walk",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Loping with Lions",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "Loping with Lions",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Loping with Lions",          team: 1,     price: "price_1N494MGAEvrTnitxXvfGa8Qj", PriceAmount: "15" },
            { id: "Loping with Lions II",       team: 2,     price: "price_1N4946GAEvrTnitxs4J7JVui", PriceAmount: "15" },
            { id: "Loping with Lions III",      team: 3,     price: "price_1N493cGAEvrTnitxsPmj2eHs", PriceAmount: "15" },
            { id: "Loping with Lions IV",       team: 4,     price: "price_1N495uGAEvrTnitxy35MdkAI", PriceAmount: "15" },
            { id: "Loping with Lions V",        team: 5,     price: "price_1N4966GAEvrTnitxDRsd6pTf", PriceAmount: "15" },
            { id: "Loping with Lions VI",       team: 6,     price: "price_1N496JGAEvrTnitxQKTDD2Af", PriceAmount: "15" },
        ]
    },
    {
        Area: "Central Provo",
        City: "Provo",
        Description: "Gather your group at the location below to start the hunts all together; the first clue will lead each team to their first destination. You will need to visit multiple Provo parks to solve the riddles, codes, and word games in this hunt - bring a car. Each clue will lead you to a new park.",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/Provo,+UT/@40.2334136,-111.6583054,108m/data=!3m1!1e3!4m6!3m5!1s0x874d9271930bf1bf:0x1d90f12600b556ef!8m2!3d40.2338438!4d-111.6585337!16zL20vMGwzOWI?entry=ttu",
        Drive: "Drive",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Picnic in the Parks",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "Picnic in the Parks",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Picnic in the Parks",          team: 1,     price: "price_1OIJGpGAEvrTnitxcRv03VBw", PriceAmount: "15" },
            { id: "Picnic in the Parks II",       team: 2,     price: "price_1OIJLSGAEvrTnitx9v9uwoe1", PriceAmount: "15" },
            { id: "Picnic in the Parks III",      team: 3,     price: "price_1OIJL8GAEvrTnitx7UIBWfSS", PriceAmount: "15" },
            { id: "Picnic in the Parks IV",       team: 4,     price: "price_1OIJJrGAEvrTnitxI0gqgWQG", PriceAmount: "15" },
            { id: "Picnic in the Parks V",        team: 5,     price: "price_1OIJKRGAEvrTnitxhURDAL7x", PriceAmount: "15" },
            { id: "Picnic in the Parks VI",       team: 6,     price: "price_1OIJKmGAEvrTnitx8IKGnMGY", PriceAmount: "15" },
        ]
    },
    {
        Area: "Central Provo",
        City: "Provo",
        Description: "Park on Provo Center street near University Avenue. All clues are contained within 4 blocks North to South of Center Street and 5 blocks East to West of University Ave. Get ready for some directional, logic, numeical, and picture clues on this hunt around town!",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/3+N+University+Ave,+Provo,+UT+84601/@40.2348014,-111.6593377,17.81z/data=!4m15!1m8!3m7!1s0x874d9271930bf1bf:0x1d90f12600b556ef!2sProvo,+UT!3b1!8m2!3d40.2338438!4d-111.6585337!16zL20vMGwzOWI!3m5!1s0x874d975372080d8b:0x5b076fe47d8a8b2c!8m2!3d40.2339586!4d-111.65894!16s%2Fg%2F11h3g7mgjq?entry=ttu",
        Drive: "Walk",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Provo Path",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "Provo Path",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Provo Path",          team: 1,     price: "price_1PIx0XGAEvrTnitx4cfl0OeD", PriceAmount: "15" },
            { id: "Provo Path II",       team: 2,     price: "price_1PIx0rGAEvrTnitxtmk9Sn2W", PriceAmount: "15" },
            { id: "Provo Path III",      team: 3,     price: "price_1PIx10GAEvrTnitxTeIPO6oj", PriceAmount: "15" },
            { id: "Provo Path IV",       team: 4,     price: "price_1PIx1CGAEvrTnitxU9gPGOvA", PriceAmount: "15" },
            { id: "Provo Path V",        team: 5,     price: "price_1PIx1LGAEvrTnitxNc2UtRC3", PriceAmount: "15" },
            { id: "Provo Path VI",       team: 6,     price: "price_1PIx1UGAEvrTnitx1HoWirH5", PriceAmount: "15" },
        ]
    },
    {
        Area: "Provo Riverwoods",
        City: "Provo",
        Description: "Riddles, codes, number clues and more will lead you on this fun hunt - perfect for dates or friend groups. All clues are somewhere within the Provo Riverwoods, so get ready to explore!",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/search/provo+riverwoods/@40.2982043,-111.6616145,874m/data=!3m2!1e3!4b1",
        Drive: "Walk",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Roam the Riverwoods",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "Roam the Riverwoods",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Roam the Riverwoods",         team: 1,     price: "price_1LaW1yGAEvrTnitx93X0k7XX", PriceAmount: "15" },
            { id: "Roam the Riverwoods II",      team: 2,     price: "price_1N5brYGAEvrTnitxRzVjbmmr", PriceAmount: "15" },
            { id: "Roam the Riverwoods III",     team: 3,     price: "price_1N5briGAEvrTnitxebUsIjiV", PriceAmount: "15" },
            { id: "Roam the Riverwoods IV",      team: 4,     price: "price_1N5cR2GAEvrTnitxKqFEIHir", PriceAmount: "15" },
            { id: "Roam the Riverwoods V",       team: 5,     price: "price_1N5cRBGAEvrTnitxOqdBzcDy", PriceAmount: "15" },
            { id: "Roam the Riverwoods VI",      team: 6,     price: "price_1N5cROGAEvrTnitxyQVjpyia", PriceAmount: "15" },
        ]
    },
    {
        Area: "BYU Campus",
        City: "Provo",
        Description: "Park anywhere at BYU - there's a visitor lot by the Wilkinson Center. You will not leave the campus on this hunt. Wander around BYU Campus solving riddles, word puzzles, logic games, and a crossword which lead to various indoor and outdoor sites! Beware, some campus buildings may lock up around 9 PM.",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Ernest+L.+Wilkinson+Student+Center,+Provo,+UT+84604/@40.2483422,-111.6461246,292m/data=!3m1!1e3!4m6!3m5!1s0x874d90b9ee679dc3:0x1937fe0d8a468f80!8m2!3d40.2485515!4d-111.6472221!16s%2Fg%2F12hvhrcb3?entry=ttu",
        Drive: "Walk",
        Filter: "secondDate",
        FilterTwo: "largeHunt",
        HuntCardCSS: "huntCardDivMedium",
        id: "BYU Building Bolt",
        InOrOut: "In & Out",
        PriceAmount: "$18",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "BYU Building Bolt",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "BYU Building Bolt",         team: 1,     price: "price_1M2KGEGAEvrTnitxPmjrtBL2", PriceAmount: "18" },
            { id: "BYU Building Bolt II",      team: 2,     price: "price_1M2KGQGAEvrTnitxWJVY5uWo", PriceAmount: "18" },
            { id: "BYU Building Bolt III",     team: 3,     price: "price_1M2KGcGAEvrTnitxC1BL06NR", PriceAmount: "18" },
            { id: "BYU Building Bolt IV",      team: 4,     price: "price_1MboeiGAEvrTnitxP1NP4XIr", PriceAmount: "18" },
            { id: "BYU Building Bolt V",       team: 5,     price: "price_1Mbof8GAEvrTnitxfR0hUZMv", PriceAmount: "18" },
            { id: "BYU Building Bolt VI",      team: 6,     price: "price_1MbofSGAEvrTnitxDG4uUsyw", PriceAmount: "18" },
        ]
    },
    {
        Area: "BYU Campus",
        City: "Provo",
        Description: "Park anywhere at BYU - there's a visitor lot by the Wilkinson Center. You will not leave the campus on this hunt. Wander around BYU Campus solving riddles, a Chess question, a flag game, directional clues, and logic puzzles which lead to various indoor and outdoor sites! Also, this hunt is on the harder side of Medium hunts!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Ernest+L.+Wilkinson+Student+Center,+Provo,+UT+84604/@40.2483422,-111.6461246,292m/data=!3m1!1e3!4m6!3m5!1s0x874d90b9ee679dc3:0x1937fe0d8a468f80!8m2!3d40.2485515!4d-111.6472221!16s%2Fg%2F12hvhrcb3?entry=ttu",
        Drive: "Walk",
        Filter: "secondDate",
        FilterTwo: "largeHunt",
        HuntCardCSS: "huntCardDivMedium",
        id: "BYU Marvelous Maze",
        InOrOut: "In & Out",
        PriceAmount: "$18",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "BYU Marvelous Maze",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "BYU Marvelous Maze",         team: 1,     price: "price_1M2KGqGAEvrTnitxGa4pq0TC", PriceAmount: "18" },
            { id: "BYU Marvelous Maze II",      team: 2,     price: "price_1M2KH3GAEvrTnitxLqaJM5JH", PriceAmount: "18" },
            { id: "BYU Marvelous Maze III",     team: 3,     price: "price_1M2KHHGAEvrTnitx1xIIyEPJ", PriceAmount: "18" },
            { id: "BYU Marvelous Maze IV",      team: 4,     price: "price_1MbogeGAEvrTnitxdaxa5D04", PriceAmount: "18" },
            { id: "BYU Marvelous Maze V",       team: 5,     price: "price_1MbogtGAEvrTnitxQgxh8DcF", PriceAmount: "18" },
            { id: "BYU Marvelous Maze VI",      team: 6,     price: "price_1Mboh5GAEvrTnitxGDTvJvxN", PriceAmount: "18" },
        ]
    },
    {
        Area: "BYU Campus",
        City: "Provo",
        Description: "Park anywhere at BYU - there's a visitor lot by the Wilkinson Center. You will not leave the campus on this hunt. Wander around BYU Campus solving riddles, word games, codes, and logic puzzles which lead to various indoor and outdoor sites! Beware, some campus buildings may lock up around 9 PM.",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Ernest+L.+Wilkinson+Student+Center,+Provo,+UT+84604/@40.2483422,-111.6461246,292m/data=!3m1!1e3!4m6!3m5!1s0x874d90b9ee679dc3:0x1937fe0d8a468f80!8m2!3d40.2485515!4d-111.6472221!16s%2Fg%2F12hvhrcb3?entry=ttu",
        Drive: "Walk",
        Filter: "secondDate",
        FilterTwo: "largeHunt",
        HuntCardCSS: "huntCardDivMedium",
        id: "BYU on an Adventure",
        PriceAmount: "$18",
        State: "UT",
        Teams: "6",
        InOrOut: "In & Out",
        Time: "60 min",
        Title: "BYU on an Adventure",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "BYU on an Adventure",         team: 1,     price: "price_1MbohMGAEvrTnitxqfBgGQ5b", PriceAmount: "18" },
            { id: "BYU on an Adventure II",      team: 2,     price: "price_1MbohYGAEvrTnitx0XXUqpbD", PriceAmount: "18" },
            { id: "BYU on an Adventure III",     team: 3,     price: "price_1MbohoGAEvrTnitxt36Fzk95", PriceAmount: "18" },
            { id: "BYU on an Adventure IV",      team: 4,     price: "price_1Mboi2GAEvrTnitxOLnnKVLG", PriceAmount: "18" },
            { id: "BYU on an Adventure V",       team: 5,     price: "price_1MboiDGAEvrTnitxtcSeRkEG", PriceAmount: "18" },
            { id: "BYU on an Adventure VI",      team: 6,     price: "price_1MboiUGAEvrTnitx8sNITYiD", PriceAmount: "18" },
        ]
    },
    {
        Area: "Kiwanis Park",
        City: "Provo",
        Description: "All clues are within Kiwanis Park. Solve some numerical, orientational, letter/word, and riddle clues to complete your hunt around the park!" ,
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Kiwanis+Park/@40.2469371,-111.6419627,875m/data=!3m2!1e3!4b1!4m5!3m4!1s0x874d90c853a996c7:0xd0555ec0fe7f4aca!8m2!3d40.246933!4d-111.639774",
        Drive: "Walk",
        Filter: "largeHunt",
        FilterTwo: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Kiwanis Kuest",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "18",
        Time: "60 min",
        Title: "Kiwanis Kuest",
        Winter: "No",
        priceList: [
            { id: "Kiwanis Kuest",          team: 1,     price: "price_1LaWCNGAEvrTnitxh1TCGZcP", PriceAmount: "18" },
            { id: "Kiwanis Kuest II",       team: 2,     price: "price_1LaXEHGAEvrTnitxgDhvOcST", PriceAmount: "18" },
            { id: "Kiwanis Kuest III",      team: 3,     price: "price_1LaXEzGAEvrTnitxxDVg5V9g", PriceAmount: "18" },
            { id: "Kiwanis Kuest B",        team: 4,     price: "price_1LaWEjGAEvrTnitx8CBMrO9R", PriceAmount: "18" },
            { id: "Kiwanis Kuest B II",     team: 5,     price: "price_1LaXGSGAEvrTnitxyXqKlRZ1", PriceAmount: "18" },
            { id: "Kiwanis Kuest B III",    team: 6,     price: "price_1LaXGuGAEvrTnitxrrenxmaZ", PriceAmount: "18" },
            { id: "Kiwanis Kuest C",        team: 7,     price: "price_1LaWFMGAEvrTnitxwcPFfvIq", PriceAmount: "18" },
            { id: "Kiwanis Kuest C II",     team: 8,     price: "price_1LaXHHGAEvrTnitxpOm5uNjX", PriceAmount: "18" },
            { id: "Kiwanis Kuest C III",    team: 9,     price: "price_1LaXIWGAEvrTnitxNRqvZ5HD", PriceAmount: "18" },
            { id: "Kiwanis Kuest D",        team: 10,    price: "price_1LaXfQGAEvrTnitxT3DCbKic", PriceAmount: "18" },
            { id: "Kiwanis Kuest D II",     team: 11,    price: "price_1LaXg4GAEvrTnitxaOV26BdQ", PriceAmount: "18" },
            { id: "Kiwanis Kuest D III",    team: 12,    price: "price_1LaXgPGAEvrTnitxRmSFQ3xH", PriceAmount: "18" },
            { id: "Kiwanis Kuest E",        team: 13,    price: "price_1LaXguGAEvrTnitxSJDKQcYb", PriceAmount: "18" },
            { id: "Kiwanis Kuest E II",     team: 14,    price: "price_1LaXhKGAEvrTnitxmJbR1g2g", PriceAmount: "18" },
            { id: "Kiwanis Kuest E III",    team: 15,    price: "price_1LaXhqGAEvrTnitxvQKm1jwe", PriceAmount: "18" },
            { id: "Kiwanis Kuest F",        team: 16,    price: "price_1LaXjYGAEvrTnitxSerp0vMY", PriceAmount: "18" },
            { id: "Kiwanis Kuest F II",     team: 17,    price: "price_1LaXjzGAEvrTnitxAtX3rUJD", PriceAmount: "18" },
            { id: "Kiwanis Kuest F III",    team: 18,    price: "price_1LaXkNGAEvrTnitxQgXou0r9", PriceAmount: "18" },
        ]
    },
    {
        Area: "Pioneer Park",
        City: "Provo",
        Description: "Gather your group at the location below to start the hunts all together; the first clue will lead each team to their first destination. You will drive to various locations all over central Provo - don't go down near East Bay or north of the football stadium. This hunt will be a bit of a challenge with riddles, ciphers, word games, and logic puzzles leading you from location to location!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Pioneer+Park/@40.2329379,-111.6691613,280m/data=!3m1!1e3!4m6!3m5!1s0x874d99ff9a68ad97:0x6ada80cef3ad9652!8m2!3d40.233059!4d-111.668294!16s%2Fg%2F1tf3pcp3?entry=ttu",
        Drive: "Drive",
        Filter: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Radical Riddles",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "3",
        Time: "75 min",
        Title: "Radical Riddles",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Radical Riddles",          team: 1,     price: "price_1Nj4IAGAEvrTnitxSguO8kZK", PriceAmount: "18" },
            { id: "Radical Riddles II",       team: 2,     price: "price_1Nj4HpGAEvrTnitxlUMxfu2q", PriceAmount: "18" },
            { id: "Radical Riddles III",      team: 3,     price: "price_1Nj4HKGAEvrTnitxMrTXLoMw", PriceAmount: "18" },
        ]
    },
    {
        Area: "Rock Canyon Park",
        City: "Provo",
        Description: "All clues are contained within Rock Canyon Park, but that doesn't mean this is a 'walk in the park'. You'll need to solve riddles, codes, word games, and number manipulation clues to complete this hunt!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Rock+Canyon+Park/@40.2671977,-111.6378229,875m/data=!3m2!1e3!4b1!4m5!3m4!1s0x874d90ee61c72063:0x4f0b015210590cd8!8m2!3d40.2671936!4d-111.6356342",
        Drive: "Walk",
        Filter: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Rock Canyon Rove",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "4",
        Time: "60 min",
        Title: "Rock Canyon Rove",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Rock Canyon Rove",       team: 1,     price: "price_1LaW8NGAEvrTnitx6HEW26DV", PriceAmount: "18" },
            { id: "Rock Canyon Rove II",    team: 2,     price: "price_1N61ymGAEvrTnitxr23a48OD", PriceAmount: "18" },
            { id: "Rock Canyon Rove III",   team: 3,     price: "price_1N61ukGAEvrTnitxlUUDvQ3N", PriceAmount: "18" },
            { id: "Rock Canyon Rove IV",    team: 4,     price: "price_1N61v9GAEvrTnitxpKALayIA", PriceAmount: "18" },
        ]
    },
    {
        Area: "Central Provo",
        City: "Provo",
        Description: "Park near Provo Center Street and University Avenue. You can walk, drive, or take scooters on this hunt. You will stay within 4 blocks north to south of Provo Center Street and 6 blocks east to west from University Avenue. Be ready to tackle riddles, word games, codes, logic puzzles and more on the journey around town!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/@40.2337049,-111.658501,198m/data=!3m1!1e3?entry=ttu",
        Drive: "Walk",
        Filter: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Statue Search",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "6",
        Time: "60 min",
        Title: "Statue Search",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Statue Search",          team: 1,     price: "price_1PBjZtGAEvrTnitxeF2kJyDO", PriceAmount: "18" },
            { id: "Statue Search II",       team: 2,     price: "price_1PBjZEGAEvrTnitxDGdXWk3M", PriceAmount: "18" },
            { id: "Statue Search III",      team: 3,     price: "price_1PBjVGGAEvrTnitxN1Tgj2PZ", PriceAmount: "18" },
            { id: "Statue Search IV",       team: 4,     price: "price_1PBjagGAEvrTnitxP9k0YoI2", PriceAmount: "18" },
            { id: "Statue Search V",        team: 5,     price: "price_1PBjawGAEvrTnitxkBfLZFsl", PriceAmount: "18" },
            { id: "Statue Search VI",       team: 6,     price: "price_1PBjbBGAEvrTnitxyJzW0EEv", PriceAmount: "18" },
        ]
    },
    {
        Area: "Central Provo",
        City: "Provo",
        Description: "Park near Provo Center Street and University Avenue. You can walk, drive, or take scooters on this hunt. You will stay within 4 blocks north to south of Provo Center Street and 6 blocks east to west from University Avenue. This hunt contains some challenging riddles, logic puzzles, math games, and more which take you from destination to destination as you are able to solve them!",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/@40.2337049,-111.658501,198m/data=!3m1!1e3?entry=ttu",
        Drive: "Walk",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "Close to the Center",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "UT",
        Teams: "3",
        Time: "90 min",
        Title: "Close to the Center",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Close to the Center",          team: 1,     price: "price_1LaVq6GAEvrTnitxSGBVxgy8", PriceAmount: "20" },
            { id: "Close to the Center II",       team: 2,     price: "price_1LaWJkGAEvrTnitxsezYuia0", PriceAmount: "20" },
            { id: "Close to the Center III",      team: 3,     price: "price_1LaWKmGAEvrTnitxPRtPl3q1", PriceAmount: "20" },
        ]
    },
    {
        Area: "Kiwanis Park",
        City: "Provo",
        Description: "All clues are will keep you in Kiwanis Park... unless a clue explicitly states to wander elsewhere real quick. This hunt is a doozy with intense word games, number manipulation clues, codes, and orientational puzzles!",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/place/Kiwanis+Park/@40.2469371,-111.6419627,875m/data=!3m2!1e3!4b1!4m5!3m4!1s0x874d90c853a996c7:0xd0555ec0fe7f4aca!8m2!3d40.246933!4d-111.639774",
        Drive: "Walk",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "Kiwanis Kampaign",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "UT",
        Teams: "3",
        Time: "90 min",
        Title: "Kiwanis Kampaign",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Kiwanis Kampaign",          team: 1,     price: "price_1LaWPaGAEvrTnitxRVE8lyIN", PriceAmount: "20" },
            { id: "Kiwanis Kampaign II",       team: 2,     price: "price_1LaWQsGAEvrTnitxumwaMVld", PriceAmount: "20" },
            { id: "Kiwanis Kampaign III",      team: 3,     price: "price_1LaWRMGAEvrTnitx8SrwBZEE", PriceAmount: "20" },
        ]
    },
    {
        Area: "Rock Canyon Park",
        City: "Provo",
        Description: "All clues are within Rock Canyon Park, but you'll need to overcome riddles, ciphers, Morse codes, and word games to complete the hidden course in this park!",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/place/Rock+Canyon+Park/@40.2671977,-111.6378229,875m/data=!3m2!1e3!4b1!4m5!3m4!1s0x874d90ee61c72063:0x4f0b015210590cd8!8m2!3d40.2671936!4d-111.6356342",
        Drive: "Walk",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "Rock Canyon Race",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "UT",
        Teams: "1",
        Time: "90 min",
        Title: "Rock Canyon Race",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Rock Canyon Race",          team: 1,     price: "price_1N5cy1GAEvrTnitxZsGbVW6s", PriceAmount: "20" },
        ]
    },
    {
        Area: "Draper Library & City Park",
        City: "Draper",
        Description: "Take on riddles, matching games, logic puzzles, and even a chess clue on the hunt around Draper City Park! You'll need to go into the library for some of this hunt, so check the Draper library hours.",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/Draper+Library/@40.5238886,-111.85877,337m/data=!3m1!1e3!4m6!3m5!1s0x8752875fa333ee11:0x88ef4043e86e4edb!8m2!3d40.5242963!4d-111.8583064!16s%2Fg%2F1tdfcl58?entry=ttu",
        Drive: "Walk",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Draper Dash",
        InOrOut: "In & Out",
        PriceAmount: "$15",
        State: "UT",
        Teams: "6",
        Time: "45 min",
        Title: "Draper Dash",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Draper Dash",          team: 1,     price: "price_1O4S7kGAEvrTnitxN6ntBlGV", PriceAmount: "15" },
            { id: "Draper Dash II",       team: 2,     price: "price_1O4S82GAEvrTnitxYXZkUcGr", PriceAmount: "15" },
            { id: "Draper Dash III",      team: 3,     price: "price_1O4S8IGAEvrTnitxZw5nFAgc", PriceAmount: "15" },
            { id: "Draper Dash IV",       team: 4,     price: "price_1O4S8VGAEvrTnitxsymeF2k9", PriceAmount: "15" },
            { id: "Draper Dash V",        team: 5,     price: "price_1O4S8iGAEvrTnitxxcLt5quy", PriceAmount: "15" },
            { id: "Draper Dash VI",       team: 6,     price: "price_1O4S8xGAEvrTnitxj133ZM5I", PriceAmount: "15" },
        ]
    },
    {
        Area: "Whisperwood Neighborhood Park",
        City: "Draper",
        Description: "Gather your group at the location below to start the hunts all together; the first clue will lead each team to their first destination. You will explore a few places in Draper as you follow these tricky riddles, ciphers, rebus puzzles, math problems, and word games around!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/@40.518592,-111.8708075,52m/data=!3m1!1e3?entry=ttu",
        Drive: "Drive",
        Filter: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Draper Drive",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "6",
        Time: "75 min",
        Title: "Draper Drive",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Draper Drive",          team: 1,     price: "price_1NQwPoGAEvrTnitxBJGIDSft", PriceAmount: "18" },
            { id: "Draper Drive II",       team: 2,     price: "price_1NQwPzGAEvrTnitxw8lKIqXv", PriceAmount: "18" },
            { id: "Draper Drive III",      team: 3,     price: "price_1NQwQAGAEvrTnitx1uomkoxL", PriceAmount: "18" },
            { id: "Draper Drive IV",       team: 4,     price: "price_1NQwQLGAEvrTnitxKDFdAtNZ", PriceAmount: "18" },
            { id: "Draper Drive V",        team: 5,     price: "price_1NQwQVGAEvrTnitxLKwVkiTD", PriceAmount: "18" },
            { id: "Draper Drive VI",       team: 6,     price: "price_1NQwQjGAEvrTnitxSE0n4qAm", PriceAmount: "18" },
        ]
    },
    {
        Area: "Gardner Village",
        City: "West Jordan",
        Description: "All clues are contained within Garner Village, so no driving is necessary. See how fast you can solve the riddles, Rebus puzzles, and codes to complete your journey throughout the village!",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/Gardner+Village/@40.6093286,-111.9254068,17z/data=!3m1!4b1!4m6!3m5!1s0x875288e19a0e5acf:0xaf799c2b6d1cc153!8m2!3d40.6093286!4d-111.9228319!16s%2Fm%2F06_d0b7?entry=ttu",
        Drive: "Walk",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Village Visit",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "UT",
        Teams: "6",
        Time: "45 min",
        Title: "Village Visit",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Village Visit",          team: 1,     price: "price_1NMIGMGAEvrTnitxcqtlZ5bc", PriceAmount: "15" },
            { id: "Village Visit II",       team: 2,     price: "price_1NMIGZGAEvrTnitxdKjXXDWD", PriceAmount: "15" },
            { id: "Village Visit III",      team: 3,     price: "price_1NMIGjGAEvrTnitxkNK4KQXv", PriceAmount: "15" },
            { id: "Village Visit IV",       team: 4,     price: "price_1NMIGyGAEvrTnitxDIgVu68E", PriceAmount: "15" },
            { id: "Village Visit V",        team: 5,     price: "price_1NMIH8GAEvrTnitxJLbpA06a", PriceAmount: "15" },
            { id: "Village Visit VI",       team: 6,     price: "price_1NMIHMGAEvrTnitxA89S50mN", PriceAmount: "15" },
        ]
    },
    {
        Area: "Midvale City Park",
        City: "Midvale",
        Description: "Gather your group at the location below to start the hunts all together; the first clue will lead each team to their first destination. Explore a few places in Midvale and on the border of South Jordan/Midvale as you follow these tricky riddles, word puzzles and Rebus images around!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/@40.6114078,-111.9023494,143m/data=!3m1!1e3?entry=ttu",
        Drive: "Drive",
        Filter: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Midvale Madness",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "UT",
        Teams: "6",
        Time: "75 min",
        Title: "Midvale Madness",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Midvale Madness",          team: 1,     price: "price_1NOpuOGAEvrTnitxSIM0x6zj", PriceAmount: "18" },
            { id: "Midvale Madness II",       team: 2,     price: "price_1NOpuZGAEvrTnitxIOZOZDb2", PriceAmount: "18" },
            { id: "Midvale Madness III",      team: 3,     price: "price_1NOpulGAEvrTnitxlPzQlyi4", PriceAmount: "18" },
            { id: "Midvale Madness IV",       team: 4,     price: "price_1NOpuxGAEvrTnitxOyblpn1A", PriceAmount: "18" },
            { id: "Midvale Madness V",        team: 5,     price: "price_1NOpv9GAEvrTnitxbj2rUROj", PriceAmount: "18" },
            { id: "Midvale Madness VI",       team: 6,     price: "price_1NOpvKGAEvrTnitxMErDlIkH", PriceAmount: "18" },
        ]
    },
// ARKANSAS HUNTS
    {
        Area: "Bentonville Square",
        City: "Bentonville",
        Description: "Wander central Bentonville being led by series of riddles, word games, codes, and other clues! All clues are within a 4 block radius.",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/@36.3727926,-94.208832,18.71z",
        Drive: "Walk",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Logical Leaps",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "AR",
        Teams: "6",
        Time: "60 min",
        Title: "Logical Leaps",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Logical Leaps",          team: 1,     price: "price_1Nj7yMGAEvrTnitxQWRUUSTo", PriceAmount: "15" },
            { id: "Logical Leaps II",       team: 2,     price: "price_1Nj7ymGAEvrTnitxLLWmBySM", PriceAmount: "15" },
            { id: "Logical Leaps III",      team: 3,     price: "price_1Nj7z4GAEvrTnitxiywXidaV", PriceAmount: "15" },
            { id: "Logical Leaps IV",       team: 4,     price: "price_1Nj80TGAEvrTnitxsQ4tzJCO", PriceAmount: "15" },
            { id: "Logical Leaps V",        team: 5,     price: "price_1Nj80gGAEvrTnitxS2kUGC2I", PriceAmount: "15" },
            { id: "Logical Leaps VI",       team: 6,     price: "price_1Nj81NGAEvrTnitxfLIV8f3V", PriceAmount: "15" },
        ]      
    },
    {
        Area: "Orchards Park",
        City: "Bentonville",
        Description: "Race around Orchards Park solving picture, riddle, word, and number puzzles. Race up to 9 teams at the same time in this hunt!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/36%C2%B022'38.4%22N+94%C2%B011'38.1%22W/@36.3773483,-94.1960987,593m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0xe909fd00217a76cf!7e2!8m2!3d36.377344!4d-94.1939098",
        Drive: "Walk",
        Filter: "largeHunt",
        FilterTwo: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Out in the Orchards",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "AR",
        Teams: "9",
        Time: "75 min",
        Title: "Out in the Orchards",
        Winter: "No",
        priceList: [
            { id: "Out in the Orchards",            team: 1,     price: "price_1LcxPTGAEvrTnitxndbPOFKq", PriceAmount: "18" },
            { id: "Out in the Orchards II",         team: 2,     price: "price_1LcxPiGAEvrTnitxcMGBm13X", PriceAmount: "18" },
            { id: "Out in the Orchards III",        team: 3,     price: "price_1LcxQ6GAEvrTnitxQlXbMSg9", PriceAmount: "18" },
            { id: "Out in the Orchards B",          team: 4,     price: "price_1LcxQNGAEvrTnitxviE2U6JR", PriceAmount: "18" },
            { id: "Out in the Orchards B II",       team: 5,     price: "price_1LcxQgGAEvrTnitxCxIBsIHc", PriceAmount: "18" },
            { id: "Out in the Orchards B III",      team: 6,     price: "price_1LcxQxGAEvrTnitxoOsCMyF1", PriceAmount: "18" },
            { id: "Out in the Orchards C",          team: 7,     price: "price_1LcxRFGAEvrTnitxz4vpHOWq", PriceAmount: "18" },
            { id: "Out in the Orchards C II",       team: 8,     price: "price_1LcxRRGAEvrTnitxB0pnHWcR", PriceAmount: "18" },
            { id: "Out in the Orchards C III",      team: 9,     price: "price_1LcxRfGAEvrTnitx4SYbaOq9", PriceAmount: "18" },
        ]
    },
    {
        Area: "Bentonville Square",
        City: "Bentonville",
        Description: "Wander the area around Bentonville Square being led by a series of riddles, word games, codes, rebus puzzles and more! All clues are within 4 blocks in any direction from the square.",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/@36.3727926,-94.208832,18.71z",
        Drive: "Walk",
        Filter: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Scout the Square",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "AR",
        Teams: "9",
        Time: "75 min",
        Title: "Scout the Square",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Scout the Square",          team: 1,     price: "price_1NW7wDGAEvrTnitxtPtb9ncg", PriceAmount: "18" },
            { id: "Scout the Square II",       team: 2,     price: "price_1NW7wNGAEvrTnitxbyfzQuvT", PriceAmount: "18" },
            { id: "Scout the Square III",      team: 3,     price: "price_1NW7wYGAEvrTnitxs5vr1xDr", PriceAmount: "18" },
            { id: "Scout the Square IV",       team: 4,     price: "price_1NW7wjGAEvrTnitx9d4wpEuO", PriceAmount: "18" },
            { id: "Scout the Square V",        team: 5,     price: "price_1NW7wuGAEvrTnitxvUyPJ4ma", PriceAmount: "18" },
            { id: "Scout the Square VI",       team: 6,     price: "price_1NW7xPGAEvrTnitxj5Y8KINw", PriceAmount: "18" },
            { id: "Scout the Square VII",      team: 7,     price: "price_1NW7xcGAEvrTnitx1fSj2pGA", PriceAmount: "18" },
            { id: "Scout the Square VIII",     team: 8,     price: "price_1NW7xrGAEvrTnitxYmHV4skR", PriceAmount: "18" },
            { id: "Scout the Square IX",       team: 9,     price: "price_1NW7y7GAEvrTnitxSPMkD3P1", PriceAmount: "18" },
        ]
    },
    {
        Area: "Bentonville Square",
        City: "Bentonville",
        Description: "Wander central Bentonville being led by a series of riddles, word games, codes, technical clues and more! All clues are within 4 blocks in any direction from the square.",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/@36.3727926,-94.208832,18.71z",
        Drive: "Walk",
        Filter: "largeHunt",
        FilterTwo: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Search at the Square",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "AR",
        Teams: "9",
        Time: "75 min",
        Title: "Search at the Square",
        Winter: "Yes",
        // Prices
        priceList: [
            { id: "Search at the Square",          team: 1,     price: "price_1LaXN1GAEvrTnitxuydM9OKM", PriceAmount: "18" },
            { id: "Search at the Square II",       team: 2,     price: "price_1LaXNZGAEvrTnitxOGfdGzc7", PriceAmount: "18" },
            { id: "Search at the Square III",      team: 3,     price: "price_1LaXNxGAEvrTnitx8PNl8KuU", PriceAmount: "18" },
            { id: "Search at the Square IV",       team: 4,     price: "price_1NW7lAGAEvrTnitxottzBvAg", PriceAmount: "18" },
            { id: "Search at the Square V",        team: 5,     price: "price_1NW7lQGAEvrTnitxNsQGsUBr", PriceAmount: "18" },
            { id: "Search at the Square VI",       team: 6,     price: "price_1NW7ldGAEvrTnitxwRfhGk3E", PriceAmount: "18" },
            { id: "Search at the Square VII",      team: 7,     price: "price_1NW7m0GAEvrTnitxVYFsW9En", PriceAmount: "18" },
            { id: "Search at the Square VIII",     team: 8,     price: "price_1NW7mEGAEvrTnitxTzPAKwPX", PriceAmount: "18" },
            { id: "Search at the Square IX",       team: 9,     price: "price_1NW7mSGAEvrTnitxe7t6rCHk", PriceAmount: "18" },
        ]
    },
    {
        Area: "Bentonville Square",
        City: "Bentonville",
        Description: "Wander central Bentonville being led by series of riddles, word games, number puzzles, language clues, codes, and more! All clues are within 4 blocks in any direction from the square.",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/@36.3727926,-94.208832,18.71z",
        Drive: "Walk",
        Filter: "largeHunt",
        FilterTwo: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Sights at the Square",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "AR",
        Teams: "9",
        Time: "75 min",
        Title: "Sights at the Square",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Sights at the Square",          team: 1,     price: "price_1LaXJ5GAEvrTnitxLxgG0kV4", PriceAmount: "18" },
            { id: "Sights at the Square II",       team: 2,     price: "price_1LaXJeGAEvrTnitxM6b2FDX0", PriceAmount: "18" },
            { id: "Sights at the Square III",      team: 3,     price: "price_1LaXK2GAEvrTnitxRuSJUQGY", PriceAmount: "18" },
            { id: "Sights at the Square IV",       team: 4,     price: "price_1NW7qnGAEvrTnitxykZOIGGo", PriceAmount: "18" },
            { id: "Sights at the Square V",        team: 5,     price: "price_1NW7r3GAEvrTnitx2C7bI0Xm", PriceAmount: "18" },
            { id: "Sights at the Square VI",       team: 6,     price: "price_1NW7rNGAEvrTnitxflb3eQ5q", PriceAmount: "18" },
            { id: "Sights at the Square VII",      team: 7,     price: "price_1NW7rfGAEvrTnitxQwBZxQr8", PriceAmount: "18" },
            { id: "Sights at the Square VIII",     team: 8,     price: "price_1NW7rrGAEvrTnitxciOi2Jn4", PriceAmount: "18" },
            { id: "Sights at the Square IX",       team: 9,     price: "price_1NW7s5GAEvrTnitx0tWKBzWD", PriceAmount: "18" },
        ]
    },
    {
        Area: "Crystal Bridges",
        City: "Bentonville",
        Description: "Race around Crystal Bridges to see if you can discover the hunt's path through the woods. You'll face word, directional, numeric, and encoded clues along this challenging hunt!",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/place/Crystal+Bridges+Museum+of+American+Art/@36.3826736,-94.211286,15z/data=!3m1!4b1!4m5!3m4!1s0x87c91a14c433925b:0xad260359e45b143b!8m2!3d36.3818542!4d-94.2026525",
        Drive: "Walk",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "Crystal Clear",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "AR",
        Teams: "3",
        Time: "90 min",
        Title: "Crystal Clear",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Crystal Clear",          team: 1,     price: "price_1LaXlHGAEvrTnitxAa0H7zjw", PriceAmount: "20" },
            { id: "Crystal Clear II",       team: 2,     price: "price_1LaXliGAEvrTnitxOi9t7Pa0", PriceAmount: "20" },
            { id: "Crystal Clear III",      team: 3,     price: "price_1LaXm3GAEvrTnitxaBFBu6qx", PriceAmount: "20" },
        ]
    },
    {
        Area: "Bentonville Square",
        City: "Bentonville",
        Description: "Wander central Bentonville being led by series of riddles, word games, crosswords, number puzzles, codes, and other clues! All clues are within a 4 block radius.",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/@36.3727926,-94.208832,18.71z",
        Drive: "Walk",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "Get a Clue",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "AR",
        Teams: "3",
        Time: "90 min",
        Title: "Get a Clue",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Get a Clue",          team: 1,     price: "price_1LxZsgGAEvrTnitxm0xK9eK2", PriceAmount: "20" },
            { id: "Get a Clue II",       team: 2,     price: "price_1LxZsrGAEvrTnitxr5BYW8DT", PriceAmount: "20" },
            { id: "Get a Clue III",      team: 3,     price: "price_1LxZt9GAEvrTnitx5gbH3jJh", PriceAmount: "20" },
        ]
    },
    {
        Area: "Memorial Park",
        City: "Bentonville",
        Description: "Race around the east side of Memorial Park with up to 6 teams at the same time. You'll take on logic puzzles, codes, riddles, technical clues and more in this exciting hunt!",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/place/36%C2%B022'36.8%22N+94%C2%B011'13.6%22W/@36.3768819,-94.1891032,593m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0x4f69f86d456bf07c!7e2!8m2!3d36.3768784!4d-94.1871136",
        Drive: "Walk",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "Memorial Maze",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "AR",
        Teams: "6",
        Time: "75 min",
        Title: "Memorial Maze",
        Winter: "No",
        priceList: [
            { id: "Memorial Maze",          team: 1,     price: "price_1LcxVAGAEvrTnitxNWdaMMHN", PriceAmount: "20" },
            { id: "Memorial Maze II",       team: 2,     price: "price_1LcxVRGAEvrTnitxRK7Vacwa", PriceAmount: "20" },
            { id: "Memorial Maze III",      team: 3,     price: "price_1LcxVnGAEvrTnitxFwOcNLB9", PriceAmount: "20" },
            { id: "Memorial Maze B",        team: 4,     price: "price_1LcxW9GAEvrTnitxlwRkVyvp", PriceAmount: "20" },
            { id: "Memorial Maze B II",     team: 5,     price: "price_1LcxWNGAEvrTnitxKcJY8vgs", PriceAmount: "20" },
            { id: "Memorial Maze B III",    team: 6,     price: "price_1LcxWnGAEvrTnitxK6nd3mtA", PriceAmount: "20" },
        ]
    },
    {
        Area: "Bentonville Square",
        City: "Bentonville",
        Description: "Wander central Bentonville being led by series of intense word games, math puzzles, logic clues and riddles! All clues are within a 4 block radius.",
        Difficulty: "Hard",
        Directions: "https://www.google.com/maps/@36.3727926,-94.208832,18.71z",
        Drive: "Walk",
        Filter: "Hard",
        HuntCardCSS: "huntCardDivHard",
        id: "Puzzle Pursuit",
        InOrOut: "Outside",
        PriceAmount: "$20",
        State: "AR",
        Teams: "3",
        Time: "90 min",
        Title: "Puzzle Pursuit",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Puzzle Pursuit",          team: 1,     price: "price_1LxZtSGAEvrTnitxyYJLM5NS", PriceAmount: "20" },
            { id: "Puzzle Pursuit II",       team: 2,     price: "price_1LxZtfGAEvrTnitxDs6Mql0R", PriceAmount: "20" },
            { id: "Puzzle Pursuit III",      team: 3,     price: "price_1LxZtuGAEvrTnitxgIYNml1B", PriceAmount: "20" },
        ]
    },
    {
        Area: "Lake Atalanta",
        City: "Rogers",
        Description: "Wander areas that are somewhere around Atalanta solving easier clues. These hunts are great for mid-teens and older.",
        Difficulty: "Easy",
        Directions: "https://www.google.com/maps/place/Lake+Atalanta+Park+%26+Trails/@36.3364968,-94.1047317,16z/data=!4m5!3m4!1s0x87c917af14351463:0xbaa9ebc277ff0a14!8m2!3d36.3321145!4d-94.1038864",
        Drive: "Walk",
        Filter: "firstDate",
        HuntCardCSS: "huntCardDivEasy",
        id: "Around Atalanta",
        InOrOut: "Outside",
        PriceAmount: "$15",
        State: "AR",
        Teams: "3",
        Time: "45 min",
        Title: "Around Atalanta",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Around Atalanta",          team: 1,     price: "price_1LasORGAEvrTnitxUvOQYKWI", PriceAmount: "15" },
            { id: "Around Atalanta II",       team: 2,     price: "price_1LasOeGAEvrTnitxh2uvRqUM", PriceAmount: "15" },
            { id: "Around Atalanta III",      team: 3,     price: "price_1LasOxGAEvrTnitxrOsVAkig", PriceAmount: "15" },
        ]
    },
    {
        Area: "Lake Atalanta",
        City: "Rogers",
        Description: "Wander areas that are somewhere around Lake Atalanta. These hunts are great for larger groups since up to 18 teams can race in similar hunts at the same time. Teams will take on classic number puzzles, riddles, word games, and fun orienteering courses on this race around the park!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/place/Lake+Atalanta+Park+%26+Trails/@36.3364968,-94.1047317,16z/data=!4m5!3m4!1s0x87c917af14351463:0xbaa9ebc277ff0a14!8m2!3d36.3321145!4d-94.1038864",
        Drive: "Walk",
        Filter: "largeHunt",
        FilterTwo: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Atalanta Adventure",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "AR",
        Teams: "18",
        Time: "60 min",
        Title: "Atalanta Adventure",
        Winter: "No",
        priceList: [
            { id: "Atalanta Adventure",          team: 1,     price: "price_1LctmMGAEvrTnitxuwXNnYf0", PriceAmount: "18" },
            { id: "Atalanta Adventure II",       team: 2,     price: "price_1LctmbGAEvrTnitxEyjs8FYQ", PriceAmount: "18" },
            { id: "Atalanta Adventure III",      team: 3,     price: "price_1LctmpGAEvrTnitxsWMuP6Xy", PriceAmount: "18" },
            { id: "Atalanta Adventure B",        team: 4,     price: "price_1LctsNGAEvrTnitxqC0oSmRl", PriceAmount: "18" },
            { id: "Atalanta Adventure B II",     team: 5,     price: "price_1LctsbGAEvrTnitx6mHqUnnb", PriceAmount: "18" },
            { id: "Atalanta Adventure B III",    team: 6,     price: "price_1LctsrGAEvrTnitxI3cbwD11", PriceAmount: "18" },
            { id: "Atalanta Adventure C",        team: 7,     price: "price_1Lctt5GAEvrTnitxigLGPdrk", PriceAmount: "18" },
            { id: "Atalanta Adventure C II",     team: 8,     price: "price_1LcttHGAEvrTnitxfyuIqWlS", PriceAmount: "18" },
            { id: "Atalanta Adventure C III",    team: 9,     price: "price_1LcttUGAEvrTnitxa9FSb4in", PriceAmount: "18" },
            { id: "Atalanta Adventure D",        team: 10,    price: "price_1LcttzGAEvrTnitx0KIWjc35", PriceAmount: "18" },
            { id: "Atalanta Adventure D II",     team: 11,    price: "price_1LctuLGAEvrTnitxvE3XityO", PriceAmount: "18" },
            { id: "Atalanta Adventure D III",    team: 12,    price: "price_1LctuYGAEvrTnitx46KkXuSM", PriceAmount: "18" },
            { id: "Atalanta Adventure E",        team: 13,    price: "price_1LctumGAEvrTnitxGo9c1ulb", PriceAmount: "18" },
            { id: "Atalanta Adventure E II",     team: 14,    price: "price_1Lctv8GAEvrTnitxzllVKtXL", PriceAmount: "18" },
            { id: "Atalanta Adventure E III",    team: 15,    price: "price_1LctvOGAEvrTnitxmjz2Bh91", PriceAmount: "18" },
            { id: "Atalanta Adventure F",        team: 16,    price: "price_1LctvaGAEvrTnitxnTAyqknT", PriceAmount: "18" },
            { id: "Atalanta Adventure F II",     team: 17,    price: "price_1LctvlGAEvrTnitxBnzBJrXk", PriceAmount: "18" },
            { id: "Atalanta Adventure F III",    team: 18,    price: "price_1Lctw2GAEvrTnitxvnwxgQwu", PriceAmount: "18" },
        ]
    },
    {
        Area: "Downtown Rogers",
        City: "Rogers",
        Description: "Wander downtown Rogers and explore some of the fun, unique areas of the town! All clues are within a 4 block radius. You'll face fun logic puzzles, riddles, number manipulation clues, and codes on this hunt around!",
        Difficulty: "Medium",
        Directions: "https://www.google.com/maps/@36.3331885,-94.1182533,18.83z",
        Drive: "Walk",
        Filter: "secondDate",
        HuntCardCSS: "huntCardDivMedium",
        id: "Historic Hike",
        InOrOut: "Outside",
        PriceAmount: "$18",
        State: "AR",
        Teams: "3",
        Time: "60 min",
        Title: "Historic Hike",
        Winter: "No",
        // Prices
        priceList: [
            { id: "Historic Hike",      team: 1,    price: "price_1LasPDGAEvrTnitxxdhwDrmf", PriceAmount: "18" },
            { id: "Historic Hike II",   team: 2,    price: "price_1LasPPGAEvrTnitx9SVAX2oY", PriceAmount: "18" },
            { id: "Historic Hike III",  team: 3,    price: "price_1LasPdGAEvrTnitxkDkpfYrq", PriceAmount: "18" }
        ]
    }

 ]

 export { huntArray };