import React, {useState} from "react";
import { Sudoku } from "../ClueSudoku";

export function SudokuData(props) {
    const [sudokuArrayData, setSudokuArrayData] = useState([]);
    const [clueBegan, setClueBegan] = useState(false);

    const handleLoadPuzzle = () => {
        setClueBegan(true)
        if (props.ClueS === "AtalantaAdventure") {
            setSudokuArrayData(
                [[8,9,5,-2,-1,-2,7,1,2],
                [4,-2,1,5,-1,7,9,6,8],
                [6,7,2,9,-1,1,4,5,-2],
                [5,8,9,-2,-2,2,3,4,7],
                [7,6,-1,-1,-2,4,1,-1,-1],
                [2,1,-1,-2,-1,5,8,9,6],
                [3,2,7,6,1,9,5,8,4],
                [9,4,8,2,5,3,6,7,1],
                [1,5,6,4,7,8,2,3,9]])
                // 36331697
        } else if (props.ClueS === "AtalantaAdventureB") {
            setSudokuArrayData(
                [[8,9,5,-2,-1,-2,7,1,2],
                [4,-2,1,5,-1,7,9,6,8],
                [6,7,2,9,-1,1,4,5,-2],
                [5,8,9,-1,-1,-2,3,4,7],
                [7,6,-1,-2,-1,-1,1,2,-1],
                [2,1,4,-1,-1,5,8,9,6],
                [-2,-2,7,6,1,9,5,8,4],
                [9,4,8,2,5,3,6,7,-1],
                [1,5,6,4,7,8,2,3,9]])
                // 36332832
        } else if (props.ClueS === "AtalantaAdventureC") {
            setSudokuArrayData(
                [[8,9,5,-2,-1,-2,7,1,2],
                [4,-2,1,5,-1,7,9,6,8],
                [6,7,2,9,-1,1,4,5,-2],
                [5,8,9,-1,-1,-1,3,4,7],
                [7,6,-2,-2,-1,-1,1,2,-1],
                [2,1,4,-1,-1,-1,8,9,6],
                [3,2,7,6,1,9,5,8,4],
                [9,4,8,2,-1,-1,6,7,1],
                [1,5,6,4,7,-1,2,3,9]])
        } else if (props.ClueS === "AtalantaAdventureD") {
            setSudokuArrayData(
                [[8,9,5,-2,-1,-2,7,1,2],
                [4,-2,1,5,-1,7,9,6,8],
                [6,7,2,9,-1,1,4,-1,-2],
                [5,8,9,-1,-1,-1,3,4,7],
                [7,6,-2,-1,-2,-1,1,2,-1],
                [2,1,4,-1,3,5,8,9,6],
                [3,2,7,6,-2,9,5,8,4],
                [9,4,8,2,5,3,6,7,1],
                [1,5,6,-2,7,8,2,3,9]])
        } else if (props.ClueS === "AtalantaAdventureE") {
            setSudokuArrayData(
                [[8,9,5,-2,-1,-2,7,1,2],
                [4,-2,1,5,-1,7,9,6,8],
                [6,7,2,9,-1,1,4,5,-2],
                [5,8,9,-1,-1,-1,3,4,7],
                [7,6,-2,-1,-1,-1,1,2,5],
                [2,1,-2,-1,-2,5,8,-2,6],
                [3,2,7,6,1,9,5,8,4],
                [9,4,8,2,5,-1,-1,7,1],
                [1,5,6,4,7,-1,-1,-1,-1]])
        } else if (props.ClueS === "AtalantaAdventureF") {
            setSudokuArrayData(
                [[8,9,5,-2,-1,-2,7,1,2],
                [4,-2,1,5,-1,7,9,6,8],
                [6,7,2,9,-1,1,4,-1,-2],
                [5,8,9,-1,-1,-1,3,4,7],
                [7,6,-1,-1,-1,-2,1,2,5],
                [2,1,4,-1,-1,5,8,-1,6],
                [3,2,7,6,1,-2,5,8,4],
                [9,4,8,-1,5,3,6,7,-2],
                [1,5,6,4,7,8,2,3,9]])
        } else if (props.ClueS === "HerrimanHaste") {
            setSudokuArrayData(
                [[8,9,5,-1,-1,6,7,1,2],
                [-2,-1,1,-2,-1,7,9,6,8],
                [6,7,2,9,-1,-2,4,-1,3],
                [-2,8,9,-1,-2,2,3,4,7],
                [7,6,-1,-1,-1,4,1,-2,5],
                [2,1,4,-1,-1,5,8,-1,6],
                [3,2,-2,6,1,-1,5,8,4],
                [9,4,8,-1,5,3,6,7,-1],
                [1,5,6,4,7,8,2,3,9]])
        } else if (props.ClueS === "HerrimanHike") {
            setSudokuArrayData(
                [[8,9,5,-1,-1,6,7,1,2],
                [-2,-1,1,-2,-1,7,9,6,8],
                [6,7,2,9,-1,-2,4,-1,3],
                [-2,8,9,-1,-1,2,3,4,7],
                [7,6,-1,-1,-1,-2,1,2,5],
                [2,1,4,-1,-1,5,8,9,-2],
                [3,2,7,6,1,9,5,8,4],
                [9,4,-2,-1,5,3,6,7,-1],
                [1,5,6,4,7,8,2,3,9]])
        } else if (props.ClueS === "HerrimanHunt") {
            setSudokuArrayData(
                [[8,9,5,-1,-1,6,7,1,2],
                [-2,-1,1,-2,-1,7,9,6,8],
                [6,7,2,9,-1,-2,4,-1,3],
                [-2,8,9,-1,-1,2,3,4,7],
                [7,6,-1,-1,-1,4,1,2,5],
                [2,1,4,-1,-1,5,8,9,-2],
                [3,2,7,6,1,-2,5,8,4],
                [9,4,8,-1,5,3,6,7,-1],
                [1,5,6,4,7,8,2,-2,9]])
        } else if (props.ClueS === "HerrimanHuntB") {
            setSudokuArrayData(
                [[8,9,5,-1,-1,6,7,1,2],
                [-2,-1,1,-2,-1,7,9,6,8],
                [6,7,2,9,-1,-2,4,-1,3],
                [-2,8,9,-1,-1,2,3,4,7],
                [7,6,-1,-1,-1,4,1,2,5],
                [2,1,4,-1,-1,5,8,9,-2],
                [3,2,7,6,1,-2,5,8,4],
                [9,4,8,-1,5,3,6,7,-1],
                [1,5,6,4,7,8,2,-2,9]])
        } else if (props.ClueS === "HerrimanHuntC") {
            setSudokuArrayData(
                [[8,9,5,-1,-1,6,7,1,2],
                [-2,-1,1,-2,-1,7,9,6,8],
                [6,7,2,9,-1,-2,4,-1,3],
                [-2,8,9,-1,-2,2,3,4,7],
                [7,6,-1,-1,-1,4,1,-2,5],
                [2,1,4,-1,-1,5,8,9,-1],
                [3,2,-2,6,1,9,5,8,4],
                [9,4,8,-1,5,3,6,7,-1],
                [1,5,6,4,7,8,2,-1,9]])
        } else if (props.ClueS === "HistoricHike") {
            setSudokuArrayData(
                [[8,9,5,-1,-1,6,7,1,2],
                [-1,-2,1,5,-1,7,9,-2,8],
                [6,7,2,9,-1,1,4,-1,-2],
                [5,8,9,-1,-1,2,3,4,7],
                [7,6,-1,-1,-1,4,1,-1,5],
                [-2,1,4,-1,-1,5,8,-2,-1],
                [3,2,7,6,1,9,5,8,4],
                [9,-2,8,-1,5,-2,6,7,1],
                [1,5,6,4,7,8,2,-1,9]])
        } else if (props.ClueS === "KiwanisKuest") {
            setSudokuArrayData(
                [[8,9,5,3,-2,6,7,-1,2],
                [-1,3,-1,5,-2,7,9,-1,8],
                [6,7,2,-1,-1,-1,-2,5,-1],
                [5,8,9,-1,-1,2,3,4,7],
                [7,-2,-1,-1,9,4,1,-1,5],
                [2,1,4,-2,-1,5,8,-1,-1],
                [3,2,7,-1,1,9,5,8,-2],
                [9,4,8,-1,5,3,6,-1,1],
                [1,5,6,4,7,8,2,-1,9]])
        } else if (props.ClueS === "KiwanisKuestB") {
            setSudokuArrayData(
                [[8,9,5,3,-1,6,7,-2,2],
                [-1,3,-2,5,-1,7,9,-1,8],
                [6,7,2,9,-1,-2,4,5,-1],
                [5,8,9,-1,-2,2,3,4,7],
                [7,6,-2,-1,9,4,1,-1,5],
                [2,1,4,-1,-1,5,-2,-1,-1],
                [3,2,7,-2,1,9,-1,-1,4],
                [9,4,8,-1,5,3,6,-2,1],
                [1,5,6,4,7,-2,2,-1,9]])
        } else if (props.ClueS === "KiwanisKuestC") {
            setSudokuArrayData(
                [[8,9,5,3,-1,6,7,-2,2],
                [-1,3,-2,5,-1,7,9,-1,8],
                [6,7,2,9,-1,-2,4,5,-1],
                [5,8,9,-1,-2,2,-1,-1,7],
                [7,6,-2,-1,9,4,1,-1,5],
                [2,1,4,-1,-1,5,-1,-2,-1],
                [3,2,7,6,-2,9,5,8,4],
                [9,4,8,-1,5,3,6,-2,1],
                [1,5,6,4,7,-1,2,-1,9]])
        } else if (props.ClueS === "KiwanisKuestD") {
            setSudokuArrayData(
                [[8,9,5,3,-1,6,7,-2,2],
                [-1,3,-2,5,-1,7,9,-1,8],
                [6,7,2,9,-1,-2,4,5,-1],
                [5,8,9,-1,-2,2,-1,-1,7],
                [7,6,-2,-1,9,4,1,-1,5],
                [2,1,4,-1,-1,5,-2,9,-1],
                [3,2,7,6,-2,9,5,8,4],
                [-2,4,8,-1,5,3,6,7,1],
                [1,5,6,4,7,-1,2,3,9]])
        } else if (props.ClueS === "RivertonCityPark") {
            setSudokuArrayData(
                [[8,9,5,3,-1,6,7,-2,2],
                [-1,3,-2,5,-1,7,9,-1,8],
                [6,7,2,9,-1,-2,4,5,-1],
                [5,8,-2,-1,6,2,3,4,7],
                [7,6,-2,-1,9,4,1,-1,5],
                [2,1,4,-1,-2,5,8,9,-1],
                [3,2,-2,6,-1,9,5,8,4],
                [-1,4,8,-1,5,3,6,7,-2],
                [1,5,6,-2,7,-1,2,3,9]])
        } else if (props.ClueS === "RivertonCityParkB") {
            setSudokuArrayData(
                [[8,9,5,3,-2,6,7,1,2],
                [-1,3,1,5,-1,7,9,-1,8],
                [6,7,2,9,-1,1,4,-2,-1],
                [5,8,-1,-1,6,-2,3,4,7],
                [7,6,-1,-1,9,4,-2,2,5],
                [2,1,4,7,-2,5,8,9,-1],
                [3,2,-1,6,-1,9,5,8,4],
                [-1,4,8,2,5,3,6,-2,1],
                [1,5,6,4,7,-1,2,-2,9]])
        } else if (props.ClueS === "ParadeThroughParks") {
            setSudokuArrayData(
                [[8,9,5,3,-2,6,7,1,2],
                [-1,3,1,5,-1,7,9,-1,8],
                [6,7,-2,9,-1,1,4,-1,-1],
                [5,-2,-1,-1,6,2,3,4,7],
                [7,-2,-1,-1,9,4,-1,2,5],
                [2,1,-2,7,-1,5,8,9,-1],
                [3,2,-1,6,-1,9,5,8,4],
                [-1,4,8,2,5,3,6,-2,1],
                [1,5,6,4,7,-2,2,-1,9]])
        } else if (props.ClueS === "BYUMarvelousMaze") {
            setSudokuArrayData([
                [-1, 9, 5, 3,-2,-1, 7, 1, 2],
                [-1,-1, 1, 5,-2, 7, 9, 6, 8],
                [ 6, 7, 2, 9,-1, 1, 4, 5,-1],
                [-2, 8, 9,-1,-1,-1, 3, 4, 7],
                [ 7, 6,-1,-1,-1,-1,-2, 2,-1],
                [ 2,-2, 4,-1,-1, 5, 8, 9, 6],
                [-1,-1, 7, 6, 1, 9, 5, 8,-2],
                [ 9, 4, 8, 2, 5,-2, 6, 7,-1],
                [ 1, 5, 6, 4, 7, 8, 2, 3, 9]
            ])     
        } else if (props.ClueS === "SquareSights") {
            setSudokuArrayData([
                [ 8, 9, 5,-2,-1,-2, 7, 1, 2],
                [ 4,-2, 1, 5,-1, 7, 9, 6, 8],
                [ 6, 7, 2, 9,-1, 1, 4, 5, 3],
                [-1, 8, 9,-1,-1, 2, 3, 4,-2],
                [ 7,-1,-2,-1, 9, 4, 1,-1,-2],
                [-1, 1,-1, 7,-1, 5, 8, 9, 6],
                [ 3, 2, 7, 6, 1, 9, 5, 8, 4],
                [ 9,-1,-2, 2, 5,-2, 6,-1, 1],
                [ 1, 5, 6, 4, 7, 8, 2, 3, 9]
                // 36.373583
            ])     
        }
        // [ 8, 9, 5,-2,-1,-2, 7, 1, 2],
        // [ 4, 3, 1, 5,-1, 7, 9, 6, 8],
        // [ 6, 7, 2, 9,-1, 1, 4, 5, 3],
        // [ 5, 8, 9,-1,-1, 2, 3, 4,-2],
        // [ 7, 6,-2,-1, 9, 4, 1,-1,-2],
        // [ 2, 1,-1, 7,-1, 5, 8, 9, 6],
        // [ 3, 2, 7, 6, 1, 9, 5, 8, 4],
        // [ 9, 4,-2, 2, 5,-2, 6, 7, 1],
        // [ 1, 5, 6, 4, 7, 8, 2, 3, 9]
    }


    return (
        <div>
            <br/>
            {clueBegan === false ?
              <div className="loadPuzzleButtons" onClick={()=>{handleLoadPuzzle()}}>Load Puzzle</div>
              :
              <Sudoku ClueS={sudokuArrayData}/>
            }
            <br/>
        </div>
    )
}