import React, {useState} from "react";
import { Crossword } from "../ClueCrossword";

export function CrosswordData(props) {
    const [crosswordArrayData, setCrosswordArrayData] = useState([]);
    const [crosswordAnswer, setCrosswordAnswer] = useState([]);
    const [crosswordRows, setCrosswordRows] = useState(0);
    const [crosswordColumns, setCrosswordColumns] = useState(0);
    const [clueBegan, setClueBegan] = useState(false);

    const handleLoadPuzzle = () => {
        setClueBegan(true)
        if (props.huntID === "Get a Clue") {
            setCrosswordArrayData([
                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1,  1, -1,     -1],

                 [-1,   -1, -1,  3, -1, -1, -1, -1, -1, -1, -2, -1,     -1],
                 [ 2,   -2, -2, -2, -2, -2, -1, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1, -1, -2, -1, -1, -1, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1, -1, -2, -1, -1,  5, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1,  4, -2, -2, -2, -2, -2, -2, -2, -2, -2,     -1],
                 [-1,   -1, -1, -2, -1, -1, -2, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1, -1, -1,  6, -2, -2, -2, -2, -2, -1, -1,     -1],
                 [-1,   -1, -1,  7, -2, -1, -2, -1, -1, -1, -1, -1,     -1],
                 [-1,   -1, -1, -1, -2, -1, -2, -1, -1, -1, -1, -1,     -1],
                 [-1,   -1, -1, -1, -2, -1, -2, -1, -1, -1, -1, -1,     -1],
                 [-1,   -1,  8, -2, -2, -2, -2, -2, -1, -1, -1, -1,     -1],

                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1]
                ])
            setCrosswordAnswer([
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1,  1, -1,     -1],

                [-1,   -1, -1,  3, -1, -1, -1, -1, -1, -1,"F", -1,     -1],
                [ 2,  "L","A","B","E","L", -1, -1, -1, -1,"O", -1,     -1],
                [-1,   -1, -1,"E", -1, -1, -1, -1, -1, -1,"R", -1,     -1],
                [-1,   -1, -1,"N", -1, -1,  5, -1, -1, -1,"M", -1,     -1],
                [-1,   -1,  4,"C","O","N","F","O","R","M","E","D",     -1],
                [-1,   -1, -1,"H", -1, -1,"O", -1, -1, -1,"R", -1,     -1],
                [-1,   -1, -1, -1,  6,"G","U","E","S","T", -1, -1,     -1],
                [-1,   -1, -1,  7,"S", -1,"N", -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1,"N", -1,"D", -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1,"O", -1,"E", -1, -1, -1, -1, -1,     -1],
                [-1,   -1,  8,"A","W","A","R","E", -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1]
                ])
            setCrosswordRows(13)
            setCrosswordColumns(13)
        } else if (props.huntID === "Out in the Orchards") {
            setCrosswordArrayData([
                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,  1, -1,     -1],

                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1, -1, -1, -1,  2, -1, -1, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1,  3,  6, -2, -2, -2, -2, -2, -2, -2, -2, -2,     -1],
                 [-1,   -1, -2, -1, -1, -2, -1, -1, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1, -2, -1, -1, -2,  4, -1,  5, -1, -1, -2, -1,     -1],
                 [ 7,   -2, -2, -2, -2, -2, -2, -1, -2, -1, -1, -1, -1,     -1],
                 [-1,   -1, -2, -1,  8, -2, -2, -2, -2, -2, -1, -1, -1,     -1],
                 [-1,   -1, -2, -1, -1, -1, -2, -1, -2, -1, -1, -1, -1,     -1],
                 [-1,   -1, -2,  9, -2, -2, -2, -2, -2, -2, -2, -1, -1,     -1],
                 [-1,   -1, -2, -1, -1, -1, -2, -1, -2, -1, -1, -1, -1,     -1],
                 [-1,   -1, -1, -1, -1, -1, -2, -1, -2, -1, -1, -1, -1,     -1],
                 [-1,   -1, -1, -1, -1, -1, -1, -1, -2, -1, -1, -1, -1,     -1],

                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordAnswer([
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,  1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,"C", -1,     -1],
                [-1,   -1, -1, -1, -1,  2, -1, -1, -1, -1, -1,"H", -1,     -1],
                [-1,   -1,  3,  6,"D","A","N","D","E","L","I","O","N",     -1],
                [-1,   -1,"M", -1, -1,"B", -1, -1, -1, -1, -1,"K", -1,     -1],
                [-1,   -1,"O", -1, -1,"Y",  4, -1,  5, -1, -1,"E", -1,     -1],
                [ 7,  "I","N","S","I","S","T", -1,"A", -1, -1, -1, -1,     -1],
                [-1,   -1,"A", -1,  8,"S","H","A","R","D", -1, -1, -1,     -1],
                [-1,   -1,"R", -1, -1, -1,"W", -1,"T", -1, -1, -1, -1,     -1],
                [-1,   -1,"C",  9,"I","M","A","G","I","N","E", -1, -1,     -1],
                [-1,   -1,"H", -1, -1, -1,"R", -1,"C", -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1,"T", -1,"L", -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1,"E", -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
               ])
            setCrosswordRows(14)
            setCrosswordColumns(14)
        } else if (props.huntID === "Crystal Clear") {
            setCrosswordArrayData([
                 [-1,   -1, -1, -1, -1, -1,  1, -1, -1, -1, -1,     -1],

                 [-1,   -1, -1, -1, -1, -1, -2, -1, -1, -1, -1,     -1],
                 [-1,   -1, -1, -1, -1, -1, -2, -1, -1, -1, -1,     -1],
                 [-1,   -1, -1, -1,  6, -1, -2, -1, -1, -1, -1,     -1],
                 [-1,   -1, -1,  2, -2, -2, -2, -2, -1, -1, -1,     -1],
                 [-1,   -1, -1, -2, -1, -1, -2, -1, -1, -1, -1,     -1],
                 [-1,    7, -2, -2, -2, -2, -2, -2, -2,  3, -1,     -1],
                 [-1,   -1, -1, -2, -1, -1, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1, -1, -2, -1,  8, -2, -2, -2, -2, -2,     -1],
                 [ 9,   -2, -2, -2, -2,  4, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1, -1, -2, -1, -2, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1, 10, -2, -2, -2, -2, -2, -2, -2, -2,     -1],
                 [-1,   -1,  5, -1, -1, -2, -1, -1, -1, -2, -1,     -1],
                 [-1,   -1, -2, -1, 11, -2, -2, -2, -2, -1, -1,     -1],
                 [-1,   12, -2, -2, -2, -2, -1, -1, -1, -1, -1,     -1],
                 [-1,   -1, -2, -1, 13, -2, -2, -2, -2, -1, -1,     -1],
                 [-1,   -1, -2, -1, -1, -1, -1, -1, -1, -1, -1,     -1],

                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordAnswer([
                [-1,   -1, -1, -1, -1, -1,  1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1,"S", -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1,"I", -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1,  6, -1,"L", -1, -1, -1, -1,     -1],
                [-1,   -1, -1,  2,"D","I","V","E", -1, -1, -1,     -1],
                [-1,   -1, -1,"A", -1, -1,"E", -1, -1, -1, -1,     -1],
                [-1,    7,"O","N","T","A","R","I","O",  3, -1,     -1],
                [-1,   -1, -1,"D", -1, -1, -1, -1, -1,"N", -1,     -1],
                [-1,   -1, -1,"R", -1,  8,"A","N","V","I","L",     -1],
                [ 9,  "A","V","O","N",  4, -1, -1, -1,"C", -1,     -1],
                [-1,   -1, -1,"I", -1,"I", -1, -1, -1,"K", -1,     -1],
                [-1,   -1, 10,"D","E","N","T","U","R","E","S",     -1],
                [-1,   -1,  5, -1, -1,"D", -1, -1, -1,"L", -1,     -1],
                [-1,   -1,"L", -1, 11,"E","D","G","E", -1, -1,     -1],
                [-1,   12,"E","V","E","N", -1, -1, -1, -1, -1,     -1],
                [-1,   -1,"A", -1, 13,"T","Y","P","E", -1, -1,     -1],
                [-1,   -1,"D", -1, -1, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordRows(18)
            setCrosswordColumns(12)
        } else if (props.huntID === "BYU Building Bolt") {
            setCrosswordArrayData([
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1,  1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -2,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -2,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -2,     -1],
                [-1,   -1, -1, -1, -1, -1,  2, -1, -1, -1, -2,     -1],
                [-1,   -1, -1, -1, -1,  4, -2, -2, -2, -2, -2,     -1],
                [-1,   -1, -1,  3, -1, -1, -2, -1, -1, -1, -2,     -1],
                [-1,   -1, -1, -2, -1, -1, -2, -1, -1, -1, -1,     -1],
                [ 5,   -2, -2, -2, -2, -2, -2, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -2, -1,  6, -2, -2, -2, -2, -2,     -1],
                [-1,   -1, -1, -2, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -2, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -2, -1, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordAnswer([
                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1,  1,     -1],

                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1,"L",     -1],
                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1,"I",     -1],
                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1,"A",     -1],
                 [-1,   -1, -1, -1, -1, -1,  2, -1, -1, -1,"B",     -1],
                 [-1,   -1, -1, -1, -1,  4,"H","O","V","E","L",     -1],
                 [-1,   -1, -1,  3, -1, -1,"O", -1, -1, -1,"E",     -1],
                 [-1,   -1, -1,"F", -1, -1,"U", -1, -1, -1, -1,     -1],
                 [ 5,  "C","H","O","S","E","N", -1, -1, -1, -1,     -1],
                 [-1,   -1, -1,"U", -1,  6,"D","W","A","R","F",     -1],
                 [-1,   -1, -1,"G", -1, -1, -1, -1, -1, -1, -1,     -1],
                 [-1,   -1, -1,"H", -1, -1, -1, -1, -1, -1, -1,     -1],
                 [-1,   -1, -1,"T", -1, -1, -1, -1, -1, -1, -1,     -1],

                 [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordRows(14)
            setCrosswordColumns(12)
        } else if (props.huntID === "PlanetsCW") {
            setCrosswordArrayData([
                [-1,   -1, -1, -1,  1, -1, -1, -1,  2, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -2, -1, -1, -1, -2, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -2, -1, -1, -1, -2, -1, -1, -1,     -1],
                [ 3,   -2, -2, -2, -2, -2, -1, -1, -2, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -2, -1,  5, -1, -2, -1, -1, -1,     -1],
                [-1,   -1,  4, -2, -2, -2, -2, -2, -2, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -2, -1, -2, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -2, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -2, -1, -1, -1,  7, -1,     -1],
                [-1,   -1, -1, -1,  6, -2, -2, -2, -2, -2, -2, -2,     -1],
                [-1,   -1, -1, -1, -1,  8, -2, -1, -1, -1, -2, -1,     -1],
                [-1,   -1, -1, -1,  9, -2, -2, -2, -2, -2, -2, -2,     -1],
                [-1,   -1, -1, -1, -1, -2, -1, -1, -1, -1, -2, -1,     -1],
                [-1,   -1, -1, -1, -1, -2, -1, -1, -1, -1, -2, -1,     -1],
                [-1,   -1, -1, -1, -1, -2, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordAnswer([
                [-1,   -1, -1, -1,  1, -1, -1, -1,  2, -1, -1, -1,     -1],

                [-1,   -1, -1, -1,"S", -1, -1, -1,"V", -1, -1, -1,     -1],
                [-1,   -1, -1, -1,"A", -1, -1, -1,"E", -1, -1, -1,     -1],
                [ 3,  "P","L","U","T","O", -1, -1,"N", -1, -1, -1,     -1],
                [-1,   -1, -1, -1,"U", -1,  5, -1,"U", -1, -1, -1,     -1],
                [-1,   -1,  4,"U","R","A","N","U","S", -1, -1, -1,     -1],
                [-1,   -1, -1, -1,"N", -1,"E", -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1,"P", -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1,"T", -1, -1, -1,  7, -1,     -1],
                [-1,   -1, -1, -1,  6,"J","U","P","I","T","E","R",     -1],
                [-1,   -1, -1, -1, -1,  8,"N", -1, -1, -1,"A", -1,     -1],
                [-1,   -1, -1, -1,  9,"M","E","R","C","U","R","Y",     -1],
                [-1,   -1, -1, -1, -1,"A", -1, -1, -1, -1,"T", -1,     -1],
                [-1,   -1, -1, -1, -1,"R", -1, -1, -1, -1,"H", -1,     -1],
                [-1,   -1, -1, -1, -1,"S", -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordRows(16)
            setCrosswordColumns(13)
        } else if (props.huntID === "ToolsCW") {
            setCrosswordArrayData([
                [-1,    1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -2, -1, -1,  2, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -2, -1, -1, -2, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -2,  3, -2, -2, -2, -2, -2, -2, -1, -1,  4,     -1],
                [-1,   -2, -1, -1, -2, -1,  6, -1, -1, -1, -1, -2,     -1],
                [ 5,   -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2,     -1],
                [-1,   -1, -1, -1, -1, -1, -2, -1, -1, -1, -1, -2,     -1],
                [-1,   -1, -1, -1, -1, -1, -2, -1, -1, -1, -1, -2,     -1],
                [-1,   -1, -1, -1, -1, -1, -2, -1, -1, -1, -1, -2,     -1],
                [-1,   -1, -1, -1, -1, -1, -2, -1, -1, -1, -1, -2,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordAnswer([
                [-1,    1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],

                [-1,  "P", -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,  "L", -1, -1,  2, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,  "I", -1, -1,"T", -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,  "E",  3,"H","A","M","M","E","R", -1, -1,  4,     -1],
                [-1,  "R", -1, -1,"P", -1,  6, -1, -1, -1, -1,"W",     -1],
                [ 5,  "S","C","R","E","W","D","R","I","V","E","R",     -1],
                [-1,   -1, -1, -1, -1, -1,"R", -1, -1, -1, -1,"E",     -1],
                [-1,   -1, -1, -1, -1, -1,"I", -1, -1, -1, -1,"N",     -1],
                [-1,   -1, -1, -1, -1, -1,"L", -1, -1, -1, -1,"C",     -1],
                [-1,   -1, -1, -1, -1, -1,"L", -1, -1, -1, -1,"H",     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordRows(12)
            setCrosswordColumns(13)
        } else if (props.huntID === "ElementsCW") {
            setCrosswordArrayData([
                [-1,   -1, -1,  1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -2, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -2, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -2, -1, -1, -1,  2, -1,     -1],
                [-1,   -1, -1, -2, -1, -1, -1, -2, -1,     -1],
                [-1,    3, -2, -2, -2, -2, -2, -2, -1,     -1],
                [-1,   -1, -1, -2, -1, -1, -1, -2, -1,     -1],
                [-1,   -1,  4, -1, -1,  5, -1, -2, -1,     -1],
                [-1,   -1, -2, -1, -1, -2, -1, -2, -1,     -1],
                [-1,   -1, -2, -1, -1, -2, -1, -2, -1,     -1],
                [ 6,   -2, -2, -2, -2, -2, -2, -2, -2,     -1],
                [-1,   -1, -2, -1, -1, -2, -1, -1, -1,     -1],
                [ 7,   -2, -2, -2, -2, -2, -2, -2, -2,     -1],
                [-1,   -1, -2, -1, -1, -2, -1, -1, -1,     -1],
                [-1,   -1, -2, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordAnswer([
                [-1,   -1, -1,  1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1,"S", -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1,"O", -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1,"D", -1, -1, -1,  2, -1,     -1],
                [-1,   -1, -1,"I", -1, -1, -1,"K", -1,     -1],
                [-1,    3,"S","U","L","F","U","R", -1,     -1],
                [-1,   -1, -1,"M", -1, -1, -1,"Y", -1,     -1],
                [-1,   -1,  4, -1, -1,  5, -1,"P", -1,     -1],
                [-1,   -1,"C", -1, -1,"C", -1,"T", -1,     -1],
                [-1,   -1,"A", -1, -1,"A", -1,"O", -1,     -1],
                [ 6,  "F","L","U","O","R","I","N","E",     -1],
                [-1,   -1,"C", -1, -1,"B", -1, -1, -1,     -1],
                [ 7,  "N","I","T","R","O","G","E","N",     -1],
                [-1,   -1,"U", -1, -1,"N", -1, -1, -1,     -1],
                [-1,   -1,"M", -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordRows(16)
            setCrosswordColumns(10)
        } else if (props.huntID === "kitchenCW") {
            setCrosswordArrayData([
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1,  1, -1,     -1],

                [-1,   -1, -1, -1,  2, -1, -1, -1, -1, -2, -1,     -1],
                [-1,   -1, -1, -1, -2, -1,  3, -2, -2, -2, -1,     -1],
                [-1,   -1, -1, -1, -2, -1, -1,  4, -1, -2, -1,     -1],
                [-1,   -1, -1, -1, -2, -1, -1, -2, -1, -2, -1,     -1],
                [-1,   -1, -1,  5, -2, -2, -2, -2, -2, -2, -2,     -1],
                [-1,   -1, -1, -1, -2, -1, -1, -2, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -2, -1, -1, -2, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -2, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1,  6, -1, -2, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -2, -1, -2, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -2, -1, -2, -1, -1, -1,     -1],
                [-1,   -1, -1,  7, -2, -2, -2, -2, -2, -1, -1,     -1],
                [-1,   -1,  8, -2, -1, -2, -1, -2, -1, -1, -1,     -1],
                [-1,   -1, -1, -2, -1, -2, -1, -1, -1, -1, -1,     -1],
                [ 9,   -2, -2, -2, -2, -2, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -2, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -2, -1, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordAnswer([
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1,  1, -1,     -1],

                [-1,   -1, -1, -1,  2, -1, -1, -1, -1,"K", -1,     -1],
                [-1,   -1, -1, -1,"J", -1,  3,"P","A","N", -1,     -1],
                [-1,   -1, -1, -1,"U", -1, -1,  4, -1,"I", -1,     -1],
                [-1,   -1, -1, -1,"I", -1, -1,"D", -1,"F", -1,     -1],
                [-1,   -1, -1,  5,"C","A","B","I","N","E","T",     -1],
                [-1,   -1, -1, -1,"E", -1, -1,"S", -1, -1, -1,     -1],
                [-1,   -1, -1, -1,"R", -1, -1,"H", -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1,"W", -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1,  6, -1,"A", -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1,"F", -1,"S", -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1,"R", -1,"H", -1, -1, -1,     -1],
                [-1,   -1, -1,  7,"M","I","X","E","R", -1, -1,     -1],
                [-1,   -1,  8,"S", -1,"D", -1,"R", -1, -1, -1,     -1],
                [-1,   -1, -1,"T", -1,"G", -1, -1, -1, -1, -1,     -1],
                [ 9,  "S","T","O","V","E", -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1,"O", -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1,"L", -1, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordRows(19)
            setCrosswordColumns(12)
        } else if (props.huntID === "everydayCW") {
            setCrosswordArrayData([
                [-1,   -1, -1, -1,  1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -2, -1,  2, -1,     -1],
                [-1,   -1, -1, -1, -2, -1, -2, -1,     -1],
                [-1,    3, -2, -2, -2, -2, -2, -2,     -1],
                [-1,   -1, -1, -1, -2, -1, -2, -1,     -1],
                [-1,   -1, -1, -1, -2, -1, -2, -1,     -1],
                [-1,   -1, -1, -1, -2, -1, -2, -1,     -1],
                [-1,    4, -1, -1, -2, -1, -1, -1,     -1],
                [-1,   -2,  5, -2, -2, -2, -2, -2,     -1],
                [-1,   -2, -1, -1, -2, -1, -1, -1,     -1],
                [ 6,   -2, -2, -2, -2, -2, -2, -2,     -1],
                [-1,   -2, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -2, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordAnswer([
                [-1,   -1, -1, -1,  1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1,"T", -1,  2, -1,     -1],
                [-1,   -1, -1, -1,"E", -1,"C", -1,     -1],
                [-1,    3,"P","I","L","L","O","W",     -1],
                [-1,   -1, -1, -1,"E", -1,"U", -1,     -1],
                [-1,   -1, -1, -1,"V", -1,"C", -1,     -1],
                [-1,   -1, -1, -1,"I", -1,"H", -1,     -1],
                [-1,    4, -1, -1,"S", -1, -1, -1,     -1],
                [-1,  "T",  5,"P","I","A","N","O",     -1],
                [-1,  "A", -1, -1,"O", -1, -1, -1,     -1],
                [ 6,  "B","L","A","N","K","E","T",     -1],
                [-1,  "L", -1, -1, -1, -1, -1, -1,     -1],
                [-1,  "E", -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordRows(14)
            setCrosswordColumns(9)
        } else if (props.huntID === "EmptyCWTemplate") {
            setCrosswordArrayData([
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordAnswer([
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],

                [-1,   -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,     -1],
                ])
            setCrosswordRows(16)
            setCrosswordColumns(13)
        }
    }


    return (
        <div>
            <br/>
            {clueBegan === false ?
              <div className="loadPuzzleButtons" onClick={()=>{handleLoadPuzzle()}}>Load Puzzle</div>
              :
              <Crossword crosswordData={crosswordArrayData} crosswordAnswer={crosswordAnswer} ClueCW={props.ClueCW} cwRows={crosswordRows} cwColumns={crosswordColumns}/>
            }
            <br/>
        </div>
    )
}