import React from "react";

export function Footer() {

    return (
        <div className="footerLayout" style={{marginTop:"60px"}}>
          <h3>&copy; {new Date().getFullYear()} HIDDEN TREASURE HUNTS</h3>
          <h4>hiddentreasurehunts@gmail.com | 856.701.0005</h4>
        </div>
    );

  }

  export function FooterTwo() {

    const logo = require('./pictures/Instagram_Gradient.png')
    const logoTwo = require('./pictures/Facebook_Logo_Primary.png')
    const ARimage = require('./pictures/ARpic.png')
    const UTimage = require('./pictures/UTpic.png')

    return (
        <div className="footerTwoLayout">
          <h4 style={{fontFamily:"NewTextFontBold", marginBottom:"5px"}}>&copy; {new Date().getFullYear()} HIDDEN TREASURE HUNTS</h4>
          <h5 style={{fontSize:"13px", marginBottom:"10px"}}>hiddentreasurehunts@gmail.com | 856.701.0005</h5>
          <div style={{display:"flex", justifyContent:"space-between", width:"200px"}}>
            <a href="/ArkansasAdventures"><img src={ARimage} alt="" style={{height:"20px", width:"20px"}}/></a>
            <a href="/UtahAdventures"><img src={UTimage} alt="" style={{height:"20px", width:"20px"}}/></a>
            <a href="https://www.instagram.com/hiddenhunts/" target="_blank" rel="noreferrer"><img src={logo} alt="" style={{height:"20px", width:"20px"}}/></a>
            <a href="https://www.facebook.com/hiddenhunts" target="_blank" rel="noreferrer"><img src={logoTwo} alt="" style={{height:"20px", width:"20px"}}/></a>

          </div>

        </div>
    );

  }