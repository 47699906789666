import React from "react";
import { PurchasedHuntCard } from "./HuntCard";
import moment from "moment";

export function HistoricHunts(props) {

    return (
      <div style={{maxWidth:"800px", display:"flex", flexDirection:"column", alignItems:"center", marginTop:"-40px"}}>
          <h4 style={{paddingTop:"20px", paddingRight:"20px", paddingLeft:"20px", textAlign:"center"}}>If you just purchased a hunt, wait 1-2 minutes and the hunt will appear below!</h4>
          <h6 style={{paddingBottom:"40px", textAlign:"center", fontWeight:"normal"}}>(call 856.701.0005 with any issues)</h6>
          <div style={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
            {props.previousHunts
            .map(card => {
              return (
                <PurchasedHuntCard
                  key={card.id}
                  Date={moment(card.date).format("M/D/YY")}
                  id={card.id}
                  step={card.step}
                  user={props.user}
                  func={props.giftFunction}
              />
            )})}
          </div>
          <br/><br/><br/><br/>
      </div>
    );
  }
  