import { useContext } from "react";
import { CartContext } from "./CartContext";
import { MdRemoveCircle } from "react-icons/md";
import { IconButton } from "@material-ui/core";

function CartView(props) {

    const appUser = props.user
    const cart = useContext(CartContext);

    return (
        <table style={{width:"100%", marginBottom:"10px"}}>
            <tbody>
            <tr style={{fontWeight:"bold"}}>
                <td style={{display:"flex", paddingBottom:"10px", borderBottom: "solid 1px black"}}><div style={{width:"75%"}}>Item</div><div style={{width:"15%", textAlign:"center"}}>Price</div></td>
            </tr>
            {cart.getCartProducts().map((item, idx) => {
                return (
                    <tr key={idx} style={{height:"50px", width:"100%", display:"flex", alignItems:"center", fontSize:"14px", borderBottom: "solid 1px lightgray"}}>
                        <td style={{width:"75%"}}>{item.id}</td>
                        <td style={{width:"15%", textAlign:"center"}}>${item.PriceAmount}</td>
                        <td style={{width:"10%", textAlign:"right"}}><IconButton style={{padding:"0px"}} onClick={() => cart.removeOneFromCart(item.id, appUser)}><MdRemoveCircle style={{fontSize:"16px", color:"red"}}/></IconButton></td>
                    </tr>
                )  
            })}
            <tr style={{fontWeight:"bold"}}>
                <td style={{display:"flex", paddingTop:"100px"}}><div style={{width:"75%"}}>Total Cost</div><div style={{width:"15%", textAlign:"center"}}>${cart.getTotalCost().toFixed(2)}</div></td>
            </tr>
            </tbody>
        </table>
    )
}

export default CartView;