import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';

const steps = [
  {
    label: 'Split into teams',
    description: `Count the number of people in your group and determine how many teams you will have - we recommend teams of 2 to 3 people for the best experience, but it's your call!`,
  },
  {
    label: 'Determine your hunt difficulty',
    description: 'Our puzzle adventures have 4 difficulty levels - Kids, Easy, Medium, and Hard. Generally, Kids hunts are for ages 6-12, Easy hunts are casual fun, Medium hunts will make you think, and Hard hunts are for HiddenHunts veterans!',
    description2: "For each of your teams, determine what difficulty level is wanted. If one team wants an easy hunt and another wants medium - that's okay! We have plenty of options to choose from."
  },
  {
    label: 'Choose your adventure',
    description: `All adventures are listed on the "Hunts" page on our site. Scroll through them to find one that fits your qualifications: location, walking vs driving, difficulty, number of teams, etc.`,
    description2: 'When you find a hunt that meets your criteria, purchase as many team options as you need. So if you have 3 teams that all want the same hunt, you need to purchase "Team1", "Team2", and "Team 3" options in that hunt.',
    description3: 'The team options within the same hunt give similar clues, in the same destinations, but in different orders so teams can race against each other, if wanted.'
  },
  {
    label: 'Buy and share hunts',
    description: `If each team is paying separately, they can purchase their own hunt (ex. Team 1 buys the "Team 1" option). The hunt will appear on the Purchases page 1-2 minutes later - ready to begin!`,
    description2: 'If paying all together, one person can buy all the team options needed. The purchased hunts will show up on their Purchases page, where they can then click on each hunt and send them to the other teams at the click of a button!',
    description3: `Hunts can be purchased right as you're ready to begin, or bought in advance and used weeks/months later; they don't expire until they're used.`
  },
  {
    label: 'Let the adventure begin',
    description: `Drive to the general location of the hunt - location links are listed on each hunt on the "Hunts" page. Park somewhere nearby.`,
    description2: `Once you're there, go to the "Purchases" page, click on the hunt you want to start, and begin! Don't worry if you can't complete the adventure in one go - your progress will save until you finish it another day.`,
    description3: 'Your first clue will populate with some type of puzzle to solve. Once you do, it will lead you to a destination where you will be required to answer a unique question - confirming your location - before you are given your next clue. See how quickly you can solve all 7 clues!',
    description4: `Feel free to use Google, pen and paper, the hints we provide in each clue, or any other resource to help you solve our homemade puzzles.`
  },
  {
    label: 'Collect a prize',
    description: `Each team that completes a hunt, wins a "prize coin" on our site. This coin can be redeemed for a coupon to a local business. Available coupons are listed on the "Prizes" page - most of which are BOGOs or %s off.`,
    description2: 'To redeem one, go to the business and click the "Use Coupon" button in front of the cashier. They will apply the discount!',
    description3: 'If you own a business and want to advertise a coupon on our site, just email us at hiddentreasurehunts@gmail.com'
  },
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 400, mb:7 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} className='MuiStepLabel-labelContainer'>
            <StepLabel
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <h5>{step.description}</h5>
              {step.description2 ? <h5 style={{marginTop:"1rem"}}>{step.description2}</h5> : <></> }
              {step.description3 ? <h5 style={{marginTop:"1rem"}}>{step.description3}</h5> : <></> }
              {step.description4 ? <h5 style={{marginTop:"1rem"}}>{step.description4}</h5> : <></> }
              <Box sx={{ mb: 2, mt:1 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
          <div style={{marginTop:"1em", fontFamily:"NewTextFontBold", color:"#FD2EFF", fontSize:"1rem", cursor:"pointer"}} onClick={handleReset} >
            Start Over
          </div>
      )}
    </Box>
  );
}