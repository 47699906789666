import React from "react";
import { Board } from "./ClueSP/ClueSlidingPuzzleBoard";

export function SlidingPuzzle(props) {

    return (
        <div className="slidingPuzzleMainDiv">
            <Board imgUrl = {props.ClueSP}/>
        </div>
    )
}