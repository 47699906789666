import React, { useState } from "react";

export function Crossword(props) {
    const [correctAnswer, setCorrectAnswer] = useState(false);

    const initial = props.crosswordData
    const cwRows = props.cwRows
    const cwColumns = props.cwColumns
    const answerArray = props.crosswordAnswer
    // console.log(initial)

      const [crosswordArr, setCrosswordArr] = useState(initial);

      function getDeepCopy(arr){
        return JSON.parse(JSON.stringify(arr));
      }

      function onInputChange(e, row, col) {
        var val = e.target.value.toUpperCase() || -1, grid = getDeepCopy(crosswordArr);
        if (val === -1 || (val <= 15 && val >= 1) || isNaN(val)) {
            grid[row][col] = val;
        } 
        setCrosswordArr(grid);
      }

      function testAnswer(crosswordArr) {
        if (JSON.stringify(crosswordArr) === JSON.stringify(answerArray)) {
            setCorrectAnswer(true)
        } else {
            alert("That's not quite right, keep trying!")
        }
    }

    return (
        <div>
            <br/>
            <table className="crossword">
                <tbody>
                    {[...Array(cwRows)].map((row, rIndex) => {
                            return (
                            <tr key={rIndex}>
                                {[...Array(cwColumns)].map((col, cIndex) => {
                                    return (
                                    <td className="crossword" key={rIndex + cIndex}>
                                          <input 
                                              onChange={(e) => onInputChange(e, rIndex, cIndex)} 
                                              value={crosswordArr[rIndex][cIndex] === -1 ? "" : crosswordArr[rIndex][cIndex] === -2 ? "" : crosswordArr[rIndex][cIndex]}
                                              className={initial[rIndex][cIndex] === -2 ? "crosswordImportantCellInput" : initial[rIndex][cIndex] === -1 ? "crosswordDisabled" : "crosswordCellInput" }
                                              disabled={initial[rIndex][cIndex] === -1 || (initial[rIndex][cIndex] <=15 && initial[rIndex][cIndex] >= 1)}
                                              maxLength="1"
                                          />
                                    </td>
                                )})}
                            </tr>
                        )})}
                </tbody>
            </table>
            { correctAnswer === false ?
            <div className="blackButtons" onClick={()=>{testAnswer(crosswordArr)}}>TEST ANSWER</div>
            :
            <div><br/><div style={{width:"270px"}}>{props.ClueCW ? props.ClueCW.split("\\n").map(function(item) { return ( <div><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div> }</div></div>
            }
        </div>
    )
}