import React, {useState} from "react"
import { GiLaurelsTrophy } from "react-icons/gi";
import { IconContext } from "react-icons";
import { db, snapshotToArray } from "./firebase";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { BiArrowBack } from "react-icons/bi";
import { IconButton } from "@mui/material";

export function Scoreboard(props) {
    const [leaderArray, setLeaderArray] = useState([]);
    const [loadData, setLoadData] = useState(false);

    const pullLeaderData = () => {
        db.collection("scoreboard").get().then(snapshot => {
            const queryData = snapshotToArray(snapshot);
            setLeaderArray(queryData)
        }).then(setLoadData(true))
    }

    return (
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
            <div style={{width:"90vw", maxWidth:"600px", display:'flex', justifyContent:"center", marginTop:"30px"}}>
                <svg width="130px" height="130px">
                  <defs>
                    <linearGradient id="myScoreboardIcon" gradientTransform="rotate(35)">
                      <stop offset="5%"  stopColor="gold" />
                      <stop offset="100%" stopColor="goldenrod" />
                    </linearGradient>
                  </defs>
                  <IconContext.Provider value={{ attr: {fill: "url('#myScoreboardIcon')"}}}>
                    <GiLaurelsTrophy style={{fontSize:"130px"}}/>
                  </IconContext.Provider>
                </svg>
            </div>
            <div style={{fontSize:"30px", marginTop:"10px"}}>Scoreboard</div>
            <div style={{fontSize:"10px"}}>(No hints or answers used)</div>
            
            {loadData === false ?
                <div className="prizeButtons" style={{marginTop:"30px", maxWidth:"500px"}} onClick={pullLeaderData}>Open Leaderboard</div>
            :
                <div style={{width:"95vw", maxWidth:"600px", marginBottom:"100px"}}>
                    <table style={{marginBottom:"10px", marginTop:"20px", padding:"10px", width:"100%"}}>
                        <tbody>
                        <tr style={{fontWeight:"bold", fontSize:"14px"}}>
                            <td style={{display:"flex", paddingBottom:"10px", paddingTop:"10px", borderBottom: "solid 2px black"}}>
                                <div style={{width:"30%"}}>Team</div>
                                <div style={{width:"35%"}}>Hunt</div>
                                <div style={{width:"20%"}}>Date</div>
                                <div style={{width:"15%", textAlign:"right", whiteSpace:"pre"}}>Time  </div>
                            </td>
                        </tr>
                        {leaderArray
                        .sort((a, b) => a.name - b.name)
                        .map((hunt, idx) => {
                            return (
                            <tr key={idx}>
                                <td style={{display:"flex", borderBottom: "solid 1px black", paddingTop:"15px", paddingBottom:"15px", fontSize:"10px"}}>
                                    <div style={{width:"30%", whiteSpace:"pre"}}>  {hunt.name}</div>
                                    <div style={{width:"35%", whiteSpace:"pre"}}>{hunt.id}</div>
                                    <div style={{width:"20%"}}>{hunt.date}</div>
                                    <div style={{width:"15%", textAlign:"right", whiteSpace:"pre"}}>{hunt.time} min  </div>
                                </td> 
                            </tr>
                            )
                        })}
                        
                        </tbody>
                    </table>
                </div>
            }  

            <AppBar style={{ backgroundColor: "black", borderTop:"solid 4px white", display:"flex", alignItems:"center", justifyContent:"center", width:"100%", height:"60px"}} position="fixed" sx={{ top: 'auto', bottom: 0 }}>
              <Toolbar style={{width:"350px", display:"flex", justifyContent:"space-around"}}>
                <a href="/" className="appHeaderIconDivSpacing" style={{textDecoration:"none"}}><div className="appHeaderIcon"><IconButton style={{padding:"0px", color:"white"}}><BiArrowBack/></IconButton><h5 style={{color:"white"}}>Back</h5></div></a>
              </Toolbar>
            </AppBar>

        </div>  
    )
}