// AUTHENTICATION GENERAL IMPORTS
import React, {useEffect, useState} from "react";
import {auth, db} from "./firebase";
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { HeaderBar } from "./HeaderBar";


// AUTHENTICATION MAIN CONTENT IMPORTS
import PrizeStoreAR from "./PrizeStoreAR";
import PrizeStoreUT from "./PrizeStoreUT";
import { FooterTwo } from "./Footer";

export function AuthPrizePage(props) {
  const [user, setUser] = useState(null);
  const [stateSelect, setStateSelect] = useState("UT")
  const [prizeMoney, setPrizeMoney] = useState(0);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(u => {
      if (u) {
        setUser(u);

        db.collection("users").doc(u.uid).collection("PrizeMoney").doc("coins").get().then(snapshot => {
          const queryData = snapshot.data()
          setPrizeMoney(String(queryData.coins))
        })
      }
    });
    return unsubscribe;
  }, [props.history]);

  const handleStateChange = (event) => {
    setStateSelect(event.target.value);
  };

  function updatePrizeCoins () {
    db.collection("users").doc(user.uid).collection("PrizeMoney").doc("coins").update({coins: prizeMoney - 1}).then(
      setPrizeMoney(prizeMoney - 1)
    )
  }

  return (
    <HelmetProvider>
    <div className="pageContainer">
        <div className="appBackground">

            {/* SEO INFO */}
            <Helmet link = {[{ rel : 'canonical', href : "https://www.hiddenhunts.com/prizePage"  }]}>
                <title>Local Prizes</title>
                <meta name='description' content="Win coupons to local businesses as you take on creative, fun progressive puzzle adventures!"/>
                <meta name="keywords" content="Provo coupons, Orem coupons, Bentonville coupons, Rogers coupons, coupon prizes"/>
            </Helmet>  

            {/* RENDERED PAGE INFO */}
            <HeaderBar PageID="Prizes"/>
  
            <div className="appMainContentDiv">
              <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
              <div style={{display:"flex", justifyContent:"center", width:"100%", marginBottom:"40px"}}><h3 style={{color:"#FD2EFF"}}>{prizeMoney} <div style={{display:"inline", color:"black"}}>Coins</div></h3></div>
                <h4 style={{width:"100%", textAlign:"center", fontSize:"2rem",color:'#2E59FF'}}>Coupon Options</h4>
                <div style={{display:"flex", justifyContent:"center", color:"black", marginBottom:"1em"}}>
                    <FormControl sx={{ m: 0, minWidth: 120, boxShadow:"none", '.MuiOutlinedInput-notchedOutline': { border: 0 }}} size="small">
                      <Select
                        MenuProps={{ MenuListProps: { sx: { "li.MuiButtonBase-root": { display: "flex", flexDirection: "column", alignItems:"flex-start", justifyContent:"center", paddingLeft:"15px", width:"120px", fontFamily:"NewTextFontBold" }}}}}
                        sx={{fontFamily:"NewTextFontBold"}}
                        value={stateSelect}
                        onChange={handleStateChange}
                      >
                        <MenuItem value={"UT"}>UTAH</MenuItem>
                        <MenuItem value={"AR"}>ARKANSAS</MenuItem>
                      </Select>
                    </FormControl>
                </div>

                <div>
                  {(user!==null) ? 
                    <div style={{marginTop:"2em", display:"flex", flexDirection:"column", alignItems:"center"}}>
                      { stateSelect === "UT" ? <PrizeStoreUT func={updatePrizeCoins} coins={prizeMoney} user={user.uid}/> : <div></div> }
                      { stateSelect === "AR" ? <PrizeStoreAR func={updatePrizeCoins} coins={prizeMoney} user={user.uid}/> : <div></div> }
                    </div>
                  :
                    <div>
                      <div style={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center", color:"black"}}>
                        <div><h4 style={{fontSize:"22px", width:"90vw", textAlign:"center", maxWidth:"360px", marginBottom:"40px", color:'#2E59FF'}}>You must <a href="/signIn" rel="noreferrer" style={{color:"#2E59FF"}}>sign in</a> & have earned a prize coin to use a coupon </h4></div>
                      </div>
                      { stateSelect === "UT" ? <PrizeStoreUT/> : <div></div> }
                      { stateSelect === "AR" ? <PrizeStoreAR/> : <div></div> }
                    </div>
                  }
                </div>
              </div>
            </div>

            <FooterTwo />
        </div>
    
    </div>
    </HelmetProvider>
  );
}
