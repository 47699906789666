import React, { useState } from "react";

export function Sudoku(props) {

    const initial = props.ClueS
    // console.log(initial)

      const [sudokuArr, setSudokuArr] = useState(initial);

      function getDeepCopy(arr){
        return JSON.parse(JSON.stringify(arr));
      }

      function onInputChange(e, row, col) {
        var val = parseInt(e.target.value) || -1 || -2, grid = getDeepCopy(sudokuArr);
        // Input value should range from 1-9 and for empty cells it should be -1
        if (val === -1 || val === -2 || (val >=1 && val <= 9)) {
            grid[row][col] = val;
        }
        setSudokuArr(grid);
      }

    return (
        <div>
            <br/>
            <table className="sudoku">
                <tbody>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((row, rIndex) => {
                            return (
                            <tr key={rIndex} className={(row + 1) %3 === 0 ? "sudokuBoldBorder" : ""}>
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((col, cIndex) => {
                                    return (
                                    <td className={(col + 1) %3 === 0 ? "sudokuBoldColumn" : "sudoku"} key={rIndex + cIndex}>
                                        {Array.isArray(initial) ?
                                            <input 
                                                onChange={(e) => onInputChange(e, row, col)} 
                                                value={sudokuArr[row][col] === -1 ? "" : sudokuArr[row][col] === -2 ? "" : sudokuArr[row][col]}
                                                className={(initial[row][col] === -2) ? "sudokuImportantCellInput" : (initial[row][col] > 0) ? "sudokuDisabled" : "sudokuCellInput"}
                                                disabled={initial[row][col] > 0}
                                                inputMode='numeric'
                                            />
                                            :
                                            <div></div>
                                        }
                                    </td>
                                )})}
                            </tr>
                        )})}
                </tbody>
            </table>
        </div>
    )
}