import React from "react";
import { HeaderBar } from "./HeaderBar";
import {FooterTwo} from "./Footer";

export function Mission(props) {

return (
    <div className="pageContainer">
        <div className="authBackground">

          <HeaderBar PageID="Mission" />


{/* AUTHENTICATION PAGE MAIN CONTENT */}
            <div className="appMainContentDiv">
              <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>

                <div style={{width:"90%", maxWidth:"800px", display:"flex", flexDirection:"column", textAlign:"center", alignItems:"center", marginBottom:"4em"}}>
                  <h3 style={{fontSize:"1.5rem", marginBottom:"1.5em"}}>What's our mission?</h3>
                  <h4 style={{fontFamily:"NewTextFont", width:"100%", textAlign:"justify", textJustify:"inter-word"}}>We believe in providing fun and creative ways to explore communities; engage in challenging, mentally stimulating activities; & promote healthy interaction with others.</h4>
                  <br/>
                  <h3 style={{width:"100%", textAlign:"justify", textJustify:"inter-word", marginBottom:"10px"}}>Exploring Communities</h3>
                  <h4 style={{fontFamily:"NewTextFont", width:"100%", textAlign:"justify", textJustify:"inter-word"}}>Our treasure hunts are carefully designed to lead hunters to unique, interesting, and/or historically relevant areas of local communities - encouraging enjoyment of the culture and beauty of each town. In addition, we seek to encourage patronization of local businesses by including coupons to establishments as prizes, redeemable by treasure hunters at the end of a hunt.</h4>
                  <br/>
                  <h3 style={{width:"100%", textAlign:"justify", textJustify:"inter-word", marginBottom:"10px"}}>Mentally Stimulating</h3>
                  <h4 style={{fontFamily:"NewTextFont", width:"100%", textAlign:"justify", textJustify:"inter-word"}}>As Einstein once said, "Education is not the learning of facts, but training the mind to think." We enjoy participating in the education of today's minds - young and old. The hunts we provide are full of riddles, logic puzzles, word games, ciphers, codes, crosswords, etc. that necessitate out-of-the-box, patient reasoning to solve.</h4>
                  <br/>
                  <h3 style={{width:"100%", textAlign:"justify", textJustify:"inter-word", marginBottom:"10px"}}>Healthy Interaction</h3>
                  <h4 style={{fontFamily:"NewTextFont", width:"100%", textAlign:"justify", textJustify:"inter-word"}}>In order to tackle the series of clues within each hunt, participants should plan to go out in groups of two or more. These small group sizes give teammates opportunities to bounce ideas off each other and encourage participation from all team members - building minds and memories!</h4>
                  
                </div> 

              </div>
            </div>

{/* AUTHENTICATION PAGE FOOTERS */}
            <div style={{paddingBottom:"100px"}}><br/><br/><br/><br/><FooterTwo /></div>
        </div>
        
    </div>

)}