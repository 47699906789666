import { HuntCard } from "./HuntCard"
import { huntArray } from "./HuntListArray"

function HuntStoreAR(props) {
    const huntArrayBentonville = huntArray.filter(hunt => hunt.State === "AR" && hunt.City === "Bentonville")
    const huntArrayRogers = huntArray.filter(hunt => hunt.State === "AR" && hunt.City === "Rogers")
    
    return (
        <div>
            <div style={{display:"flex", alignItems:"center", flexDirection:"column", marginBottom:"250px"}}>
                <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                    <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}><h4>Bentonville</h4></div>
                    {huntArrayBentonville.map((hunt, idx) => {return (<div key={idx}><HuntCard hunt={hunt} user={props.user}/></div>)})}
                    <div style={{height:"20px"}}></div>
                </div>

                <div style={{width:"100vw", maxWidth:"600px", marginBottom:"25px"}}>
                    <div style={{color:"black", fontSize:"20px", padding:"10px", paddingLeft:"20px"}}><h4>Rogers</h4></div>
                    {huntArrayRogers.map((hunt, idx) => {return (<div key={idx}><HuntCard hunt={hunt} user={props.user}/></div>)})}
                    <div style={{height:"20px"}}></div>
                </div>
            </div>
        </div>
    )
}

export default HuntStoreAR;