import React, { useState } from "react";
import { OutlinedInput, IconButton, Dialog } from "@material-ui/core";
import { ImInfo } from "react-icons/im";
import { SudokuData } from "./clues/ClueS/ClueSudokuData";
import { SlidingPuzzle } from "./clues/ClueSlidingPuzzle";
import { CrosswordData } from "./clues/ClueCW/ClueCrosswordData";
import { MathSquareData } from "./clues/ClueMS/ClueMathSquareData";
import { WordLadder } from "./clues/ClueWordLadder";
import { FillInTheWords } from "./clues/ClueFillInTheWords";
import { KakuruData } from "./clues/ClueK/ClueKakuruData";
import { MdOutlineCheckCircle } from "react-icons/md";
import { AiOutlineFileDone } from "react-icons/ai";
import { MdHelpOutline } from "react-icons/md";
import { IconContext } from "react-icons";

export function PracticeCard(props) {
  // INPUT & SUBMISSION VARIABLES
  const [clientAnswer, setClientAnswer] = useState("");
  // ALL OTHER BUTTON VARIABLES
  const [openInstructions, setOpenInstructions] = useState(false);
  const [openPicture, setOpenPicture] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openHint, setOpenHint] = useState(false);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [openHowItWorks, setOpenHowItWorks] = useState(false);
  const [chessAnswer, setChessAnswer] = useState("");
  const [chessCorrect, setChessCorrect] = useState(false);
  const [chessPart, setChessPart] = useState(0);
  const [mapCoord, setMapCoord] = useState("");
  const [correctAnswerAlert, setCorrectAnswerAlert] = useState(false);


  const testAnswer = () => {
    if (clientAnswer.toUpperCase().trim() === props.Answer) {      
        setCorrectAnswerAlert(true)

        setTimeout(()=> {
          setCorrectAnswerAlert(false)
        }, 2000);

    } else {
      alert("Not quite! If the clue has instructions, make sure to click the icon and read them.")
    }
  }

  const handleOpenHint = () => {
    setOpenHint(true)
  }

  const handleOpenAnswer = () => {
    setOpenAnswer(true)
  }

  const handleTestChess = () => {
    const noSpaces = chessAnswer.replace(/ /g, '');
    if (props.ClueChessAnswer2) {
      if (chessPart === 0) {
        if (noSpaces.toUpperCase().trim() === props.ClueChessAnswer) {
          setChessPart(1)
          setChessAnswer("")
        } else {
          alert("Wrong move! Try again.")
        }
      } else {
        if (noSpaces.toUpperCase().trim() === props.ClueChessAnswer2) {
          setChessCorrect(true)
          setChessPart(0)
        } else {
          alert("Wrong move! Try again.")
        }
      }
    } else {
      if (noSpaces.toUpperCase().trim() === props.ClueChessAnswer) {
        setChessCorrect(true)
      } else {
        alert("Wrong move! Try again.")
      }
    }
  }

    return (
    <div style={{marginBottom:"150px"}}>
      
      <div style={{display:"flex", justifyContent:"center"}}>
        <div className="treasureHuntCard">
          <div className="treasureHuntMainDiv">

            {/* CLUE CARD HEADER */}
            <div className="treasureHuntTitle"><h2>Practice Clue {props.clueNumber}</h2></div>
            <br/><br/>

            {/* CLUE CARD TEXT */}
            <div className="treasureHuntTextDiv">
              <div className="treasureHuntText">

                {/* CLUE NUMBER & CONDITIONAL INSTRUCTIONS ICON */}
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                  <h3 className="treasureHuntHeaders" style={{marginBottom:"10px", fontWeight:"bolder"}}>CLUE</h3>
                  <div style={{display:"flex"}}>
                    {props.I ?
                      <IconButton style={{fontSize:"20px", paddingTop:"1px", marginRight:"-5px"}} onClick={() => {setOpenInstructions(true)}}>
                        <svg width="20" height="20">
                          <defs>
                            <linearGradient id="myInstructionsGradient" gradientTransform="rotate(95)">
                              <stop offset="5%"  stopColor="black" />
                              <stop offset="100%" stopColor="green" />
                            </linearGradient>
                          </defs>
                          <IconContext.Provider value={{ attr: {fill: "url('#myInstructionsGradient')"}}}>
                            <ImInfo/>
                          </IconContext.Provider>
                        </svg>
                      </IconButton>
                    :
                      <div style={{width:"50px"}}></div>
                    }
                  </div>
                </div>

                {/* CLUE CARD CLUE, IMAGE, SUDOKU, ETC. INFORMATION */}
                {props.Clue ? props.Clue.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div> }
                {props.ClueTwo ? props.ClueTwo.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px", marginTop:"0px"}}>{item}</h5></div> )}) : <div></div> }
                {props.ClueWL ? <WordLadder myArray={props.ClueWL}/> : <div></div> }
                {props.ClueFW ? <FillInTheWords ClueFW={props.ClueFW}/> : <div></div> }
              </div>

              {/* CLUE CARD OPTIONAL CLUES */}
              {props.ClueI ? 
                <div>
                  {chessPart === 0 ?
                  <img className="treasureHuntClueImage" src={props.ClueI} alt="" /> 
                  :
                  <div></div>
                  }
                </div> 
              : 
                <div></div>
              }
              {props.ClueI2 ? 
                <div>
                  {chessPart === 1 ?
                  <img className="treasureHuntClueImage" src={props.ClueI2} alt="" /> 
                  :
                  <div></div>
                  }
                </div>
              : 
              <div></div> 
              }
              {props.ClueS ? <SudokuData ClueS={props.ClueS}/> : <div></div> }
              {props.ClueSP ? <SlidingPuzzle ClueSP={props.ClueSP}/> : <div></div> }
              {props.ClueCW ? <CrosswordData ClueCW={props.ClueCW} huntID={props.ClueCWid}/> : <div></div> }
              {props.ClueMS ? <MathSquareData ClueMS={props.ClueMS} ClueMSid={props.ClueMSid}/> : <div></div> }
              {props.ClueK ? <KakuruData ClueK={props.ClueK} ClueKid={props.ClueKid}/> : <div></div> }
              {props.ClueChess ? 
                <div style={{width:"80vw", maxWidth:"400px", minWidth:"280px"}}>
                  {chessCorrect === false ? 
                    <div> <OutlinedInput placeholder={""} fullWidth={true} value={chessAnswer} className="treasureHuntInputBackground" onChange={e => {setChessAnswer(e.target.value)}}/>
                      <div className="chessCheckButton" style={{textAlign:"center"}} onClick={handleTestChess}>Test Answer</div>
                    </div>
                    : 
                    <div>
                      <br/><h5>LOCATION INFO</h5>
                      <h5>{props.ClueChess.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px", marginTop:"5px"}}>{item}</h5></div> )})}</h5>
                    </div>
                  } 
                </div>
                :
                <div></div>
              }
              {props.Map ? <div style={{width:"80vw", maxWidth:"400px", minWidth:"280px", marginTop:"20px"}}> <h5>Your coordinates are: {props.Map} <br/>Once you've solved for the missing numbers, enter the completed coordinates in the field below, then click the button</h5> <OutlinedInput placeholder={""} fullWidth={true} value={mapCoord} className="treasureHuntInputBackground" onChange={e => {setMapCoord(e.target.value)}}/> <a className="chessCheckButton" href={'https://www.google.com/maps/place/' + mapCoord} target="_blank" rel="noreferrer" style={{textAlign:"center"}} >Test Coordinates</a></div> :<div></div> }
              {props.ClueThree ? props.ClueThree.split("\\n").map(function(item, index) { return ( <div key={index} style={{marginTop:"1em"}}><h5 style={{marginBottom:"3px", marginTop:"0px"}}>{item}</h5></div> )}) : <div></div> }
                

              <br/><br/>
              <div className="treasureHuntText">
                <h3 className="treasureHuntHeaders" style={{marginBottom:"10px", fontWeight:"bolder"}}>QUESTION</h3>
                <h5>{props.Question}</h5>
              </div>
            </div>

            {/* CLUE CARD INPUT AND BUTTONS */}
            <OutlinedInput placeholder={""} fullWidth={true} value={clientAnswer} className="treasureHuntInputBackground" onChange={e => {setClientAnswer(e.target.value)}}/>
            <br/>
            <div style={{display:"flex"}}>
              <div className="treasureHuntWhiteButtons" style={{textAlign:"center"}} onClick={handleOpenAnswer}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <svg width="40" height="40">
                    <defs>
                      <linearGradient id="myAnswerGradient" gradientTransform="rotate(95)">
                        <stop offset="5%"  stopColor="red" />
                        <stop offset="100%" stopColor="red" />
                      </linearGradient>
                    </defs>
                    <IconContext.Provider value={{ attr: {fill: "url('#myAnswerGradient')"}}}>
                      <AiOutlineFileDone style={{fontSize:"40px", marginBottom:"5px"}}/>
                    </IconContext.Provider>
                  </svg>
                  <div>GIVE UP</div>
                  <h6>Get answer</h6>
                </div>
              </div>

              <div className="treasureHuntWhiteButtons" style={{textAlign:"center"}} onClick={handleOpenHint}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <svg width="40" height="40">
                    <defs>
                      <linearGradient id="myHintGradient" gradientTransform="rotate(95)">
                        <stop offset="5%"  stopColor="greenyellow" />
                        <stop offset="100%" stopColor="red" />
                      </linearGradient>
                    </defs>
                    <IconContext.Provider value={{ attr: {fill: "url('#myHintGradient')"}}}>
                      <MdHelpOutline style={{fontSize:"40px", marginBottom:"5px"}}/>
                    </IconContext.Provider>
                  </svg>
                  <div>HINT</div>
                </div>
              </div>

              <div className="treasureHuntWhiteButtons" onClick={testAnswer}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <svg width="40" height="40">
                    <defs>
                      <linearGradient id="mySubmitGradient" gradientTransform="rotate(95)">
                        <stop offset="5%"  stopColor="greenyellow" />
                        <stop offset="100%" stopColor="green" />
                      </linearGradient>
                    </defs>
                    <IconContext.Provider value={{ attr: {fill: "url('#mySubmitGradient')"}}}>
                      <MdOutlineCheckCircle style={{fontSize:"40px", marginBottom:"5px"}}/>
                    </IconContext.Provider>
                  </svg>
                  <div>SUBMIT</div>
                  {correctAnswerAlert === true ?
                    <div className='alert-container'>
                      <div className='alert-inner'>
                          Correct!<br/>Try another clue
                      </div>
                    </div>
                    :
                    <div></div>
                  }
                </div>
              </div>
            </div>  
            <div style={{marginTop:"15px", marginBottom:"10px", color:"blue", fontSize:"14px", cursor:"pointer"}} onClick={()=>{setOpenHowItWorks(true)}}>How do these clues work?</div>       
            <h6 style={{marginBottom:"-5px", color:"black", fontWeight:"normal"}}>&copy; {new Date().getFullYear()} - Hidden Treasure Hunts</h6>

          </div>
        </div>
        </div>

        {/* DIALOG BOXES */}
        <Dialog open={openHowItWorks} onClose={() => {setOpenHowItWorks(false)}}><div className="treasureHuntButtonDiv"><h4 style={{fontWeight:"bolder"}}>HOW IT WORKS</h4><hr/><h5 style={{margin:"10px"}}><div style={{fontFamily:"NewTextFontBold", fontSize:"16px"}}>Clue</div>Each clue is some type of puzzle that you need to solve. When you have solved it correctly, it will lead you to your next destination - remember that - you are solving for a DESTINATION. Also keep in mind that sometimes the destination might come in different forms - words, coordinates, numbers, etc.</h5><h5 style={{margin:"10px"}}><div style={{fontFamily:"NewTextFontBold", fontSize:"16px"}}>Instructions</div>If there are instructions for that clue, a little icon will appear right above the clue (below the home icon).</h5><h5 style={{margin:"10px"}}><div style={{fontFamily:"NewTextFontBold", fontSize:"16px"}}>Question</div>You CANNOT answer this question until you have solved the clue and have gone to the new destination. The question verifies that you made it to the next correct destination.</h5><h5 style={{margin:"10px"}}><div style={{fontFamily:"NewTextFontBold", fontSize:"16px"}}>Photo Op</div>Totally optional - some clues will have a little camera icon below the home icon; if you click on it, it will give you a silly photo prompt if you want some Insta inspo.</h5></div></Dialog>
        <Dialog open={openInstructions} onClose={() => {setOpenInstructions(false)}}>
          <div className="treasureHuntButtonDiv">
            <h4 style={{fontWeight:"bolder"}}>INSTRUCTIONS</h4><hr/>
            <h4 style={{textAlign:"center"}}>
              {props.I ? props.I.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div> }
            </h4>
          </div>
        </Dialog>

        <Dialog open={openPicture} onClose={() => {setOpenPicture(false)}}><div className="treasureHuntButtonDiv"><h4 style={{fontWeight:"bolder"}}>PHOTO OP!</h4><hr/><h4 style={{textAlign:"center"}}>{props.Pic}</h4></div></Dialog>
        <Dialog open={openHistory} onClose={() => {setOpenHistory(false)}}><div className="treasureHuntButtonDiv"><h4 style={{fontWeight:"bolder"}}>DID YOU KNOW...</h4><hr/><h4 style={{textAlign:"center"}}>{props.Hist}</h4></div></Dialog>
        <Dialog open={openHint} onClose={() => {setOpenHint(false)}}>
          <div className="treasureHuntButtonDiv">
            <h4 style={{fontWeight:"bolder"}}>HINT</h4><hr/>
            <h4 style={{textAlign:"center"}}>
                {props.Hint ? props.Hint.split("\\n").map(function(item, index) { return ( <div key={index}><h5 style={{marginBottom:"3px"}}>{item}</h5></div> )}) : <div></div> }
            </h4>
          </div>
        </Dialog>

        <Dialog open={openAnswer} onClose={() => {setOpenAnswer(false)}}>
          <div className="treasureHuntButtonDiv">
            <div className="treasureHuntButtonDiv"><h4 style={{fontWeight:"bolder"}}>QUESTION'S ANSWER</h4><hr/><h4 style={{textAlign:"center"}}>{props.Answer}</h4></div>
          </div>
        </Dialog>
        
      </div>
    );

  }
