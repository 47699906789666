// AUTHENTICATION GENERAL IMPORTS
import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import VerticalLinearStepper from "./VerticalStepper";
import { HeaderBar } from "./HeaderBar";

// AUTHENTICATION MAIN CONTENT IMPORTS
import {FooterTwo} from "./Footer";
// import { YoutubeCards } from "./YoutubeCards";

export function SignIn(props) {

  const mainPicTwo = require('./pictures/AuthImgBannerTwo.jpg')

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
    <div className="pageContainer">
        <div className="authBackground">

          {/* RENDERED PAGE INFO */}
          <HeaderBar PageID="Home" />

            <div className="appMainContentDiv">
              <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                  <div style={{width:"90%", display:"flex", flexDirection:"row-reverse", flexWrap:"wrap", justifyContent:"space-between", textAlign:"center", alignItems:"center", marginBottom:"5em"}}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", flexGrow:"1"}}>
                      <h4 style={{fontSize:"2rem", marginBottom:"1em", maxWidth:"350px", minWidth:"300px", width:"95vw"}}><div style={{display:"inline", color:"#2E59FF"}}>Promoting </div> creative solutions and <div style={{display:"inline", color:"#2E59FF"}}> lasting memories</div></h4>
                      <h4 style={{fontSize:"1rem", marginBottom:"3em", maxWidth:"350px", minWidth:"300px", width:"95vw"}}><div style={{display:"inline", color:"#2E59FF"}}>Affordable team adventures</div> encouraging exploration and fun while tackling a series of unique, puzzling clues</h4>
                    </div>
                    <div style={{display:"flex", justifyContent:"center", flexGrow:"1"}}>
                      <img src={mainPicTwo} alt="" style={{width:"90vw", maxWidth:"300px", minWidth:"300px", boxShadow:"0px 0px 6px black"}}/>
                    </div>
                  </div>  
                </div>

                <div style={{width:"90%", maxWidth:"800px", display:"flex", flexDirection:"column", textAlign:"center", alignItems:"center", marginBottom:"4em"}}>
                  <h3 style={{fontSize:"1.5rem", marginBottom:"1.5em"}}>So what is this really?</h3>
                  <h4 style={{fontFamily:"NewTextFont", width:"100%", textAlign:"justify", textJustify:"inter-word"}}>Imagine scavenger hunts mashed together with escape rooms! These progressive puzzle treasure hunts not only lead you on an adventure to find random objects and locations, but also have you solve a series of puzzles along the way.<br/><br/>You'll be given a clue containing some type of code to crack, and once you do, you'll be led to a new destination where you can obtain the next one. Solid teamwork and logicking will help you conquer all 7 clues without needing our hints during your adventure.<br/><br/>On a date, or with a team, just have fun with it! You can race your friends in the same hunt, establish prizes for the winners, take silly pics along the way, and explore new places!</h4>
                </div> 

                <div style={{width:"90%", maxWidth:"800px", display:"flex", flexDirection:"column", textAlign:"center", alignItems:"center", marginBottom:"4em"}}>
                  <h3 style={{fontSize:"1.5rem", marginBottom:"1.5em"}}>Practice makes better</h3>
                  <h4 style={{fontFamily:"NewTextFont", width:"100%", textAlign:"justify", textJustify:"inter-word"}}>We won't toss you into the unknown without some examples - so give our practice clues a shot <a href="/practice" rel="noreferrer" style={{color:"#2E59FF"}}>here</a>. Also, when you create an account on our site you can find a sample hunt on the Purchases page. And feel free to check out our <a href="https://www.instagram.com/hiddenhunts/" rel="noreferrer" style={{color:"#2E59FF"}}>Insta</a> for a bunch of sample riddles!</h4>
                </div> 

                <div style={{width:"90%", display:"flex", flexDirection:"column", textAlign:"center", alignItems:"center", marginBottom:"15em"}}>
                  <h3 style={{fontSize:"1.5rem", marginBottom:"1.5em"}}>Let's get started</h3>
                  <VerticalLinearStepper/>
                </div>  

              </div>
            </div>

{/* AUTHENTICATION PAGE FOOTERS */}
            <FooterTwo/>
        </div>
        
    </div>
    </HelmetProvider>
  );
}
